import { useEffect, useState } from "react";
import { formatMoneyToTL } from "../utilization/MoneyOperations";
import { Link } from "react-router-dom";
import { convertToTurkish } from "../utilization/LanguageOperations";
import BaseModal from "./components/BaseModal";
import FormSelect from "../app/components/FormSelect";
import {
  approveContractPaymentInformation,
  queryContractPaymentsByParams,
  rejectContractPaymentInformation,
} from "../requests/ContractRequests";
import { NotificationManager } from "react-notifications";

const TransferApprovalsScreen = () => {
  const [contractPaymentList, setContractPaymentList] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [showTransferImageModal, setShowTransferImageModal] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState("WAITING");
  const schoolId = localStorage.getItem("schoolId");
  const user = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    if (!schoolId) return;
    retrieveAndSetContractPaymentsByParams();
  }, [schoolId]);

  const retrieveAndSetContractPaymentsByParams = async () => {
    const { data, err } = await queryContractPaymentsByParams(
      {
        paymentType: "HAVALE",
        schoolId,
      },
      user?.access_token
    );
    if (err) {
      NotificationManager.error(
        "Havale ödemeleri sorgulanırken bir hata oluştu"
      );
      return;
    }
    setContractPaymentList(data);
  };

  const constructTransferStatusOptionList = () => {
    return [
      {
        value: "APPROVED",
        name: convertToTurkish("APPROVED"),
      },
      {
        value: "WAITING",
        name: convertToTurkish("WAITING"),
      },
      {
        value: "REJECTED",
        name: convertToTurkish("REJECTED"),
      },
    ];
  };

  const filterContractPaymentList = () => {
    if (!contractPaymentList) return [];
    return contractPaymentList.filter(
      (payment) => payment.contractPaymentTransferItem.status === selectedStatus
    );
  };

  const RenderTransferApprovalsContent = () => {
    return (
      <>
        <FormSelect
          name="gradeId"
          text="Durum"
          placeholder=""
          type="text"
          col="3"
          required
          value={selectedStatus}
          data={constructTransferStatusOptionList()}
          onChange={(e) => setSelectedStatus(e.target.value)}
          valuekey="value"
          textkey="name"
        />
        <div className="table-responsive">
          <table className="table table-centered table-nowrap mb-0 rounded table-hover">
            <thead className="thead-light">
              <tr>
                <th className="border-0 rounded-start">#</th>
                <th className="border-0">TÜR</th>
                <th className="border-0">DETAY</th>
                <th className="border-0">TUTAR</th>
                <th className="border-0">DURUM</th>
                <th className="border-0"></th>
              </tr>
            </thead>
            <tbody>
              {filterContractPaymentList().map((payment, key) => (
                <tr
                  className={`${
                    selectedRow && selectedRow.id === payment.id
                      ? "table-warning"
                      : ""
                  } `}
                  key={key}
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setSelectedRow(payment);
                  }}
                >
                  <td>
                    <Link to="#" className="text-primary fw-bold">
                      {key + 1}
                    </Link>
                  </td>
                  <td>Havale ödeme</td>
                  <td>Dosya yükleme</td>
                  <td>
                    {formatMoneyToTL(
                      payment.contractPaymentTransferItem.amount
                    ) + " TL"}
                  </td>
                  <td>
                    {convertToTurkish(
                      payment.contractPaymentTransferItem.status
                    )}
                  </td>
                  <td>
                    <button
                      className="btn btn-info "
                      onClick={() => setShowTransferImageModal(true)}
                    >
                      İşlem
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </>
    );
  };

  const handleApproveContractPaymentOnClick = async (id) => {
    const { data, err } = await approveContractPaymentInformation(
      id,
      user?.access_token
    );
    if (err) {
      NotificationManager.error("Havale ödemesi onaylanırken bir hata oluştu");
      return;
    }
    NotificationManager.success("Havale ödemesi başarıyla onaylandı");
    retrieveAndSetContractPaymentsByParams();
    setShowTransferImageModal(false);
  };

  const handleRejectContractPaymentOnClick = async (id) => {
    const { data, err } = await rejectContractPaymentInformation(
      id,
      user?.access_token
    );
    if (err) {
      NotificationManager.error("Havale ödemesi reddedilirken bir hata oluştu");
      return;
    }
    NotificationManager.success("Havale ödemesi başarıyla reddedildi");
    retrieveAndSetContractPaymentsByParams();
    setShowTransferImageModal(false);
  };

  const RenderShowTransferImageModalContent = () => {
    if (!selectedRow) return;
    const { contractPaymentTransferItem } = selectedRow;
    const { base64Image, status } = contractPaymentTransferItem;
    return (
      <BaseModal
        modalIsOpen={showTransferImageModal}
        contentLabel={"Ücret kalemi bilgileri"}
        closeModal={() => {
          setShowTransferImageModal(false);
        }}
      >
        <div className="col-12 d-flex justify-content-center align-items-center">
          {base64Image.startsWith("data:application/pdf") ? (
            <div className="" style={{ width: 950, height: 900 }}>
              <iframe className="w-100 h-100" src={base64Image} />
            </div>
          ) : (
            <div style={{ width: 600 }}>
              <img className="w-100" src={base64Image} />
            </div>
          )}
        </div>
        {status === "WAITING" ? (
          <div className="d-flex justify-content-end align-items-center my-2">
            <div className="mx-2">
              <button
                className="btn btn-success text-light "
                onClick={() => {
                  handleApproveContractPaymentOnClick(selectedRow.id);
                }}
              >
                Onayla
              </button>
            </div>
            <div className="mx-2">
              <button
                className="btn btn-danger text-light  mx-2"
                onClick={() => {
                  handleRejectContractPaymentOnClick(selectedRow.id);
                }}
              >
                Reddet
              </button>
            </div>
          </div>
        ) : null}
      </BaseModal>
    );
  };

  return (
    <>
      {RenderShowTransferImageModalContent()}
      <div className="content">
        <div className="row">
          <div className="col-12">
            <div className="card border-0 shadow">
              <div className="card-body">
                {RenderTransferApprovalsContent()}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TransferApprovalsScreen;
