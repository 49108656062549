const SchoolExpressConsent = (props) => {
  const { student } = props;
  if (!student) return;

  const constructStudentName = () => {
    return student.name + " " + student.lastname;
  };

  const constructStudentIdentityNumber = () => {
    return student.identityNumber;
  };

  const constructParentNameByParentType = (parentType) => {
    const parent = student.parents.find((p) => p.parentType === parentType);
    if (parent) {
      return parent.user.name + " " + parent.user.lastname;
    } else {
      return "";
    }
  };

  return (
    <div className="mt-3 mb-3">
      <h5 className="">
        TÜRK EĞİTİM DERNEĞİNE (Kişisel Verilerin Korunması Kanunu Kapsamında)
        AÇIK RIZA BEYANI
      </h5>
      <div style={{ paddingTop: 20, marginBottom: 10 }} />
      Okulunuza ait Öğrenci Kayıt Formunda ve Millî Eğitim Bakanlığı Özel
      Öğretim Kurumları Genel Müdürlüğü Öğrenci Kayıt Sözleşmesinde ve K12
      sistemi üzerinden beyan ettiğim/edeceğim bilgilerin doğru olduğunu ve bu
      verilerin ted.org.tr/kvkk adresinde bulunan ve okumuş olduğum Türk Eğitim
      Derneği Aydınlatma Metninde yer alan açıklamalara uygun olarak Türk Eğitim
      Derneği tarafından işlenmesine Kişisel Verilerin Korunması Kanunu
      kapsamında izin verdiğimi beyan ederim.
      <div className="row">
        <div className="col-md-12">
          <div className="mt-3">
            <div className="d-flex flex-row">
              <div style={{ marginRight: 10 }}>Tarih: </div>
              <div style={{ fontWeight: "bold" }}>
                {new Date().toLocaleDateString("TR")}
              </div>
            </div>
          </div>
          <div className="d-flex flex-row">
            <div style={{ marginRight: 10 }}>Öğrenci TC Kimlik Numarası: </div>
            <div style={{ fontWeight: "bold" }}>
              {constructStudentIdentityNumber()}
            </div>
          </div>
          <div className="d-flex flex-row">
            <div style={{ marginRight: 10 }}>Öğrenci Adı ve Soyadı:</div>
            <div style={{ fontWeight: "bold" }}>{constructStudentName()}</div>
          </div>
          <div className="d-flex flex-row justify-content-between">
            <p style={{ fontWeight: "bold" }}>Onay veriyorum</p>
            <div>
              {constructParentNameByParentType("ANNE") ? (
                <div>
                  Anne adı:{" "}
                  <span style={{ fontWeight: "bold" }}>
                    {constructParentNameByParentType("ANNE")}
                  </span>
                </div>
              ) : null}
              {constructParentNameByParentType("BABA") ? (
                <div>
                  Baba adı:{" "}
                  <span style={{ fontWeight: "bold" }}>
                    {constructParentNameByParentType("BABA")}
                  </span>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SchoolExpressConsent;
