import { useContext, useEffect, useState } from "react";
import Back from "../../assets/back.png";
import { useNavigate } from "react-router-dom";
import Logo from "../../assets/logo.png";
import { UserContext } from "../../context/UserContextProvider";
import {
  addSiblingDiscountToStudent,
  removeSiblingDiscountFromStudent,
  retrieveStudentContractByParentId,
} from "../../requests/ContractRequests";
import { NotificationManager } from "react-notifications";

export default function ParentStudent() {
  const userContext = useContext(UserContext);
  const { userInformation } = userContext;
  const [hasSiblingDiscount, setHasSiblingDiscount] = useState(false);
  const [studentContractList, setStudentContractList] = useState([]);
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("user"));
  const parentUser = JSON.parse(localStorage.getItem("parent_data"));

  useEffect(() => {
    retrieveAndSetStudentContractByParentId();
  }, []);

  useEffect(() => {
    if (!studentContractList) return;
    if (studentContractList.length === 0) return;
    let useSiblingDiscount = false;
    for (const studentContract of studentContractList) {
      const { contractDiscounts } = studentContract;
      useSiblingDiscount =
        contractDiscounts.filter(
          (contractDiscount) =>
            contractDiscount.schoolDiscount.rule === "SIBLING_DISCOUNT"
        ).length > 0;
      if (useSiblingDiscount) {
        break;
      }
    }
    setHasSiblingDiscount(useSiblingDiscount);
  }, [studentContractList]);

  const retrieveAndSetStudentContractByParentId = async () => {
    const { data, err } = await retrieveStudentContractByParentId(
      parentUser.user_id,
      user?.access_token
    );
    if (err) {
      NotificationManager.error(
        "Öğrenci sözleşmesi sorgulanırken bir hata oluştu"
      );
      return;
    }
    setStudentContractList(data);
  };

  const eventHandler = (student, contract) => {
    if (contract.status === "END") {
      navigate(`/parent-contract/${student.id}`);
    } else {
      navigate(`/parent-detail/${student.id}`);
    }
  };

  const handleAddSiblingDiscountOnClick = async () => {
    const user = JSON.parse(localStorage.getItem("parent_data"));
    let schoolId;
    const addSiblingDiscountItemList = studentContractList.map(
      (studentContract) => {
        const { student } = studentContract;
        const { contractFees } = studentContract;
        const foundContractFee = contractFees.find(
          (contractFee) => contractFee.schoolFee.groupNo === 1
        );
        const { school } = student;
        const { grade } = student;
        schoolId = school.id;
        return {
          gradeId: grade.id,
          studentId: student.id,
          contractFeeId: foundContractFee.id,
          schoolFeeId: foundContractFee.schoolFee.id,
        };
      }
    );
    const { data, err } = await addSiblingDiscountToStudent(
      {
        schoolId,
        addSiblingDiscountItemList,
      },
      user?.access_token
    );
    if (err) {
      NotificationManager.error("Kardeş indirimi eklenirken bir hata oluştu");
      return;
    }
    retrieveAndSetStudentContractByParentId();
  };

  const handleRemoveSiblingDiscountOnClick = async () => {
    const user = JSON.parse(localStorage.getItem("parent_data"));
    for (const studentContract of studentContractList) {
      const { student } = studentContract;
      const { contractDiscounts } = studentContract;
      const foundContractDiscount = contractDiscounts.find(
        (contractDiscount) =>
          contractDiscount.schoolDiscount.rule === "SIBLING_DISCOUNT"
      );
      const { data, err } = await removeSiblingDiscountFromStudent({
        studentId: student.id,
        parentId: user.user_id,
        schoolDiscountId: foundContractDiscount.id,
      });
      if (err) {
        NotificationManager.error("Kardeş indirimi silinirken bir hata oluştu");
      }
    }
    retrieveAndSetStudentContractByParentId();
  };

  const RenderSiblingDiscountWarningContent = () => {
    if (!studentContractList) return;
    let canShow = false;
    if (studentContractList.length > 1) {
      canShow =
        !studentContractList.filter(
          (studentContract) => studentContract.contract.status === "END"
        ).length > 0;
    }
    if (!canShow) return;
    return (
      <div>
        <div className="border border-warning rounded">
          <div className="p-3">
            {hasSiblingDiscount ? (
              <>
                <div>
                  <b>Öğrenci indirimi;</b> Bütün kardeşlerin kayıtlarının okula
                  yapılacağı durumda kullanılır. Eğer bütün kardeşleri kayıt
                  etmeyecekseniz bu durumu değiştirmeniz gerekmektedir. Kardeş
                  indirimi sözleşmede yer alacağından bu durumun sorumluluğu
                  size aittir. Şu anda bu indirim size <b>atanmıştır</b>.
                </div>
                <div className="d-flex flex-row justify-content-end">
                  <button
                    className="btn btn-warning"
                    onClick={() => {
                      handleRemoveSiblingDiscountOnClick();
                    }}
                  >
                    Kardeş indirimi kullanmak istemiyorum
                  </button>
                </div>
              </>
            ) : (
              <>
                <div>
                  <b>Öğrenci indirimi;</b> Bütün kardeşlerin kayıtlarının okula
                  yapılacağı durumda kullanılır. Eğer bütün kardeşleri kayıt
                  etmeyecekseniz bu durumu değiştirmeniz gerekmektedir. Kardeş
                  indirimi sözleşmede yer alacağından bu durumun sorumluluğu
                  size aittir. Şu anda bu indirim size <b>atanmamıştır</b>.
                </div>
                <div className="d-flex flex-row justify-content-end">
                  <button
                    className="btn btn-warning"
                    onClick={() => {
                      handleAddSiblingDiscountOnClick();
                    }}
                  >
                    Kardeş indirimi kullanmak istiyorum
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={`${userInformation.role === "ADMIN" ? "content" : ""}`}>
      {userInformation.role === "ADMIN" ? (
        <div className="" style={{ width: 260 }} />
      ) : null}
      <div className="container">
        <div className="row">
          <div className="col-12 col-xl-12">
            <div className="card card-body border-0 shadow mb-4">
              <div className="w-100 d-flex justify-content-center">
                <div className="p-3" style={{ width: 200 }}>
                  <img
                    src={Logo}
                    alt="atekent_logo"
                    style={{ width: "100%" }}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-auto">
                  <div className="d-inline-flex" onClick={() => navigate("/")}>
                    <div className="" style={{ width: 30, cursor: "pointer" }}>
                      <img src={Back} alt="back" style={{ width: "100%" }} />
                    </div>
                  </div>
                </div>
                <div className="col">
                  <h2 className="h5 mb-4">Velisi olduğunuz öğrenciler</h2>
                </div>
              </div>
              {RenderSiblingDiscountWarningContent()}
              {studentContractList && Array.isArray(studentContractList)
                ? studentContractList.map((studentContract, key) => {
                    const { student, contract } = studentContract;
                    return (
                      <div className="my-2" key={key}>
                        <div className="card" style={{ width: "18rem;" }}>
                          <div className="card-body">
                            <h5 className="card-title">
                              {student.name} {student.lastname}
                            </h5>
                            <hr />
                            <p className="card-subtitle mb-2">
                              <b>
                                2024-2025 eğitim öğretim yılı (
                                {student.grade.name}) için kayıt
                              </b>
                            </p>

                            <p className="card-text">{student.school.name}</p>
                            <div className="row">
                              {student.parents && Array.isArray(student.parents)
                                ? student.parents.map((parent, key) => (
                                    <div
                                      className="col-6 p-2"
                                      style={{ width: "18rem;" }}
                                    >
                                      <div className="card">
                                        <div className="card-body">
                                          <h5 className="card-title">
                                            {parent.user.name}{" "}
                                            {parent.user.lastname}
                                          </h5>
                                          <h6 className="card-subtitle mb-2 text-muted">
                                            {parent.parentType}
                                          </h6>
                                          <h6 className="card-subtitle mb-2 text-muted">
                                            {parent.user.email}
                                          </h6>
                                        </div>
                                      </div>
                                    </div>
                                  ))
                                : null}
                            </div>
                            <button
                              className="btn btn-success text-light m-2 w-100 text-light text-center"
                              onClick={() => eventHandler(student, contract)}
                            >
                              <div className="">
                                {contract ? (
                                  contract.status === "END" ? (
                                    <span>
                                      {student.name} {student.lastname} için
                                      sözleşme göster
                                    </span>
                                  ) : contract.status === "NOT_START" ? (
                                    <span>
                                      {student.name} {student.lastname} için
                                      kayıt sürecini başlat
                                    </span>
                                  ) : (
                                    <span>
                                      {student.name} {student.lastname} için
                                      kayıt sürecine devam et
                                    </span>
                                  )
                                ) : (
                                  <span>
                                    {student.name} {student.lastname} için kayıt
                                    sürecini başlat
                                  </span>
                                )}
                              </div>
                            </button>
                          </div>
                        </div>
                      </div>
                    );
                  })
                : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
