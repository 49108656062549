import React, { useEffect, useState } from "react";
import FormSelect from "../app/components/FormSelect";
import FormInput from "../app/components/FormInput";
import { formatMoneyToTL } from "../utilization/MoneyOperations";
import BaseModal from "./components/BaseModal";
import { convertToDateString } from "../utilization/DateOperations";
import { useNavigate } from "react-router";
import { retrieveQuotaListBySchoolId } from "../requests/SchoolRequests";
import { NotificationManager } from "react-notifications";
import {
  retrieveAllContractsInformationBySchoolId,
  retrieveContractPaymentByStudentId,
} from "../requests/ContractRequests";

export default function Contract() {
  const navigate = useNavigate();
  const [dataList, setDataList] = useState([]);
  const [quotaList, setQuotaList] = useState(null);
  const [selectedFee, setSelectedFee] = useState(null);
  const [paymentList, setPaymentList] = useState([]);
  const [selectedGradeId, setSelectedGradeId] = useState(null);
  const [selectedBranchId, setSelectedBranchId] = useState(null);
  const [textContains, setTextContains] = useState("");
  const [showSelectedStudentModal, setShowSelectedStudentModal] =
    useState(false);
  const schoolId = localStorage.getItem("schoolId");
  const user = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    const retrieveAndSetQuotaListBySchoolId = async () => {
      const { data, err } = await retrieveQuotaListBySchoolId(
        schoolId,
        user?.access_token
      );
      if (err) {
        NotificationManager.error("Kota listesi sorgulanırken bir hata oluştu");
        return;
      }
      setQuotaList(data);
    };
    const retrieveAndSetContractInformationBySchoolId = async () => {
      const { data, err } = await retrieveAllContractsInformationBySchoolId(
        schoolId,
        user?.access_token
      );
      if (err) {
        NotificationManager.error(
          "Öğrenci sözleşmeleri sorgulanırken bir hata oluştu"
        );
        return;
      }
      setDataList(data);
    };
    if (!schoolId) return;
    retrieveAndSetQuotaListBySchoolId();
    retrieveAndSetContractInformationBySchoolId();
  }, [schoolId]);

  useEffect(() => {
    const retrieveAndSetContractPaymentListByStudentId = async () => {
      const { data, err } = await retrieveContractPaymentByStudentId(
        selectedFee.student.id,
        user?.access_token
      );
      if (err) {
        NotificationManager.error(
          "Sözleşme ödemeleri sorgulanırken bir hata oluştu"
        );
        return;
      }
      setPaymentList(data);
    };
    if (selectedFee && selectedFee.id) {
      retrieveAndSetContractPaymentListByStudentId();
    }
  }, [selectedFee]);

  useEffect(() => {
    if (!quotaList) return;
    const { gradeList, branchList } = quotaList;
    const grade = gradeList[0];
    const foundBranch = branchList.find(
      (branch) => branch.grade.id === grade.id
    );
    if (grade) {
      setSelectedGradeId(grade.id);
    }
    if (foundBranch) {
      setSelectedBranchId(foundBranch.id);
    }
  }, [quotaList]);

  const creditCartInstallment = () => {
    return paymentList &&
      Array.isArray(paymentList) &&
      paymentList.length > 0 ? (
      <div className="table-responsive" style={{ paddingBottom: "25px" }}>
        <h5>
          {paymentList[0].paymentType === "PESİNAT"
            ? "TAKSİT"
            : paymentList[0].paymentType}
        </h5>
        <table className="table table-centered mb-0 rounded table-hover w-100">
          <thead className="thead-light">
            <tr>
              <th className="border-0">NO</th>
              <th className="border-0">TARİH</th>
              <th className="border-0">TUTAR</th>
            </tr>
          </thead>
          <tbody>
            {paymentList.map((ins, key) => (
              <tr key={key} style={{ cursor: "pointer" }}>
                <td>-</td>
                <td>{convertToDateString(ins.installmentDate)}</td>
                <td>
                  {ins.paymentType === "PESIN" || ins.paymentType === "HAVALE"
                    ? formatMoneyToTL(ins.cash) + " TL"
                    : formatMoneyToTL(ins.installmentFee) + " TL"}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    ) : null;
  };

  const handleSelectGradeOnChange = (e) => {
    const { branchList } = quotaList;
    const foundBranch = branchList.find(
      (branch) => branch.grade.id === e.target.value
    );
    setSelectedGradeId(e.target.value);

    if (foundBranch) {
      setSelectedBranchId(foundBranch.id);
    }
  };

  const handleSelectBranchOnChange = (e) => {
    setSelectedBranchId(e.target.value);
  };

  const retrieveFilteredBranchList = () => {
    if (!quotaList) return [];
    return quotaList.branchList.filter(
      (branch) => branch.grade.id === selectedGradeId
    );
  };

  const retrieveTextContainsDataList = () => {
    if (textContains) {
      return dataList.filter(
        (datum) =>
          datum.student.name
            .toUpperCase()
            .includes(textContains.toUpperCase()) ||
          datum.student.lastname
            .toUpperCase()
            .includes(textContains.toUpperCase())
      );
    } else {
      return dataList;
    }
  };

  const retrieveFilterDataList = () => {
    if (!dataList) return [];
    return retrieveTextContainsDataList().filter(
      (datum) =>
        datum.student.branch.id === selectedBranchId &&
        datum.student.grade.id === selectedGradeId
    );
  };

  const RenderSelectedStudentModalContent = () => {
    if (!selectedFee) return;
    return (
      <BaseModal
        modalIsOpen={showSelectedStudentModal}
        contentLabel={"Sözleşme bilgileri"}
        closeModal={() => {
          setShowSelectedStudentModal(false);
        }}
      >
        <div className="col-12 ">
          {creditCartInstallment()}
          <div className="d-flex flex-row justify-content-center align-items-center">
            <div
              className="btn btn-sm btn-gray-800 d-inline-flex align-items-center mx-2 mt-3"
              onClick={() => {
                navigate(`/parent-contract/${selectedFee.student.id}`);
              }}
            >
              Sözleşme göster
            </div>
            <div
              className="btn btn-sm btn-gray-800 d-inline-flex align-items-center mx-2 mt-3"
              onClick={() => {
                navigate(`/parent-contract/${selectedFee.student.id}/pdf`);
              }}
            >
              PDF göster
            </div>
          </div>
        </div>
      </BaseModal>
    );
  };

  return (
    <>
      {RenderSelectedStudentModalContent()}
      <div className="content">
        <div className="col-12 col-xl-12">
          <div className="card border-0 shadow">
            <div className="card-body">
              <form>
                <div className="row">
                  {quotaList?.gradeList ? (
                    <FormSelect
                      name="gradeId"
                      text="Sınıf"
                      placeholder=""
                      type="text"
                      col="3"
                      required
                      value=""
                      id={"inputs.id"}
                      data={quotaList.gradeList}
                      onChange={handleSelectGradeOnChange}
                      valuekey="id"
                      textkey="name"
                    />
                  ) : null}
                  {selectedGradeId && quotaList?.branchList ? (
                    <FormSelect
                      name="branchId"
                      text="Şube"
                      placeholder=""
                      type="text"
                      col="3"
                      required
                      value=""
                      id={"inputs.id"}
                      data={retrieveFilteredBranchList()}
                      onChange={handleSelectBranchOnChange}
                      valuekey="id"
                      textkey="name"
                    />
                  ) : null}

                  <FormInput
                    name="beginAt"
                    text="Kelime"
                    placeholder=""
                    type="text"
                    col="3"
                    required
                    value={textContains}
                    onChange={(e) => setTextContains(e.target.value)}
                  />
                </div>
              </form>

              <div className="table-responsive">
                <table className="table table-centered mb-0 rounded table-hover w-100">
                  <thead className="thead-light">
                    <tr>
                      <th className="border-0">ÖĞRENCİ</th>
                      <th className="border-0">ESKİ SINIF</th>
                      <th className="border-0">YENİ SINIF</th>
                      <th className="border-0">TUTAR</th>
                      <th className="border-0">ÖDEME YÖNTEMİ</th>
                      <th className="border-0"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {retrieveFilterDataList().map((f, key) =>
                      f.paymentType && f.paymentType.length > 2 ? (
                        <tr
                          onClick={() => {
                            setSelectedFee(f);
                          }}
                          key={key}
                          style={{ cursor: "pointer" }}
                          className={`${
                            selectedFee && selectedFee.id === f.id
                              ? "table-warning"
                              : ""
                          } `}
                        >
                          <td>
                            {f.student.name} {f.student.lastname}
                          </td>
                          <td>{f.student.oldGrade.name}</td>
                          <td>{f.student.grade.name}</td>
                          <td>{`${formatMoneyToTL(f.totalPrice)} TL`}</td>
                          <td>{f.paymentType}</td>
                          <td>
                            <button
                              className="btn btn-secondary "
                              onClick={() => setShowSelectedStudentModal(true)}
                            >
                              Detay
                            </button>
                          </td>
                        </tr>
                      ) : null
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
