import { useContext, useEffect, useState } from "react";
import {
  patchSchoolSettings,
  retrieveSchoolSettingsBySchoolId,
} from "../requests/SchoolRequests";
import FormInput from "../app/components/FormInput";
import { NotificationManager } from "react-notifications";
import { UserContext } from "../context/UserContextProvider";
import { useNavigate } from "react-router";
import { queryUserLogsByEntityId } from "../requests/UserLogRequests";
import BaseModal from "./components/BaseModal";

const SchoolSettingsScreen = (props) => {
  const schoolId = localStorage.getItem("schoolId");
  const user = JSON.parse(localStorage.getItem("user"));
  const navigate = useNavigate();
  const userContext = useContext(UserContext);
  const { schoolSettings, setSchoolSettings } = userContext;
  const [minCash, setMinCash] = useState(0);
  const [smsSettings, setSmsSettings] = useState({
    username: "",
    password: "",
    originator: "",
  });
  const [paymentSettings, setPaymentSettings] = useState({
    username: "",
    password: "",
    code: "",
    guid: "",
  });
  const [transferSettingsList, setTransferSettingsList] = useState([]);
  const [userLogList, setUserLogList] = useState([]);
  const [showPastUserLogsModal, setShowPastUserLogsModal] = useState(false);

  useEffect(() => {
    const retrieveAndSetSchoolSettingsBySchoolId = async () => {
      const { data, err } = await retrieveSchoolSettingsBySchoolId(
        schoolId,
        user?.access_token
      );
      if (err) {
        NotificationManager.error(
          "Okul ayarları sorgulanırken bir hata oluştu"
        );
        return;
      }
      setSchoolSettings(data);
    };
    const retrieveAndSetUserLogsByEntityId = async () => {
      const { data, err } = await queryUserLogsByEntityId(
        schoolId,
        user?.access_token
      );
      if (err) {
        NotificationManager.error(
          "Okul ayarlarına ilişkin kullanıcı kayıtları sorgulanırken bir hata oluştu"
        );
        return;
      }
      setUserLogList(data);
    };
    retrieveAndSetSchoolSettingsBySchoolId();
    retrieveAndSetUserLogsByEntityId();
  }, [schoolId]);

  useEffect(() => {
    if (!schoolSettings) return;
    const {
      minCash,
      schoolSMSSettingsDto,
      schoolPaymentSettingsDto,
      schoolTransferSettingsDtoList,
    } = schoolSettings;
    setMinCash(minCash);
    setSmsSettings(schoolSMSSettingsDto);
    setPaymentSettings(schoolPaymentSettingsDto);
    setTransferSettingsList(schoolTransferSettingsDtoList);
  }, [schoolSettings]);

  const handlePatchSchoolSettings = async () => {
    const patchBody = {
      schoolId,
      minCash,
      smsSettings,
      paymentSettings,
      patchSchoolTransferSettingsList: transferSettingsList,
    };
    const { data, err } = await patchSchoolSettings(
      patchBody,
      user?.access_token
    );
    if (err) {
      NotificationManager.error("Okul ayarları güncellenirken bir hata oluştu");
      return;
    }
    NotificationManager.success("Okul ayarları başarıyla güncellendi");
    navigate("/");
  };

  const RenderMinCashContent = () => {
    return (
      <>
        <div className="my-2">
          <h5>Genel ayarlar</h5>
        </div>
        <div className="row">
          <FormInput
            name="minCash"
            text="Minimum peşin tutarı"
            placeholder=""
            type="number"
            col="4"
            value={minCash}
            onChange={(e) => setMinCash(parseFloat(e.target.value))}
          />
        </div>
      </>
    );
  };

  const RenderSmsSettingsContent = () => {
    return (
      <>
        <div className="my-2">
          <h5>SMS (MobilDEV) ayarları</h5>
        </div>
        <div className="row">
          <FormInput
            name="username"
            text="Kullanıcı adı"
            placeholder=""
            type="text"
            col="4"
            value={smsSettings.username}
            onChange={(e) =>
              setSmsSettings({ ...smsSettings, username: e.target.value })
            }
          />
          <FormInput
            name="password"
            text="Şifre"
            placeholder=""
            type="text"
            col="4"
            value={smsSettings.password}
            onChange={(e) =>
              setSmsSettings({ ...smsSettings, password: e.target.value })
            }
          />
          <FormInput
            name="originator"
            text="Kaynak"
            placeholder=""
            type="text"
            col="4"
            value={smsSettings.originator}
            onChange={(e) =>
              setSmsSettings({ ...smsSettings, originator: e.target.value })
            }
          />
        </div>
      </>
    );
  };

  const RenderPaymentSettingsContent = () => {
    return (
      <>
        <div className="my-2">
          <h5>Ödeme (ParamPOS) ayarları</h5>
        </div>
        <div className="p-2">
          <div className="row">
            <FormInput
              name="username"
              text="Kullanıcı adı"
              placeholder=""
              type="text"
              col="4"
              value={paymentSettings.username}
              onChange={(e) =>
                setPaymentSettings({
                  ...paymentSettings,
                  username: e.target.value,
                })
              }
            />
            <FormInput
              name="password"
              text="Şifre"
              placeholder=""
              type="text"
              col="4"
              value={paymentSettings.password}
              onChange={(e) =>
                setPaymentSettings({
                  ...paymentSettings,
                  password: e.target.value,
                })
              }
            />
            <FormInput
              name="code"
              text="Kod"
              placeholder=""
              type="text"
              col="4"
              value={paymentSettings.code}
              onChange={(e) =>
                setPaymentSettings({ ...paymentSettings, code: e.target.value })
              }
            />
            <FormInput
              name="guid"
              text="GUID"
              placeholder=""
              type="text"
              col="4"
              value={paymentSettings.guid}
              onChange={(e) =>
                setPaymentSettings({ ...paymentSettings, guid: e.target.value })
              }
            />
          </div>
        </div>
      </>
    );
  };

  const RenderTransferSettingsContent = (transferSettings, index) => {
    return (
      <div className="p-2">
        <div className="row">
          <FormInput
            name="name"
            text="İsim"
            placeholder=""
            type="text"
            col="4"
            value={transferSettings.name}
            onChange={(e) => {
              transferSettingsList[index].name = e.target.value;
              setTransferSettingsList([...transferSettingsList]);
            }}
          />
          <FormInput
            name="accountName"
            text="Hesap adı"
            placeholder=""
            type="text"
            col="4"
            value={transferSettings.accountName}
            onChange={(e) => {
              transferSettingsList[index].accountName = e.target.value;
              setTransferSettingsList([...transferSettingsList]);
            }}
          />
          <FormInput
            name="accountType"
            text="Hesap tipi"
            placeholder=""
            type="text"
            col="4"
            value={transferSettings.accountType}
            onChange={(e) => {
              transferSettingsList[index].accountType = e.target.value;
              setTransferSettingsList([...transferSettingsList]);
            }}
          />
          <FormInput
            name="accountBranchName"
            text="Şube adı"
            placeholder=""
            type="text"
            col="4"
            value={transferSettings.accountBranchName}
            onChange={(e) => {
              transferSettingsList[index].accountBranchName = e.target.value;
              setTransferSettingsList([...transferSettingsList]);
            }}
          />
          <FormInput
            name="IBAN"
            text="IBAN"
            placeholder=""
            type="text"
            col="4"
            value={transferSettings.iban}
            onChange={(e) => {
              transferSettingsList[index].iban = e.target.value;
              setTransferSettingsList([...transferSettingsList]);
            }}
          />
        </div>
      </div>
    );
  };

  const RenderTransferSettingsListContent = () => {
    return (
      <>
        <div className="my-2">
          <h5>Havale ayarları</h5>
        </div>
        {transferSettingsList.map((transferSettings, index) => (
          <div className="my-1 p-2" key={index}>
            {RenderTransferSettingsContent(transferSettings, index)}
          </div>
        ))}
      </>
    );
  };

  const RenderUserLogsContent = () => {
    if (userLogList.length === 0) return;
    return (
      <div>
        <div className="d-flex justify-content-end">
          <span
            style={{
              fontStyle: "italic",
              textDecoration: "underline",
              cursor: "pointer",
            }}
            onClick={() => {
              setShowPastUserLogsModal(true);
            }}
          >
            Geçmiş kayıtları göster
          </span>
        </div>
      </div>
    );
  };

  const RenderSchoolSettingsContent = () => {
    if (!schoolSettings) return;
    return (
      <>
        {RenderUserLogsContent()}
        {RenderMinCashContent()}
        {RenderSmsSettingsContent()}
        {RenderPaymentSettingsContent()}
        {RenderTransferSettingsListContent()}
        <div className="d-flex justify-content-end align-items-center my-2">
          <button
            className="btn btn-success text-light "
            onClick={handlePatchSchoolSettings}
          >
            Güncelle
          </button>
        </div>
      </>
    );
  };

  const RenderUserLogContent = (userLog) => {
    const { name, lastname, operation, entity, description, createdAt } =
      userLog;
    if (entity === "SCHOOL_SETTINGS") {
      const parsedDescription = JSON.parse(description);
      const { minCash, smsSettings, paymentSettings, transferSettingsList } =
        parsedDescription;
      const parsedSmsSettings = JSON.parse(smsSettings);
      const parsedPaymentSettings = JSON.parse(paymentSettings);
      const parsedTransferSettingsList = JSON.parse(transferSettingsList);
      if (operation === "UPDATE") {
        return (
          <div className="my-2">
            <div>
              -{" "}
              <span style={{ fontWeight: "bold" }}>
                {name} {lastname}
              </span>
              {" tarafından "}
              <span style={{ fontWeight: "bold" }}>
                {new Date(createdAt).toLocaleString("TR")}
              </span>
              {" tarihinde güncellendi."}
            </div>
            <div className="px-4">
              <div className="my-1">
                <span
                  style={{ textDecoration: "underline", fontStyle: "italic" }}
                >
                  (SMS ayarları)
                </span>
                <span>
                  {" Kullanıcı adı: "}
                  <span style={{ fontWeight: "bold" }}>
                    {parsedSmsSettings.username}
                  </span>
                  {", şifre: "}
                  <span style={{ fontWeight: "bold" }}>
                    {parsedSmsSettings.password}
                  </span>
                  {", kaynak: "}
                  <span style={{ fontWeight: "bold" }}>
                    {parsedSmsSettings.originator}
                  </span>
                </span>
              </div>
              <div className="my-1">
                <span
                  style={{ textDecoration: "underline", fontStyle: "italic" }}
                >
                  (Ödeme ayarları)
                </span>
                <span>
                  {" Kullanıcı adı: "}
                  <span style={{ fontWeight: "bold" }}>
                    {parsedPaymentSettings.username}
                  </span>
                  {", şifre: "}
                  <span style={{ fontWeight: "bold" }}>
                    {parsedPaymentSettings.password}
                  </span>
                  {", kod: "}
                  <span style={{ fontWeight: "bold" }}>
                    {parsedPaymentSettings.code}
                  </span>
                  {", GUID: "}
                  <span style={{ fontWeight: "bold" }}>
                    {parsedPaymentSettings.guid}
                  </span>
                </span>
              </div>
              <div className="my-1">
                <span
                  style={{ textDecoration: "underline", fontStyle: "italic" }}
                >
                  (Havale ayarları)
                </span>
                {parsedTransferSettingsList.map((parsedTransferSettings) => (
                  <div className="my-1" style={{ marginLeft: 20 }}>
                    <span>
                      {" - İsim: "}
                      <span style={{ fontWeight: "bold" }}>
                        {parsedTransferSettings.name}
                      </span>
                      {", hesap ismi: "}
                      <span style={{ fontWeight: "bold" }}>
                        {parsedTransferSettings.accountName}
                      </span>
                      {", şube ismi: "}
                      <span style={{ fontWeight: "bold" }}>
                        {parsedTransferSettings.accountBranchName}
                      </span>
                      {", hesap tipi: "}
                      <span style={{ fontWeight: "bold" }}>
                        {parsedTransferSettings.accountType}
                      </span>
                      {", IBAN: "}
                      <span style={{ fontWeight: "bold" }}>
                        {parsedTransferSettings.iban}
                      </span>
                    </span>
                  </div>
                ))}
              </div>
            </div>
          </div>
        );
      }
    }
  };

  const RenderPastUserLogsModalContent = () => {
    return (
      <BaseModal
        modalIsOpen={showPastUserLogsModal}
        contentLabel={"Sözleşme bilgileri"}
        closeModal={() => {
          setShowPastUserLogsModal(false);
        }}
      >
        {userLogList.map((userLog, index) => (
          <div key={index} className="my-2">
            {RenderUserLogContent(userLog)}
          </div>
        ))}
      </BaseModal>
    );
  };

  return (
    <>
      {RenderPastUserLogsModalContent()}
      <div className="content">
        <div className="row">
          <div className="col-12">
            <div className="card border-0 shadow">
              <div className="card-body">{RenderSchoolSettingsContent()}</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SchoolSettingsScreen;
