import FormSelect from "../../app/components/FormSelect";
import React from "react";

export default function AddFeesPanel(props) {
  return (
    <>
      <p>Ücretler</p>
      <div className="d-flex flex-row gap-3">
        <FormSelect
          name="branchId"
          text=""
          placeholder=""
          type="text"
          col="11"
          required
          value={props.addFeesSelected}
          onChange={(e) => props.setAddFeesSelected(e.target.value)}
          id={"inputs.id"}
          data={props.feeList}
          valuekey="id"
          textkey="name"
        />
        <div className="">
          <button
            className="btn btn-sm btn-secondary"
            onClick={() => props.handlerAddFees()}
          >
            Ekle
          </button>
        </div>
      </div>
    </>
  );
}
