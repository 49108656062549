import React, { useContext, useEffect } from "react";
import { AppContext } from "./context/AppContextProvider";
import { Route, Routes } from "react-router";
import { adminRoutes, parentRoutes, loginRoutes } from "./config/route";
import NoMatch from "./app/page-parts/NoMatch";
import { UserContext } from "./context/UserContextProvider";
import { BrowserRouter } from "react-router-dom";
import ShortMenu from "./admin/page-parts/ShortMenu";
import MainMenu from "./admin/page-parts/MainMenu";
import Header from "./admin/page-parts/Header";
import Footer from "./admin/page-parts/Footer";
import { HashLoader } from "react-spinners";
import axios from "axios";
import config from "./config/config.json";
import TokenService from "./service/token.service";
import AppHeader from "./app/page-parts/AppHeader";
import { retrieveSchoolSettingsBySchoolId } from "./requests/SchoolRequests";
import { NotificationManager } from "react-notifications";

export default function Main() {
  const appContext = useContext(AppContext);
  const { appState, setAppState } = appContext;
  const userContext = useContext(UserContext);
  const { userInformation, setSchoolSettings, setUserInformation } =
    userContext;

  useEffect(() => {
    const retrieveAndSetSchoolSettings = async () => {
      const schoolId = localStorage.getItem("schoolId");
      const user = JSON.parse(localStorage.getItem("user"));
      const { data, err } = await retrieveSchoolSettingsBySchoolId(
        schoolId,
        user?.access_token
      );
      if (err) {
        NotificationManager.error(
          "Kurum ayarları sorgulanırken bir hata oluştu"
        );
        setAppState("LOGIN_REQUIRED");
      }
      setSchoolSettings(data);
    };
    const retrieveAndSetLocalStorageUser = async () => {
      const localStorageUser = localStorage.getItem("user");
      if (!localStorageUser) {
        TokenService.removeUser();
        setAppState("LOGIN_REQUIRED");
        return;
      }
      const localStorageLoginState = localStorage.getItem("loginState");
      if (localStorageLoginState === "SMS_REQUIRED") {
        TokenService.removeUser();
        setAppState("LOGIN_REQUIRED");
        return;
      }
      const parsedUser = JSON.parse(localStorageUser);
      const { access_token, refresh_token } = parsedUser;
      const { data: accessTokenData, err: accessTokenErr } =
        await retrieveUserByAccessToken(access_token);
      if (accessTokenErr) {
        const { data: refreshTokenData, err: refreshTokenErr } =
          await retrieveAccessTokenByRefreshToken(refresh_token, access_token);
        if (refreshTokenErr) {
          TokenService.removeUser();
          setAppState("LOGIN_REQUIRED");
        } else {
          TokenService.setUser(refreshTokenData);
          setUserInformation(TokenService.getUser(refreshTokenData));
          setAppState("COMPLETED");
        }
      } else {
        TokenService.setUser(accessTokenData);
        setUserInformation(TokenService.getUser(accessTokenData));
        setAppState("COMPLETED");
      }
    };
    if (appState === "INITIALIZING") {
      retrieveAndSetLocalStorageUser();
    } else if (appState === "COMPLETED") {
      retrieveAndSetSchoolSettings();
    }
  }, [appState]);

  const retrieveUserByAccessToken = async (accessToken) => {
    try {
      const response = await axios.get(
        `${config.api.invokeUrl}/auth/by-accessToken`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      return { data: response.data };
    } catch (err) {
      return { err };
    }
  };

  const retrieveAccessTokenByRefreshToken = async (
    refreshToken,
    accessToken
  ) => {
    try {
      const response = await axios.post(
        `${config.api.invokeUrl}/auth/by-refreshToken`,
        {
          accessToken,
          refreshToken,
        }
      );
      return { data: response.data };
    } catch (err) {
      return { err };
    }
  };

  const compare = (a, b) => {
    if (a.sort < b.sort) {
      return -1;
    }
    if (a.sort > b.sort) {
      return 1;
    }
    return 0;
  };

  const RenderMainContent = () => {
    if (appState === "INITIALIZING") {
      return (
        <div className="h-100 flex-1 d-flex justify-content-center align-items-center">
          <HashLoader color="#082070" />
        </div>
      );
    } else if (appState === "LOGIN_REQUIRED") {
      return (
        <BrowserRouter>
          <main>
            <Routes>
              {loginRoutes.sort(compare).map((route, key) => (
                <Route
                  key={key}
                  path={route.path}
                  element={<route.component {...route} />}
                />
              ))}
              <Route path="*" element={<NoMatch />} />
            </Routes>
          </main>
        </BrowserRouter>
      );
    } else if (appState === "COMPLETED") {
      const { role } = userInformation;
      if (role === "ADMIN") {
        return (
          <BrowserRouter>
            <ShortMenu />
            <MainMenu />
            <main style={{ height: "100vh" }}>
              <div
                className="h-100 d-flex flex-column justify-content-between"
                style={{ overflowY: "auto" }}
              >
                <div>
                  <Header />
                </div>
                <div className="h-100" style={{ overflowY: "auto" }}>
                  <Routes>
                    {adminRoutes.sort(compare).map((route, key) => (
                      <Route
                        key={key}
                        path={route.path}
                        element={<route.component {...route} />}
                      />
                    ))}
                    <Route path="*" element={<NoMatch />} />
                  </Routes>
                </div>
                <div>
                  <Footer />
                </div>
              </div>
            </main>
          </BrowserRouter>
        );
      } else if (role === "PARENT") {
        return (
          <BrowserRouter>
            <main style={{ height: "100vh" }}>
              <div
                className="h-100 d-flex flex-column justify-content-between"
                style={{ overflowY: "auto" }}
              >
                <div>
                  <AppHeader />
                </div>
                <div className="mt-5">
                  <Routes>
                    {parentRoutes.sort(compare).map((route, key) => (
                      <Route
                        key={key}
                        path={route.path}
                        element={<route.component {...route} />}
                      />
                    ))}
                    <Route path="*" element={<NoMatch />} />
                  </Routes>
                </div>
                <div>
                  <Footer />
                </div>
              </div>
            </main>
          </BrowserRouter>
        );
      }
    }
  };
  return (
    <div style={{ height: "100vh" }}>
      {RenderMainContent()}
      {/* {userRole === "PARENT" ? <ContractMainPage /> : <Admin />} */}
    </div>
  );
}
