import React, { Component, createContext } from "react";
export const UserContext = createContext();

class UserContextProvider extends Component {
  state = {
    userInformation: null,
    schoolSettings: null,
  };

  setUserInformation = (userInformation) => {
    this.setState({ userInformation });
  };

  setSchoolSettings = (schoolSettings) => {
    this.setState({ schoolSettings });
  };

  render() {
    return (
      <UserContext.Provider
        value={{
          ...this.state,
          setUserInformation: this.setUserInformation,
          setSchoolSettings: this.setSchoolSettings,
        }}
      >
        {this.props.children}
      </UserContext.Provider>
    );
  }
}

export default UserContextProvider;
