import { convertToDateString } from "../../../utilization/DateOperations";

const StudentInformationTable = (props) => {
  const { student, parentFather, parentMother } = props;
  if (!student) return;

  return (
    <div className="table-responsive">
      <table className="table table-centered mb-0 rounded w-100">
        <tbody>
          <tr>
            <th>T.C. Kimlik Numarası</th>
            <td>: {student.identityNumber}</td>
          </tr>
          <tr>
            <th>Adı ve Soyadı</th>
            <td>
              : {student.name} {student.lastname}
            </td>
          </tr>
          {parentFather ? (
            <tr>
              <th>Baba Adı</th>
              <td>
                : {parentFather.user.name} {parentFather.user.lastname}
              </td>
            </tr>
          ) : null}
          {parentMother ? (
            <tr>
              <th>Anne Adı</th>
              <td>
                : {parentMother.user.name} {parentMother.user.lastname}
              </td>
            </tr>
          ) : null}

          <tr>
            <th>Doğum Yeri ve Yılı</th>
            <td>
              : {student.hometown} {convertToDateString(student.birthday)}
            </td>
          </tr>
          <tr>
            <th>Okula Kayıt veya Nakil Tarihi</th>
            <td>: {convertToDateString(student.schoolRecordDate)}</td>
          </tr>
          <tr>
            <th>Sınıfı</th>
            <td>: {student.grade.name}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default StudentInformationTable;
