import React, { useEffect, useState } from "react";
import FormInput from "../app/components/FormInput";
import BaseModal from "./components/BaseModal";
import { convertToDateString } from "../utilization/DateOperations";
import { formatMoneyToTL } from "../utilization/MoneyOperations";
import { convertToTurkish } from "../utilization/LanguageOperations";
import FormSelect from "../app/components/FormSelect";
import { NotificationManager } from "react-notifications";
import {
  handleCreateDiscountInformation,
  handleCreateFeeInformation,
  handleUpdateDiscountInformation,
  handleUpdateFeeInformation,
  retrieveAllFeesAndDiscountsInformationBySchoolId,
} from "../requests/ContractRequests";
import { queryUserLogsByEntityId } from "../requests/UserLogRequests";

export default function Common() {
  const [dataList, setDataList] = useState(null);
  const [selectedFee, setSelectedFee] = useState(null);
  const [selectedDiscount, setSelectedDiscount] = useState(null);
  const [showSelectedFeeModal, setShowSelectedFeeModal] = useState(false);
  const [showSelectedDiscountModal, setShowSelectedDiscountModal] =
    useState(false);
  const [feeLogList, setFeeLogList] = useState([]);
  const [discountLogList, setDiscountLogList] = useState([]);
  const [inputs, setInputs] = useState({
    name: "",
    explain: "",
    fees: "",
    taxRatio: "",
    startAt: new Date().toISOString().split("T")[0],
    endAt: "2024-10-01",
  });
  const [inputDis, setInputDis] = useState({
    name: "",
    explain: "",
    discount: 0,
    discountCategory: "DISCOUNT",
    startAt: new Date().toISOString().split("T")[0],
    endAt: "2024-10-01",
  });
  const schoolId = localStorage.getItem("schoolId");
  const user = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    if (!schoolId) return;
    retrieveAndSetAllFeesAndDiscountsInformation();
  }, [schoolId]);

  useEffect(() => {
    if (selectedFee) {
      setInputs({ ...selectedFee });
    } else {
      setInputs({
        name: "",
        explain: "",
        fees: "",
        taxRatio: "",
        startAt: new Date().toISOString().split("T")[0],
        endAt: "2024-10-01",
      });
    }
  }, [selectedFee]);

  useEffect(() => {
    if (selectedDiscount) {
      setInputDis({ ...selectedDiscount });
    } else {
      setInputDis({
        name: "",
        explain: "",
        discount: 0,
        discountCategory: "DISCOUNT",
        startAt: new Date().toISOString().split("T")[0],
        endAt: "2024-10-01",
      });
    }
  }, [selectedDiscount]);

  const retrieveAndSetAllFeesAndDiscountsInformation = async () => {
    const { data, err } =
      await retrieveAllFeesAndDiscountsInformationBySchoolId(
        schoolId,
        user?.access_token
      );
    if (err) {
      NotificationManager.error(
        "Ücret kalemleri ve indirimleri sorgulanırken bir hata oluştu"
      );
      return;
    }
    setDataList(data);
  };

  const constructDiscountCategoryOptionList = () => {
    return [
      {
        value: "DISCOUNT",
        name: convertToTurkish("DISCOUNT"),
      },
      {
        value: "SCHOLARSHIP",
        name: convertToTurkish("SCHOLARSHIP"),
      },
    ];
  };

  const handleCreateOrUpdateFeeInformationOnClick = async (e) => {
    e.preventDefault();
    const start = new Date(inputs.startAt).getTime();
    const end = new Date(inputs.endAt).getTime();
    if (inputs.id) {
      const { data, err } = await handleUpdateFeeInformation(
        inputs.id,
        {
          ...inputs,
          startAt: start,
          endAt: end,
          school: { id: schoolId },
        },
        user?.access_token
      );
      if (err) {
        NotificationManager.error(
          "Ücret kalemi güncellenirken bir hata oluştu"
        );
        return;
      } else {
        NotificationManager.success("Ücret kalemi başarıyla güncellendi");
      }
    } else {
      const { data, err } = await handleCreateFeeInformation(
        {
          ...inputs,
          startAt: start,
          endAt: end,
          school: { id: schoolId },
        },
        user?.access_token
      );
      if (err) {
        NotificationManager.error(
          "Ücret kalemi oluşturulurken bir hata oluştu"
        );
        return;
      } else {
        NotificationManager.success("Ücret kalemi başarıyla oluşturuldu");
      }
    }
    retrieveAndSetAllFeesAndDiscountsInformation();
    setShowSelectedFeeModal(false);
  };

  const saveDiscount = async (e) => {
    e.preventDefault();
    const start = new Date(inputDis.startAt).getTime();
    const end = new Date(inputDis.endAt).getTime();
    if (inputDis.id) {
      const { data, err } = await handleUpdateDiscountInformation(
        inputDis.id,
        {
          ...inputDis,
          startAt: start,
          endAt: end,
        },
        user?.access_token
      );
      if (err) {
        NotificationManager.error(
          "İndirim kalemi güncellenirken bir hata oluştu"
        );
        return;
      } else {
        NotificationManager.success("İndirim kalemi başarıyla güncellendi");
      }
    } else {
      const createDiscount = {
        ...inputDis,
        startAt: start,
        endAt: end,
        schoolFee: {
          id: selectedFee.id,
        },
      };
      if (selectedFee.grade) {
        createDiscount.grade = {
          id: selectedFee.grade.id,
        };
      }
      if (selectedFee.school) {
        createDiscount.school = {
          id: selectedFee.school.id,
        };
      }
      const { data, err } = await handleCreateDiscountInformation(
        createDiscount,
        user?.access_token
      );
      if (err) {
        NotificationManager.error(
          "İndirim kalemi oluşturulurken bir hata oluştu"
        );
        return;
      } else {
        NotificationManager.success("İndirim kalemi başarıyla oluşturuldu");
      }
    }
    retrieveAndSetAllFeesAndDiscountsInformation();
  };

  const updateDiscountForm = () => {
    return (
      <div>
        <div className="my-3">
          {selectedDiscount ? (
            <h5>İndirim kalemi düzenle</h5>
          ) : (
            <h5>İndirim kalemi ekle</h5>
          )}
        </div>
        <form>
          <div className="row">
            <FormInput
              name="name"
              text="Ad"
              placeholder=""
              type="text"
              col="6"
              value={inputDis.name}
              onChange={(e) =>
                setInputDis({ ...inputDis, name: e.target.value })
              }
            />
            <FormInput
              name="description"
              text="Açıklama"
              placeholder=""
              type="text"
              col="6"
              value={inputDis.explain}
              onChange={(e) =>
                setInputDis({ ...inputDis, explain: e.target.value })
              }
            />
            <FormSelect
              name="description"
              text="Kategori"
              placeholder=""
              type="text"
              col="3"
              required
              value={inputDis.discountCategory}
              data={constructDiscountCategoryOptionList()}
              onChange={(e) =>
                setInputDis({ ...inputDis, discountCategory: e.target.value })
              }
              valuekey="value"
              textkey="name"
            />
            <FormInput
              name="description"
              text="İndirim Oranı"
              placeholder=""
              type="number"
              col="3"
              value={inputDis.discount}
              onChange={(e) => {
                if (parseFloat(e.target.value) > 100) {
                  setInputDis({ ...inputDis, discount: 100 });
                } else if (parseFloat(e.target.value) < 0) {
                  setInputDis({ ...inputDis, discount: 0 });
                } else {
                  setInputDis({
                    ...inputDis,
                    discount: parseFloat(e.target.value),
                  });
                }
              }}
            />
            <FormInput
              name="description"
              text="Başlangıç"
              placeholder=""
              type="date"
              col="3"
              value={inputDis.startAt}
              onChange={(e) =>
                setInputDis({ ...inputDis, startAt: e.target.value })
              }
            />
            <FormInput
              name="description"
              text="Bitiş"
              placeholder=""
              type="date"
              col="3"
              value={inputDis.endAt}
              onChange={(e) =>
                setInputDis({ ...inputDis, endAt: e.target.value })
              }
            />
            <button
              className="btn btn-success text-light "
              onClick={(e) => {
                saveDiscount(e);
                setShowSelectedDiscountModal(false);
              }}
            >
              Kaydet
            </button>
          </div>
        </form>
      </div>
    );
  };

  const updateFeeForm = () => {
    return (
      <div>
        <div className="my-3">
          {selectedFee ? (
            <h5>Ücret kalemi düzenle</h5>
          ) : (
            <h5>Ücret kalemi ekle</h5>
          )}
        </div>
        <form>
          <div className="row">
            <FormInput
              name="name"
              text="Ad"
              placeholder=""
              type="text"
              col="6"
              value={inputs.name}
              onChange={(e) => setInputs({ ...inputs, name: e.target.value })}
            />
            <FormInput
              name="description"
              text="Açıklama"
              placeholder=""
              type="text"
              col="6"
              value={inputs.explain}
              onChange={(e) =>
                setInputs({ ...inputs, explain: e.target.value })
              }
            />

            <FormInput
              name="description"
              text="Ücret"
              placeholder=""
              type="number"
              col="3"
              value={inputs.fees}
              onChange={(e) =>
                setInputs({ ...inputs, fees: parseFloat(e.target.value) })
              }
            />

            <FormInput
              name="description"
              text="KDV"
              placeholder=""
              type="number"
              col="3"
              value={inputs.taxRatio}
              onChange={(e) =>
                setInputs({ ...inputs, taxRatio: parseFloat(e.target.value) })
              }
            />

            <FormInput
              name="description"
              text="Başlangıç"
              placeholder=""
              type="date"
              col="3"
              value={inputs.startAt}
              onChange={(e) =>
                setInputs({ ...inputs, startAt: e.target.value })
              }
            />
            <FormInput
              name="description"
              text="Bitiş"
              placeholder=""
              type="date"
              col="3"
              value={inputs.endAt}
              onChange={(e) => setInputs({ ...inputs, endAt: e.target.value })}
            />
            <button
              className="btn btn-success text-light "
              onClick={(e) => {
                handleCreateOrUpdateFeeInformationOnClick(e);
              }}
            >
              Kaydet
            </button>
          </div>
        </form>
      </div>
    );
  };

  const filterFees = () => {
    if (!dataList) return [];
    if (!dataList.fees) return [];
    return dataList.fees;
  };

  const filterDiscounts = () => {
    if (!selectedFee) return [];
    if (!dataList) return [];
    if (!dataList.discounts) return [];
    return dataList.discounts.filter(
      (item) => item.schoolFee.id === selectedFee.id
    );
  };

  const handleSelectFeeOnClick = async (id) => {
    const { data, err } = await queryUserLogsByEntityId(id, user?.access_token);
    if (err) {
      NotificationManager.error(
        "Kullanıcı kayıtları sorgulanırken bir hata oluştu"
      );
      return;
    }
    setFeeLogList(data);
  };

  const handleSelectDiscountOnClick = async (id) => {
    const { data, err } = await queryUserLogsByEntityId(id, user?.access_token);
    if (err) {
      NotificationManager.error(
        "Kullanıcı kayıtları sorgulanırken bir hata oluştu"
      );
      return;
    }
    setDiscountLogList(data);
  };

  const RenderFeeContent = () => {
    return (
      <div className="table-responsive">
        <div className="row">
          <button
            className="btn btn-sm btn-secondary me-2"
            onClick={() => {
              setSelectedFee(null);
              setInputs({
                name: "",
                explain: "",
                fees: "",
                taxRatio: "",
                startAt: new Date().toISOString().split("T")[0],
                endAt: "2024-10-01",
              });
              setShowSelectedFeeModal(true);
            }}
          >
            Yeni ücret kalemi tanımla
          </button>
        </div>
        <table className="table table-centered mb-0 rounded table-hover w-100">
          <thead className="thead-light">
            <tr>
              <th className="border-0">İSİM</th>
              <th className="border-0">AÇIKLAMA</th>
              <th className="border-0">TUTAR</th>
              <th className="border-0">GEÇERLİLİK TARİHİ</th>
              <th className="border-0">VERGİ ORANI</th>
              <th className="border-0"></th>
            </tr>
          </thead>
          <tbody>
            {filterFees().map((f, key) => (
              <tr
                onClick={() => {
                  setSelectedFee({
                    ...f,
                    startAt: new Date(f.startAt).toISOString().split("T")[0],
                    endAt: new Date(f.endAt).toISOString().split("T")[0],
                  });
                }}
                key={key}
                style={{ cursor: "pointer" }}
                className={`${
                  selectedFee?.id === f.id ? "table-warning" : ""
                } `}
              >
                <td>{f.name}</td>
                <td>{f.explain}</td>
                <td>{`${formatMoneyToTL(f.fees)} TL`}</td>
                <td>
                  {convertToDateString(f.startAt)} -{" "}
                  {convertToDateString(f.endAt)}
                </td>
                <td>%{f.taxRatio}</td>
                <td>
                  <button
                    className="btn btn-info "
                    onClick={() => {
                      handleSelectFeeOnClick(f.id);
                      setShowSelectedFeeModal(true);
                    }}
                  >
                    İşlem
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  const RenderDiscountsContent = () => {
    if (!selectedFee) return;
    return (
      <div className="table-responsive">
        <div className="row">
          <button
            className="btn btn-sm btn-secondary me-2"
            onClick={() => {
              setSelectedDiscount(null);
              setInputDis({
                name: "",
                explain: "",
                discount: 0,
                discountCategory: "DISCOUNT",
                startAt: new Date().toISOString().split("T")[0],
                endAt: "2024-10-01",
              });
              setShowSelectedDiscountModal(true);
            }}
          >
            Yeni indirim tanımla
          </button>
        </div>
        <table className="table table-centered mb-0 rounded table-hover w-100">
          <thead className="thead-light">
            <tr>
              <th className="border-0">İSİM</th>
              <th className="border-0">KATEGORİ</th>
              <th className="border-0">ORAN</th>
              <th className="border-0">GEÇERLİLİK TARİHİ</th>
              <th className="border-0"></th>
            </tr>
          </thead>
          <tbody>
            {filterDiscounts().map((f, key) => (
              <tr
                onClick={() => {
                  setSelectedDiscount({
                    ...f,
                    startAt: new Date(f.startAt).toISOString().split("T")[0],
                    endAt: new Date(f.endAt).toISOString().split("T")[0],
                  });
                }}
                key={key}
                style={{ cursor: "pointer" }}
                className={`${
                  selectedFee && selectedFee.id === f.id ? "table-warning" : ""
                } `}
              >
                <td>{f.name}</td>
                <td>{convertToTurkish(f.discountCategory)}</td>
                <td>%{f.discount}</td>
                <td>
                  {convertToDateString(f.startAt)} -{" "}
                  {convertToDateString(f.endAt)}
                </td>
                <td>
                  {f.rule === "SIBLING_DISCOUNT" ||
                  f.rule === "EARLY_DISCOUNT" ? null : (
                    <button
                      className="btn btn-info "
                      onClick={() => {
                        handleSelectDiscountOnClick(f.id);
                        setShowSelectedDiscountModal(true);
                      }}
                    >
                      İşlem
                    </button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  const RenderSelectedFeeModalContent = () => {
    return (
      <BaseModal
        modalIsOpen={showSelectedFeeModal}
        contentLabel={"Ücret kalemi bilgileri"}
        closeModal={() => {
          setShowSelectedFeeModal(false);
        }}
      >
        <div className="col-12 ">
          {updateFeeForm()}
          {feeLogList?.length > 0 ? (
            <div className="px-2" style={{ maxHeight: 450, overflowY: "auto" }}>
              <div
                className="mt-4 mb-2"
                style={{ borderBottomWidth: 1, borderBottomStyle: "dashed" }}
              >
                <div className="" style={{ fontWeight: "bold" }}>
                  Değiştirilme geçmişi
                </div>
              </div>
              {feeLogList.map((feeLog) => {
                const { name, lastname, createdAt, operation, description } =
                  feeLog;
                const parsedDescription = JSON.parse(description);
                return (
                  <div className="my-1">
                    <div>
                      -{" "}
                      <span style={{ fontWeight: "bold" }}>
                        {name} {lastname}
                      </span>
                      {" tarafından "}
                      <span style={{ fontWeight: "bold" }}>
                        {new Date(createdAt).toLocaleString("TR")}
                      </span>
                      {" tarihinde "}
                      <span style={{ fontStyle: "italic" }}>
                        ismi:{" "}
                        <span style={{ fontWeight: "bold" }}>
                          {parsedDescription.name}
                        </span>
                        , açıklaması:{" "}
                        <span style={{ fontWeight: "bold" }}>
                          {parsedDescription.description}
                        </span>
                        , ücreti:{" "}
                        <span style={{ fontWeight: "bold" }}>
                          {formatMoneyToTL(parseFloat(parsedDescription.fees))}{" "}
                          TL
                        </span>
                        , KDV oranı:{" "}
                        <span style={{ fontWeight: "bold" }}>
                          {parsedDescription.taxRatio}%
                        </span>
                        , başlangıç tarihi{" "}
                        <span style={{ fontWeight: "bold" }}>
                          {new Date(
                            parseFloat(parsedDescription.startAt)
                          ).toLocaleDateString("TR")}
                        </span>{" "}
                        ve bitiş tarihi{" "}
                        <span style={{ fontWeight: "bold" }}>
                          {new Date(
                            parseFloat(parsedDescription.endAt)
                          ).toLocaleDateString("TR")}
                        </span>
                      </span>
                      {operation === "UPDATE"
                        ? " olacak şekilde güncellendi."
                        : " olacak şekilde yaratıldı."}
                    </div>
                  </div>
                );
              })}
            </div>
          ) : null}
        </div>
      </BaseModal>
    );
  };

  const RenderSelectedDiscountModalContent = () => {
    return (
      <BaseModal
        modalIsOpen={showSelectedDiscountModal}
        contentLabel={"İndirim kalemi bilgileri"}
        closeModal={() => {
          setShowSelectedDiscountModal(false);
        }}
      >
        <div className="col-12 ">
          {updateDiscountForm()}
          {discountLogList?.length > 0 ? (
            <div className="px-2" style={{ maxHeight: 450, overflowY: "auto" }}>
              <div
                className="mt-4 mb-2"
                style={{ borderBottomWidth: 1, borderBottomStyle: "dashed" }}
              >
                <div className="" style={{ fontWeight: "bold" }}>
                  Değiştirilme geçmişi
                </div>
              </div>
              {discountLogList.map((discountLog) => {
                const { name, lastname, createdAt, operation, description } =
                  discountLog;
                const parsedDescription = JSON.parse(description);
                return (
                  <div className="my-1">
                    <div>
                      -{" "}
                      <span style={{ fontWeight: "bold" }}>
                        {name} {lastname}
                      </span>
                      {" tarafından "}
                      <span style={{ fontWeight: "bold" }}>
                        {new Date(createdAt).toLocaleString("TR")}
                      </span>
                      {" tarihinde "}
                      <span style={{ fontStyle: "italic" }}>
                        ismi:{" "}
                        <span style={{ fontWeight: "bold" }}>
                          {parsedDescription.name}
                        </span>
                        , açıklaması:{" "}
                        <span style={{ fontWeight: "bold" }}>
                          {parsedDescription.explain}
                        </span>
                        , kategorisi:{" "}
                        <span style={{ fontWeight: "bold" }}>
                          {convertToTurkish(parsedDescription.discountCategory)}
                        </span>
                        , indirim oranı:{" "}
                        <span style={{ fontWeight: "bold" }}>
                          {parsedDescription.discount}%
                        </span>
                        , başlangıç tarihi{" "}
                        <span style={{ fontWeight: "bold" }}>
                          {new Date(
                            parseFloat(parsedDescription.startAt)
                          ).toLocaleDateString("TR")}
                        </span>{" "}
                        ve bitiş tarihi{" "}
                        <span style={{ fontWeight: "bold" }}>
                          {new Date(
                            parseFloat(parsedDescription.endAt)
                          ).toLocaleDateString("TR")}
                        </span>
                      </span>
                      {operation === "UPDATE"
                        ? " olacak şekilde güncellendi."
                        : " olacak şekilde yaratıldı."}
                    </div>
                  </div>
                );
              })}
            </div>
          ) : null}
        </div>
      </BaseModal>
    );
  };

  return (
    <>
      {RenderSelectedFeeModalContent()}
      {RenderSelectedDiscountModalContent()}
      <div className="content">
        <div className="row">
          <div className="col-12 col-xl-6">
            <div className="card border-0 shadow">
              <div className="card-body">{RenderFeeContent()}</div>
            </div>
          </div>
          {selectedFee ? (
            <div className="col-12 col-xl-6">
              <div className="card shadow border-0 p-4">
                {RenderDiscountsContent()}
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
}
