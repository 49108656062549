import { useState } from "react";
import FormInput from "./FormInput";
import FormSelect from "./FormSelect";
import Cards from "react-credit-cards-2";

const mountNames = [
  { name: "01" },
  { name: "02" },
  { name: "03" },
  { name: "04" },
  { name: "05" },
  { name: "06" },
  { name: "07" },
  { name: "08" },
  { name: "09" },
  { name: "10" },
  { name: "11" },
  { name: "12" },
];

const yearNames = [
  { name: "2023", value: "23" },
  { name: "2024", value: "24" },
  { name: "2025", value: "25" },
  { name: "2026", value: "26" },
  { name: "2027", value: "27" },
  { name: "2028", value: "28" },
  { name: "2029", value: "29" },
  { name: "2030", value: "30" },
  { name: "2031", value: "31" },
  { name: "2032", value: "32" },
  { name: "2033", value: "33" },
  { name: "2034", value: "34" },
  { name: "2035", value: "35" },
  { name: "2036", value: "36" },
  { name: "2037", value: "37" },
  { name: "2038", value: "39" },
];

export default function CreditCart(props) {
  const { inputs, setInputs } = props;
  const [focus, setFocus] = useState();

  return (
    <div className="row align-items-md-center">
      <div className="col-md-6">
        <Cards
          number={inputs.ccNumber}
          expiry={`${inputs.ccMonth}/${inputs.ccYear}`}
          cvc={inputs.ccCVC}
          name={inputs.ccName}
          focused={focus}
        />
      </div>
      <div className="col-md-6">
        <form>
          <div className="row" style={{ flexWrap: "wrap" }}>
            <FormInput
              name="ccName"
              text="Kart Sahibi"
              placeholder="İsim ve Soyisim Giriniz"
              type="text"
              col="12"
              value={inputs.ccName}
              onChange={setInputs}
              onFocus={() => {
                setFocus("name");
              }}
            />
            <FormInput
              name="ccNumber"
              text="Kart Numarası"
              placeholder="Kart Numarasını Giriniz"
              type="text"
              col="12"
              value={inputs.ccNumber}
              onChange={setInputs}
              onFocus={() => {
                setFocus("number");
              }}
            />
            <FormSelect
              name="ccMonth"
              text="Ay"
              placeholder=""
              type="text"
              col="4"
              required
              value={inputs.ccMonth}
              onChange={setInputs}
              id="ayId"
              data={mountNames}
              valuekey="name"
              textkey="name"
              onFocus={() => {
                setFocus("expiry");
              }}
            />
            <FormSelect
              name="ccYear"
              text="Yıl"
              placeholder=""
              type="text"
              col="4"
              required
              value={inputs.ccYear}
              onChange={setInputs}
              id="ayId"
              data={yearNames}
              valuekey="value"
              textkey="name"
              onFocus={() => {
                setFocus("expiry");
              }}
            />
            <FormInput
              name="ccCVC"
              text="CVC"
              placeholder="CVC"
              type="text"
              col="4"
              value={inputs.ccCVC}
              onChange={setInputs}
              onFocus={() => {
                setFocus("cvc");
              }}
            />
          </div>
        </form>
      </div>
    </div>
  );
}
