import {
  Document,
  Font,
  PDFViewer,
  Page,
  Text,
  View,
  Image,
} from "@react-pdf/renderer";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import { UserContext } from "../../../context/UserContextProvider";
import Logo from "../../../assets/logo.png";
import { formatMoneyToTL } from "../../../utilization/MoneyOperations";
import { convertToDateString } from "../../../utilization/DateOperations";
import { retrieveStudentContractByStudentIdAndParentId } from "../../../requests/ContractRequests";
import { NotificationManager } from "react-notifications";

Font.register({
  family: "Roboto",
  src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-light-webfont.ttf",
  fontWeight: "normal",
});

Font.register({
  family: "Roboto",
  src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-medium-webfont.ttf",
  fontWeight: "bold",
});

const ContractPDFScreen = () => {
  const { id } = useParams();
  const userContext = useContext(UserContext);
  const { userInformation } = userContext;
  const [studentContract, setStudentContract] = useState(null);
  const parentUser = JSON.parse(localStorage.getItem("parent_data"));
  const user = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    const retrieveAndSetStudentContractByStudentIdAndParentId = async () => {
      const { data, err } = await retrieveStudentContractByStudentIdAndParentId(
        id,
        parentUser?.user_id,
        user?.access_token
      );
      if (err) {
        NotificationManager.error(
          "Öğrenci sözleşme detayı sorgulanırken bir hata oluştu"
        );
        return;
      }
      setStudentContract(data);
    };
    if (!id) return;
    retrieveAndSetStudentContractByStudentIdAndParentId();
  }, [id]);

  const constructTotalFeePrice = () => {
    let total = 0;
    for (let i = 0; i < retrieveContractFees().length; i++) {
      total += retrieveContractFees()[i].totalFees;
    }
    return total;
  };

  const retrieveStudent = () => {
    return studentContract.student;
  };

  const findParentByParentType = (parentType) => {
    return retrieveStudent().parents.find((p) => p.parentType === parentType);
  };

  const retrieveContractFees = () => {
    return studentContract.contractFees;
  };

  const retrieveContractDiscounts = () => {
    return studentContract.contractDiscounts;
  };

  const retrieveDiscountsInFee = (feesId) => {
    if (retrieveContractDiscounts()) {
      const discountListForFees = retrieveContractDiscounts().filter(
        (discount) =>
          discount.contractFees && discount.contractFees.id === feesId
      );
      return discountListForFees;
    }
    return [];
  };

  const filterPayments = () => {
    return studentContract.payments.filter((payment) => {
      const { contractPaymentTransferItem } = payment;
      if (contractPaymentTransferItem) {
        const { status } = contractPaymentTransferItem;
        return status === "APPROVED";
      } else {
        return payment;
      }
    });
  };

  const RenderTableKeyValue = (key, value) => {
    return (
      <>
        <View
          style={{
            width: "50%",
            borderBottom: 1,
            borderColor: "gray",
            fontWeight: "bold",
          }}
        >
          <Text>{key}</Text>
        </View>
        <View style={{ width: "50%", borderBottom: 1, borderColor: "gray" }}>
          <Text>: {value}</Text>
        </View>
      </>
    );
  };

  const RenderTableKeyDoubleValue = (key, value1, value2) => {
    let width = 0;
    if (value1 && value2) {
      width = 33;
    } else if (!value1 && !value2) {
      width = 100;
    } else {
      width = 50;
    }
    return (
      <>
        <View
          style={{
            width: `${width}%`,
            borderBottom: 1,
            borderColor: "gray",
            fontWeight: "bold",
          }}
        >
          <Text>{key}</Text>
        </View>
        {value1 ? (
          <View
            style={{ width: `${width}%`, borderBottom: 1, borderColor: "gray" }}
          >
            <Text>: {value1}</Text>
          </View>
        ) : null}
        {value2 ? (
          <View
            style={{ width: `${width}%`, borderBottom: 1, borderColor: "gray" }}
          >
            <Text>: {value2}</Text>
          </View>
        ) : null}
      </>
    );
  };

  const RenderTableThreeValue = (value1, value2, value3, isBold) => {
    return (
      <>
        <View
          style={{
            width: "33%",
            borderBottom: 1,
            borderColor: "gray",
            fontWeight: isBold ? "bold" : "normal",
          }}
        >
          <Text>{value1}</Text>
        </View>
        <View
          style={{
            width: "33%",
            borderBottom: 1,
            borderColor: "gray",
            fontWeight: isBold ? "bold" : "normal",
          }}
        >
          <Text>{value2}</Text>
        </View>
        <View
          style={{
            width: "33%",
            borderBottom: 1,
            borderColor: "gray",
            fontWeight: isBold ? "bold" : "normal",
          }}
        >
          <Text>{value3}</Text>
        </View>
      </>
    );
  };

  const RenderTableFiveValue = (
    value1,
    value2,
    value3,
    value4,
    value5,
    isBold
  ) => {
    return (
      <>
        <View
          style={{
            width: "20%",
            borderBottom: 1,
            borderColor: "gray",
            fontWeight: isBold ? "bold" : "normal",
          }}
        >
          <Text>{value1}</Text>
        </View>
        <View
          style={{
            width: "20%",
            borderBottom: 1,
            borderColor: "gray",
            fontWeight: isBold ? "bold" : "normal",
          }}
        >
          <Text>{value2}</Text>
        </View>
        <View
          style={{
            width: "20%",
            borderBottom: 1,
            borderColor: "gray",
            fontWeight: isBold ? "bold" : "normal",
          }}
        >
          <Text>{value3}</Text>
        </View>
        <View
          style={{
            width: "20%",
            borderBottom: 1,
            borderColor: "gray",
            fontWeight: isBold ? "bold" : "normal",
          }}
        >
          <Text>{value4}</Text>
        </View>
        <View
          style={{
            width: "20%",
            borderBottom: 1,
            borderColor: "gray",
            fontWeight: isBold ? "bold" : "normal",
          }}
        >
          <Text>{value5}</Text>
        </View>
      </>
    );
  };

  const RenderContractHeader = () => {
    return (
      <>
        <View style={{ flexDirection: "column", alignItems: "center" }}>
          <Text style={{}}>T.C.</Text>
          <Text style={{}}>MİLLÎ EĞİTİM BAKANLIĞI</Text>
          <Text style={{}}>
            ÖZEL ÖĞRETİM KURUMLARI GENEL MÜDÜRLÜĞÜ ÖĞRENCİ KAYIT SÖZLEŞMESİ
          </Text>
        </View>
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <View style={{ width: 100, marginVertical: 10 }}>
            <Image src={Logo} style={{}} />
          </View>
          <View
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Text style={{ fontWeight: "bold" }}>
              {retrieveStudent().name} {retrieveStudent().lastname}
            </Text>
            <View style={{ marginTop: 20 }} />
            <Text style={{ fontWeight: "bold" }}>
              {retrieveStudent().school.name}
            </Text>
            <Text style={{ fontWeight: "bold" }}>2024-2025 ÖĞRETİM YILI</Text>
          </View>
          <View style={{ width: 100, marginVertical: 10 }}>
            <Image src={Logo} style={{}} />
          </View>
        </View>
      </>
    );
  };

  const RenderStudentInformationTableWithTitle = () => {
    return (
      <View
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <View style={{ marginVertical: 10 }}>
          <Text style={{ fontWeight: "bold" }}>Öğrenci bilgileri</Text>
        </View>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            flexWrap: "wrap",
          }}
        >
          {RenderTableKeyValue(
            "T.C. Kimlik Numarası",
            retrieveStudent().identityNumber
          )}
          {RenderTableKeyValue(
            "Adı ve Soyadı",
            `${retrieveStudent().name} ${retrieveStudent().lastname}`
          )}
          {findParentByParentType("BABA")
            ? RenderTableKeyValue(
                "Baba Adı",
                `${findParentByParentType("BABA").user.name} ${
                  findParentByParentType("BABA").user.lastname
                }`
              )
            : null}
          {findParentByParentType("ANNE")
            ? RenderTableKeyValue(
                "Anne Adı",
                `${findParentByParentType("ANNE").user.name} ${
                  findParentByParentType("ANNE").user.lastname
                }`
              )
            : null}
          {RenderTableKeyValue(
            "Doğum Yeri ve Yılı",
            `${retrieveStudent().hometown} ${convertToDateString(
              retrieveStudent().birthday
            )}`
          )}
          {RenderTableKeyValue(
            "Okula Kayıt veya Nakil Tarihi",
            convertToDateString(retrieveStudent().schoolRecordDate)
          )}
          {RenderTableKeyValue("Sınıfı", retrieveStudent().grade.name)}
        </View>
      </View>
    );
  };

  const RenderParentInformationTableWithTitle = () => {
    let width = 0;
    if (findParentByParentType("ANNE") && findParentByParentType("BABA")) {
      width = 33;
    } else if (
      !findParentByParentType("ANNE") &&
      !findParentByParentType("BABA")
    ) {
      width = 100;
    } else {
      width = 50;
    }
    return (
      <View
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <View style={{ marginVertical: 10 }}>
          <Text style={{ fontWeight: "bold" }}>Aile bilgileri</Text>
        </View>
        <View style={{ marginVertical: 5 }}>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <View style={{ width: `${width}%` }}></View>
            {findParentByParentType("ANNE") ? (
              <View style={{ width: `${width}%` }}>
                <Text>ANNE</Text>
              </View>
            ) : null}

            {findParentByParentType("BABA") ? (
              <View style={{ width: `${width}%` }}>
                <Text>BABA</Text>
              </View>
            ) : null}
          </View>
        </View>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            flexWrap: "wrap",
          }}
        >
          {RenderTableKeyDoubleValue(
            "T.C. Kimlik Numarası",
            findParentByParentType("ANNE")?.user.identityNumber,
            findParentByParentType("BABA")?.user.identityNumber
          )}
          {RenderTableKeyDoubleValue(
            "Adı ve Soyadı",
            findParentByParentType("ANNE")
              ? `${findParentByParentType("ANNE")?.user.name} ${
                  findParentByParentType("ANNE")?.user.lastname
                }`
              : null,
            findParentByParentType("BABA")
              ? `${findParentByParentType("BABA")?.user.name} ${
                  findParentByParentType("BABA")?.user.lastname
                }`
              : null
          )}
          {RenderTableKeyDoubleValue(
            "Mesleği",
            findParentByParentType("ANNE")?.job,
            findParentByParentType("BABA")?.job
          )}
          {RenderTableKeyDoubleValue(
            "Cep Telefonu",
            findParentByParentType("ANNE")?.user.phone,
            findParentByParentType("BABA")?.user.phone
          )}
          {RenderTableKeyDoubleValue(
            "Ev Telefonu",
            findParentByParentType("ANNE")?.homePhone,
            findParentByParentType("BABA")?.homePhone
          )}
          {RenderTableKeyDoubleValue(
            "İş Telefonu",
            findParentByParentType("ANNE")?.businessPhone,
            findParentByParentType("BABA")?.businessPhone
          )}
          {RenderTableKeyDoubleValue(
            "Ev Adresi",
            findParentByParentType("ANNE")?.homeAddress,
            findParentByParentType("BABA")?.homeAddress
          )}
          {RenderTableKeyDoubleValue(
            "İş Adresi",
            findParentByParentType("ANNE")?.businessAddress,
            findParentByParentType("BABA")?.businessAddress
          )}
          {RenderTableKeyDoubleValue(
            "E-Posta",
            findParentByParentType("ANNE")?.user.email,
            findParentByParentType("BABA")?.user.email
          )}
        </View>
      </View>
    );
  };

  const RenderFeesTableWithTitle = () => {
    return (
      <View
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <View style={{ marginVertical: 10 }}>
          <Text style={{ fontWeight: "bold" }}>Ödeme bilgileri</Text>
        </View>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            flexWrap: "wrap",
          }}
        >
          {RenderTableFiveValue(
            "ÜCRET KALEMİ",
            "BEDELİ",
            "KDV ORANI",
            "KURUMUN İLAN ETTİĞİ ÜCRETLER (KDV DAHİL)",
            "ÖĞRENCİ İÇİN BELİRLENEN ÜCRETLER (KDV DAHİL)",
            true
          )}
          {retrieveContractFees().map((contractFee) => (
            <>
              {RenderTableFiveValue(
                contractFee.name,
                `${formatMoneyToTL(contractFee.fees)} TL`,
                `%${contractFee.taxRatio}`,
                `${formatMoneyToTL(contractFee.feesWithTax)} TL`,
                `${formatMoneyToTL(contractFee.totalFees)} TL`
              )}
              {retrieveDiscountsInFee(contractFee.id).map((contractDiscount) =>
                RenderTableFiveValue(
                  "",
                  `${contractDiscount.name} (%${contractDiscount.discount})`,
                  "",
                  `-${formatMoneyToTL(contractDiscount.discountFees)} TL`,
                  ""
                )
              )}
            </>
          ))}
          {RenderTableFiveValue(
            "",
            "",
            "",
            `GENEL TOPLAM`,
            `${formatMoneyToTL(constructTotalFeePrice())} TL`
          )}
        </View>
      </View>
    );
  };

  const RenderCreditCardInstallment = () => {
    return (
      <View
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <View style={{ marginVertical: 10 }}></View>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            flexWrap: "wrap",
          }}
        >
          {RenderTableThreeValue("TÜR", "DETAY", "TUTAR", true)}
          {filterPayments().map((payment) =>
            RenderTableThreeValue(
              payment.paymentType === "PESIN"
                ? "Peşin ödeme"
                : payment.paymentType === "PESİNAT"
                ? "Peşinat"
                : payment.paymentType === "TAKSİT"
                ? "Taksitli ödeme"
                : payment.paymentType === "HAVALE"
                ? "Havale"
                : payment.paymentType === "KMH"
                ? `KMH (Ödeme ${payment.installmentNo})`
                : null,
              convertToDateString(payment.installmentDate),
              payment.paymentType === "PESIN" ||
                payment.paymentType === "PESİNAT" ||
                payment.paymentType === "HAVALE"
                ? formatMoneyToTL(payment.cash) + " TL"
                : formatMoneyToTL(payment.installmentFee) + " TL"
            )
          )}
        </View>
      </View>
    );
  };

  const RenderContractText = () => {
    return (
      <View style={{ marginVertical: 10 }}>
        <View style={{ marginVertical: 10 }}>
          <Text style={{ fontWeight: "bold" }}>GENEL HUSUSLAR</Text>
        </View>
        <View style={{ paddingHorizontal: 10 }}>
          <Text>
            1. Öğrencinin bir sonraki eğitim öğretim yılında okula devam etmek
            istemesi durumunda, yukarıda “Ödeme Bilgileri Bölümünde Öğrenci İçin
            Belirlenen Öğrenim Ücreti” üzerinden artış yapılacaktır. Bir sonraki
            yılda öğrencinin aynı okulun ara sınıfına devam emek istemesi
            durumunda eğitim ücreti, yılsonu TÜFE oranı dikkate alınarak
            Bakanlıkça belirlenen oranı aşmayacak şekilde tespit edilir.
          </Text>
          <Text>
            2. Kurumumuz, bir sonraki yılın eğitim ve öğretim ücretini ve diğer
            (takviye kursları / ÜDEP vb), etkinlik, yemek, kıyafet, kırtasiye,
            yatakhane vb. ve etkinlik ücretleri, ocak ayından itibaren mayıs
            ayının sonuna kadar ilan eder. Bu ilanlarda ders yılı veya ders
            saati ücretiyle birlikte, peşin veya taksitli ödemeler ve diğer
            indirimler de belirtilir.
          </Text>
          <Text>
            3. Sosyal ve kültürel faaliyetler, gezi ve gözlem gibi diğer
            etkinlikler için ücretler, faaliyet öncesi belirlenir. Faaliyetlere
            katılım ise veli/vasi izni doğrultusunda gerçekleşir.
          </Text>
          <Text>
            4. Kurumumuz, öğrenci veya velilerinden beceri eğitimi / staj
            çalışması adı altında ayrı bir ücret talep etmeyecektir.
          </Text>
          <Text>
            5. Kurumumuzun süresi içinde eğitim ve öğretim ücretini ilan
            etmemesi halinde, kurumumuzda bir önceki yılın sözleşmesindeki
            eğitim ve öğretim ücreti geçerli olacaktır.
          </Text>
          <Text>
            6. Kurumumuzun eğitim ve öğretim ücreti kurum adına açılan ve
            Valiliğe bildirilen banka hesap numarasına yatırılarak tahsil
            edilir. Öğrencilerden alınan ücretler e-Okul sistemine işlenir ve
            ücretler velilere duyurulur.
          </Text>
          <Text>
            7. Kurumumuz, çalışma takviminde belirtilen ders yılı sonunda
            ücretini ödemeyen öğrencilerin kayıtlarını yenilemeyebilir. Ücretini
            ödememekte ısrarcı olan velinin/vasinin çocuğunun kaydı, maarif
            müfettişleri tarafından yapılacak inceleme sonucunda nakil ve
            yerleştirme komisyonu aracılığıyla uygun olan resmi okula veya açık
            liseye alınır.
          </Text>
          <Text>8. Kayıt Yenileme İşlemleri</Text>
          <View style={{ marginHorizontal: 10 }}>
            <Text>
              a. TED Okulları Kurum Yönetmeliği’nin (MEB - 16.03.2018 - 95178073
              sayılı onayı) ilgili maddeleri gereği okullarımızda öğrenim gören
              öğrencilerin kayıt yenileme işlemlerini okulun belirlediği
              tarihlerde tamamlaması gerekmektedir.
            </Text>
            <Text>
              b. TED Okulları Kurum Yönetmeliğin gereği belirlenen son kayıt
              tarihine kadar mazeret belirtmeksizin kaydını yenilemeyen
              öğrenciler için, açıklanan o yıla ait indirimler uygulanmayacağı
              gibi kontenjanı dolu olan okullarımızda kayıt yenileme işlemi
              yapılamayacaktır.
            </Text>
            <Text>
              c. İçinde bulunulan eğitim öğretim yılında peşin ödeme, taksitli
              ödeme, kardeş indirimi , öğretmen indirimi , kurumsal ( THY, TSK,
              TOKİ, Kanuni Sultan Süleyman Hst. , Acıbadem Hst. , Esenyurt
              N.K.Devlet Hst. Vb.) uygulanan indirimler 1 yıl geçerli olup,
              sonraki yıl kayıt yenileme işlemlerinde yeniden güncellenir. Ted
              Atakent Koleji söz konusu İndirimleri kaldırma, değişiklik yapma
              ve oranlarını azaltma hakkını saklı tutar.
            </Text>
            <Text>
              d. TED Okulları Kurum Yönetmeliğin gereği belirlenen son kayıt
              tarihine kadar mazeret belirtmeksizin kaydını yenilemeyen burslu
              öğrenciler burs haklarını kaybedeceklerdir.
            </Text>
          </View>
          <Text>
            9. MEB Özel Öğretim Kurumları Yönetmeliğinin 56-3a Maddesi
            hükümlerince; eğitim ve öğretim yılı başlamadan kurumumuzdan
            ayrılanlara, öğrencinin ödeyeceği yıllık ücretin %10 dışındaki
            kısmı, eğitim ve öğretim yılı başladıktan sonra kurumumuzdan
            ayrılanlara ise öğrencinin ödeyeceği yıllık ücretin %10’u ile
            öğrenim gördüğü günlere göre hesaplanan miktarın dışındaki kısmı
            öğrenci veli/vasisine iade edilir.
          </Text>
          <Text>
            10. Kurumumuz tarafından öğrencinin ücretsiz okumasının uygun
            bulunması veya indirim hakkı elde etmesi halinde kurumumuza ödenen
            ücret, eğitim ve öğretimin başladıktan sonra iki ay içinde öğrenci
            veli/vasisine geri ödenir.
          </Text>
          <Text>
            11. Veliler, kurumumuzda kardeş indiriminden yararlanan öğrencinin,
            kardeşinin okuldan ayrılması halinde, kardeş indirimi tutarını
            kuruma geri ödemeyi kabul etmiş sayılır.
          </Text>
          <Text>
            12. Herhangi bir şekilde kurumumuzdan ayrılan burslu öğrencilerden
            öğrenim gördüğü süre için kurumumuz tarafından herhangi bir ücret
            talep edilmeyecektir.
          </Text>
          <Text>
            13. Öğrencinin okula devam etmesi esastır. Devamsızlık yapan
            öğrencilerin velileri/vasileri, öğrencilerin mazeretlerini okula
            bildirir. Veli/vasilerce söz konusu bildirimin okula yapılmaması
            halinde, okul idaresi gelmeyen öğrencilerin velisiyle/vasisiyle
            iletişim kurarak devamsız öğrenci hakkında veli/vasiye bilgi verir.
          </Text>
          <Text>
            14. Taraflar; Milli Eğitim Bakanlığı ve resmi kurumların alacağı
            kararlar doğrultusunda belirlenen yöntemle; yüz yüze, online,
            uzaktan ve benzeri bir şekilde yapılacak eğitim- öğretim hizmetinin,
            kurumun iş bu sözleşmede belirlenen ve yapmakla yükümlü olduğu
            eğitim-öğretim hizmetini ve buna ilişkin her türlü edimini ifa
            ettiği ve ifa etmiş sayılacağı hususunda mutabık olduklarını kabul
            ve beyan etmişlerdir.
          </Text>
          <Text>
            15. İş bu sözleşmede yer alan hususların yerine getirilmesi ve
            gerekli tebligatların yapılabilmesi için veli/vasi tarafından ilk
            sayfada belirtilen adresin yasal ikametgâh adresi olduğu ve adres
            değişikliği durumunda 15 gün içerisinde yazılı olarak okula
            bildirilmediği takdirde bu adrese yapılacak tebligat geçerli
            olacaktır. Öğrenci kayıt sözleşmesinin tüm hükümleri veli/vasi
            tarafından eksiksiz olarak okunmuş, anlaşılmış ve herhangi bir
            tereddüt olmaksızın tamamen hür irade ve arzu ile kesin olarak kabul
            edilmiştir.
          </Text>
          <Text>
            16. Veliler; öğrencinin eğitim, sağlık, sosyal, hukuksal alanlarına
            ilişkin kişisel verilerinin okul ile paylaşılması ve okul tarafından
            kullanılması ile ilgili olarak, Kişisel Verileri Koruma Kanunu
            gereğince bilgilendirildiği, aydınlatıldığı ve bu bilgileri
            onayladığını kabul etmiş sayılır.
          </Text>
        </View>
        <View style={{ marginVertical: 10 }}>
          <Text style={{ fontWeight: "bold" }}>ÖZEL HUSUSLAR</Text>
        </View>
        <View style={{ paddingHorizontal: 10 }}>
          <View style={{ paddingHorizontal: 5, marginBottom: 5 }}>
            <Text style={{ fontWeight: "bold" }}>A. Kayıt Şartları</Text>
          </View>
          <Text>
            1. Ders ve yardımcı ders kitapları, kılık kıyafet, Öğrenci kayıt
            kabul işlemleri ve nakillerinde öncelikle MEB onaylı TED Okulları
            Anaokulu-İlkokul-Ortaokul-Ortaöğretim Kurumları Yönetmeliği ve/veya
            TED Atakent Koleji Kurum Yönetmeliği hükümlerinin, yönetmelikte
            hüküm bulunmayan hallerde 5580 sayılı yasa ve MEB Özel Öğretim
            Kurumları Yönetmeliği başta olmak üzere Milli Eğitim Bakanlığı
            mevzuatının uygulanacağı hususunda taraflar arasında mutabakata
            varılmıştır.
          </Text>
          <Text>
            2. Sınıf tekrarı yapacak olan öğrenciden, devam edeceği sınıftaki
            diğer öğrencilerin ödedikleri ücret alınır.
          </Text>
          <View style={{ paddingHorizontal: 5, marginVertical: 5 }}>
            <Text style={{ fontWeight: "bold" }}>B. Eğitim Öğretim</Text>
          </View>
          <Text>
            3. Okulda eğitim öğretim 1739 sayılı Milli Eğitim Temel Kanununda
            ifade edilen Türk Milli Eğitiminin genel amaç ve temel ilkelerine
            uygun olarak yürütülür. Okulun amacı; Öğrencileri Atatürk inkılap ve
            ilkelerine ve Anayasada ifadesini bulan Atatürk milliyetçiliğine
            bağlı; Türk Milletinin milli, ahlaki, insani, manevi ve kültürel
            değerlerini benimseyen, koruyan ve geliştiren; ailesini, vatanını,
            milletini seven ve daima yüceltmeye çalışan; insan haklarına ve
            Anayasanın başlangıcındaki temel ilkelere dayanan demokratik, laik
            ve sosyal bir hukuk devleti olan Türkiye Cumhuriyetine karşı görev
            ve sorumluluklarını bilen ve bunları davranış haline getirmiş
            yurttaşlar olarak yetiştirmektir.
          </Text>
          <Text>
            4. Okul idaresinin yazılı izni olmadan öğrencilerin kendi aralarında
            yapacakları gezi ve etkinliklerden Okul sorumlu olmayıp Okul, yasal
            görevini yerine getirmek üzere sorumlular hakkında inceleme ve
            soruşturma başlatma hakkına da sahiptir.
          </Text>
          <Text>
            5. Öğrenci, ders saatleri içerisinde haklı ve geçerli mazeretlerin
            vuku halinde ancak velinin yazılı talep ve isteği ile Okul’dan
            ayrılabilir.
          </Text>
          <Text>
            6. Veli, Öğrencinin Okula, derslere ve sınavlara yönelik düzenlenen
            Takviye Kursu veya üniversite destek programına düzenli bir şekilde
            devamını sağlamak ve Okul’a zamanında gelmesini temin etmekle
            birinci derecede sorumludur.
          </Text>
          <Text>
            7. Okul yönetimi ve öğretmenler tarafından yapılan ve velinin
            katılması gereken her türlü toplantı ve etkinliklere velinin
            katılması zorunludur.
          </Text>
          <Text>
            8. BTEC Uluslararası Program 9 ve 10. Sınıflarda zorunlu olup ücreti
            eğitim ücretine ek olarak ödenir.
          </Text>
          <Text>
            9. Okul, değişik sosyal, kültürel ve sportif etkinliklere katılacak
            öğrencileri seçme hakkına sahip olup, Öğrenci ve velinin bireysel
            istekleri imkânlar ölçüsünde dikkate alınacaktır.
          </Text>
          <View style={{ paddingHorizontal: 5, marginVertical: 5 }}>
            <Text style={{ fontWeight: "bold" }}>C. Öğrenci Davranışları</Text>
          </View>
          <Text>
            10. Öğrenci, Okul içi ve dışında Millî Eğitim Bakanlığı tarafından
            belirlenen tutum ve davranışlara uymak, öğretmenlerinin uyarılarını
            dikkate almak zorundadır.
          </Text>
          <Text>
            11. Öğrenci, öğretmen ve arkadaşları ile personele saygılı davranmak
            zorundadır.
          </Text>
          <Text>
            12. Öğrencinin, derslere zamanında girmesi, dersin işlenişini
            bozmaması, öğretmen ve arkadaşlarını derste rahatsız etmemesi
            gerekmektedir.
          </Text>
          <Text>
            13. Öğrenci, ders içinde ya da dışında hiçbir siyasi veya ideolojik
            konuşma yapamayacağı gibi, Okula delici/kesici alet, radyo teyp,
            gazete, tablet gibi görüntü ve kayıt özelliği olan teknolojik
            araçlar, gayri ahlaki ve siyasi içerikli yayın, kaset, CD, vb.
            şeyleri getiremez. Akıllı saat, akıllı telefon ve MP3 getirmesi
            halinde okul bitiminde almak üzere ilgili müdür yardımcısına teslim
            etmek zorundadır. Okul tarafından belirlenen kılık-kıyafet
            kurallarına ve yönetmelik hükümlerine aynen uymak zorundadır.
          </Text>
          <Text>
            14. Öğrencinin, davranış ve disiplinsizlikleri hakkında MEB Okul
            Öncesi ve İlköğretim Kurumları Yönetmeliği ve MEB Ortaöğretim
            Kurumları Yönetmeliğinin öğrenci davranışları ile ilgili hükümleri
            aynen uygulanacaktır.
          </Text>
          <Text>
            15. Öğrenci, öğretmenleri tarafından istenen her türlü araç-gereç ve
            ders kaynaklarını zamanında getirmek, verilen ödev ve projeleri
            zamanında istenilen şekilde yapmak, derslerine düzenli olarak
            çalışmak, öğretmenleri tarafından verilen görevleri yerine getirmek
            zorundadır.
          </Text>
          <Text>
            16. Eğitim öğretimin sağlıklı sürdürülebilmesi bakımından Okul,
            sınıfları istediği gibi oluşturmaya; gerekirse öğrencilerin sınıfını
            değiştirmeye, sınıf içi oturma düzenini planlamaya yetkili olup,
            Zümre Öğretmenler Kurulu kararları uygulamada önceliğe sahip
            olacaktır.
          </Text>
          <Text>
            17. Okulun, işbu sözleşme hükümlerine uymayan öğrencinin kaydının
            alınmasını veliden talep etme hakkının varlığı ve bu hakka itiraz
            edilmeyeceği hususunda taraflar arasında mutabakata varılmıştır.
          </Text>
          <Text>
            18. Öğrenci velisi öğrencinin sağlık durumu ile ilgili bilgileri
            okul ile paylaşmak durumundadır. Kayıt yenileme veya yeni kayıt
            döneminde veya okul devam ederken gelişen sağlık sorunlarını /daha
            önceden bilinen sağlık sorunlarını, nasıl bir tedavi sürecinde
            olduğunu okula bildirmek zorundadır. Okul yönetimi bu sağlık sorunu
            veya sorunlarının öğrenciyi, okul arkadaşlarını nasıl etkileyeceği
            konusunda ayrıca bu sağlık sorunu veya sorunlarının okulun sağlık
            departmanı tarafından takip edilip edilmeyeceği konusunda da karar
            verir ve bu hususu veliye bildirir.
          </Text>
          <View style={{ paddingHorizontal: 5, marginVertical: 5 }}>
            <Text style={{ fontWeight: "bold" }}>D. Servis ve Yemek</Text>
          </View>
          <Text>
            19. Okulun Servis yönergesi ve MEB Okul Servis Araçları Yönetmeliği
            hükümlerine uygun olarak akdedilen Servis Sözleşmesi doğrultusunda
            Okul Aile Birliği’nin de görüşleri alınarak belirlenen servis
            güzergâhlarına göre Öğrencinin Okula ulaştırılması servis şirketi
            ile sağlanır. Servisten yararlanma zorunluluğu bulunmamakta olup,
            servisten yararlanan ya da yararlanmayan Öğrencinin Okulumuza
            getirilmesi ve götürülmesinden dolayı tüm sorumluluk veliye aittir.
          </Text>
          <Text>
            20. Öğrenci davranış ve disiplin sorumlulukları servis araçları
            içinde de aynen geçerli olup, aksi durumda Öğrencinin servis
            kullanma hakkının sona erdirilmesinden dolayı tüm sorumluluk veliye
            aittir.
          </Text>
          <Text>
            21. Servis ile ilgili sorunların giderilmesinde öncelikle veliler
            ile taşıyıcı firma birbirlerine karşı sorumlu olup Okulun tüm
            sorumluluğu, Servis Firması ile akdedilen sözleşme hükümleri
            doğrultusunda gerekli tedbirleri almaktan ibarettir.
          </Text>
          <Text>
            22. Servis içerisinde yemek yenmesi yasak olup, gerek Öğrenci,
            gerekse veli servis aracının bekletilmemesi, araçta uygulanan
            güvenlik tedbirlerine aynen uyulması, şoför ve rehberin uyarılarına
            riayet edilmesi konusunda üzerlerine düşen yükümlülükleri eksiksiz
            yerine getirmekle sorumludur. Güvenlik tedbirleri yanında şoför ve
            rehberin uyarılarına riayet edilmemesi halinde doğacak tüm
            sorumluluk veliye ait olup, Okulumuza hiçbir yükümlülük getirilemez.
          </Text>
          <Text>
            23. Servis güzergâhları Öğrenci adresine 100 m’den daha uzak
            mesafede olamaz. Öğrenci servisleri site ve müstakil evlerde bahçe
            içine girmez.
          </Text>
          <Text>
            24. Öğrenciye Okul tarafından her eğitim öğretim yılı başında ilan
            edildiği şekilde yemek hizmeti verilir. Kurum ilan edilen yemek
            hizmeti ücretini yıl içerisinde meydana gelebilecek TÜFE artışı vb.
            nedenlerle güncelleme hakkını saklı tutar.
          </Text>
          <Text>
            25. Yemek saatinde tüm öğrencilerimizin okul yemekhanesinde öğle
            yemeğine katılım göstermeleri okulumuzdaki eğitim öğretim
            faaliyetlerinin ve günlük programın bir parçasıdır. Bu husus göz
            önünde bulundurularak yemek hizmeti düzenlenir. Sadece eğitim
            -öğretim hizmeti bedelini ödeyerek eğitim -öğretim hizmeti almak
            istiyoruz, yemek hizmeti almak istemiyoruz şeklindeki talepler; bu
            madde ve aşağıdaki maddelerdeki gerekçelerle kabul edilemez. Veli
            işbu sözleşmeyi imzalamakla velisi olduğu öğrencinin yemek
            hizmetinden yararlanacağını talep, beyan ve kabul etmektedir.
          </Text>
          <Text>
            26. Yemekler belirlenirken özel beslenme gereksinimi olan
            öğrencilerimiz için diyet veya vejetaryen yemekleri için okul
            doktoru ve okul diyetisyenlerinin görüşü alınarak menü oluşturulur.
          </Text>
          <Text>
            27. Okulda sunulan yemek hizmetleri Millî Eğitim Bakanlığı, Sağlık
            Bakanlığı, Gıda Tarım ve Hayvancılık Bakanlığı ile ilgili
            kurum/kuruluşlarca hazırlanan 10.03.2016 tarih ve 2852893 sayılı
            Okulda Satılacak Gıdalar ve Eğitim Kurumlarındaki Gıda
            İşletmelerinin Hijyen Yönünden Denetlenmesi Konulu Genelge
            doğrultusunda yürütülmektedir.
          </Text>
          <Text>
            28. Yürürlükteki mevzuat gereği öğrenci ve çalışanlara, sağlıklı
            beslenme ortamlarının oluşturulması, yeterli ve dengeli beslenmenin
            teşvik edilmesi ile doğru beslenme alışkanlıkları kazandırılması,
            güvenilir gıdaya erişim sağlanarak olabilecek gıda zehirlenmeleri,
            bulaşıcı hastalıklar ile yetersiz ve dengesiz beslenmeye bağlı
            hastalıkların önlenmesi (obezite, diyabet, kalp damar hastalıkları
            vb.) amaç edinildiğinden okuldan yemek hizmeti almak zorunlu olup,
            dışarıdan yemek getirilmesi uygun değildir.
          </Text>
          <Text>
            29. Okullarda sağlıklı beslenmenin sağlanması amacıyla; İlçe Millî
            Eğitim Müdürlüğü, İlçe Sağlık Müdürlüğü ve Tarım ve Orman İlçe
            Müdürlüğü temsilcilerinden oluşan İlçe Denetim Ekipleri denetim
            yapmakta olduğundan, olası sorunlar için kontrolsüz gıda girişine
            izin vermemektedir.
          </Text>
          <Text>
            30. Yemek listeleri mevsime göre aylık olarak belirlenerek ilan
            edilir. Listelerin hazırlanmasında Öğrencilerin bireysel
            isteklerinden çok sağlıklı ve dengeli beslenme kuralları dikkate
            alınır.
          </Text>
          <Text>
            31. Öğrencinin herhangi bir nedenle devamsızlığı ya da Okulun idari
            nedenlerle tatili halinde belirlenen yemek ücretinden herhangi bir
            indirim yapılmaz.
          </Text>
          <View style={{ paddingHorizontal: 5, marginVertical: 5 }}>
            <Text style={{ fontWeight: "bold" }}>E. Ödeme</Text>
          </View>
          <Text>
            32. İşbu sözleşme gereğince Ödeme Bilgilerinde yer alan yıllık
            öğrenim, etkinlik ücreti, 7.ve 8. Sınıflar için liselere giriş
            sınavına hazırlık için takviye kursu ile 11. ve 12. sınıflar için
            Üniversite Destek Eğitim Programı (ÜDEP) ücreti, yemek, kahvaltı,
            varsa pansiyon (yatakhane) ücretlerinin planlanan tarihlerde
            yatırılacağı veli tarafından kabul ve taahhüt edilmiştir. Toplam
            ücrete KDV dâhildir. KDV oranındaki değişiklikler hiçbir ihbara
            gerek olmaksızın ücrete yansıtılır.
          </Text>
          <Text>
            33. Ödeme Bilgileri kısmında yer alan ücretlerin banka ödeme
            araçları (POS, KMH vb.) ile ödenmesi halinde Veli ile Banka
            /Bankalar arasında doğacak uyuşmazlıkların ya da ödeme sorunlarının
            Okula herhangi bir yükümlülük getirmeyeceği hususunda taraflar
            mutabıktır.
          </Text>
          <Text>
            34. Ödeme Bilgilerinde belirlenen ödeme tutarlarının belirlenen
            sürede yapılmaması halinde geciken ödemelerin aylık %3 oranındaki
            gecikme faiziyle birlikte talep ve tahsil edileceği hususunda da
            taraflar mutabıktır.
          </Text>
          <Text>
            35. Veli, mutabık kalınan Ödeme Bilgileri gereğince doğan borcun
            tamamını veya taksitlerden birini belirtilen tarihte ödemediği
            takdirde okul yönetiminin mahkeme ve icra müdürlüklerine
            başvurmasına sebebiyet verirse tüm mahkeme masraflarını, İcra İnkâr
            Tazminatını, vekâlet ücretini, yasal her türlü mükellefiyeti derhal
            ödemeyi peşinen kabul ve taahhüt eder.
          </Text>
          <Text>
            36. İşbu sözleşmenin akdedilmesiyle birlikte öğrencinin kesin kaydı
            yapılmış olup, Velinin öğrenim ücreti borçlarını ödememesi nedeniyle
            Okulun öğrencinin Öğrencilik durumuna son verme hak ve yetkisinin
            varlığı da kabul edilmiştir. Ancak, bu durumun Öğrencinin eğitimini
            sürdürdüğü süreye ilişkin eğitim borcu ile ferilerinin ödenmeyeceği
            anlamına gelmediği hususunda da taraflar arasında mutabakata
            varılmıştır.
          </Text>
          <Text>
            37. Öğrenim ya da yemek ve servis ücretinin tamamının ödenmemesi
            halinde Öğrencinin Diploma ya da Tasdiknamesinin borç ödeninceye
            kadar Öğrenci veya Veliye verilmeyeceği hususunda da taraflar
            arasında anlaşmaya varılmıştır.
          </Text>
          <View style={{ paddingHorizontal: 5, marginVertical: 5 }}>
            <Text style={{ fontWeight: "bold" }}>F. Yetki</Text>
          </View>
          <Text>
            38. İş bu sözleşme 54 maddeden oluşmaktadır. Veli, bu öğrenci kayıt
            sözleşmesinin tüm hükümlerini eksiksiz olarak okuduğunu, anladığını
            ve herhangi bir tereddüdü olmaksızın tamamen hür irade ve arzusuyla
            kesin olarak kabul eder. Sözleşme 6 sayfadan ibaret olup, iki nüsha
            olarak tanzim edilmiş ve bir nüshası veliye teslim edilmiştir.
          </Text>
        </View>
        <View style={{ paddingHorizontal: 10 }}>
          <View style={{ marginVertical: 20 }}>
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <View style={{ width: "33%" }}>
                <View
                  style={{
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Text style={{ fontWeight: "bold" }}>
                    Öğrenci velisi/vasisi
                  </Text>
                  <Text style={{ fontWeight: "bold" }}>Adı Soyadı</Text>
                  <Text style={{ fontWeight: "bold" }}>İmza</Text>
                </View>
              </View>
              <View style={{ width: "33%" }}>
                <View
                  style={{
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Text style={{ fontWeight: "bold" }}>
                    {convertToDateString(new Date().getTime())}
                  </Text>
                </View>
              </View>
              <View style={{ width: "33%" }}>
                <View
                  style={{
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Text style={{ fontWeight: "bold" }}>
                    Kurumun (Kaşe, Mühür ve Yetkili imza)
                  </Text>
                  <Text style={{ fontWeight: "bold" }}>
                    {retrieveStudent().school.name}
                  </Text>
                </View>
              </View>
            </View>
          </View>
        </View>
      </View>
    );
  };

  const RenderParentExpressConsentText = () => {
    return (
      <View style={{ marginVertical: 10 }}>
        <View style={{ marginVertical: 10 }}>
          <View
            style={{
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Text style={{ fontWeight: "bold" }}>ÖĞRENCİ VELİSİNE AİT</Text>
            <Text style={{ fontWeight: "bold" }}>AÇIK RIZA BEYANI</Text>
          </View>
        </View>
        <View style={{ paddingHorizontal: 10 }}>
          <Text>
            6698 sayılı Kişisel Verilerin Korunması Kanunu'na uygun olarak Özel
            Atakent Kolej Eğitim Yayın Basım Hizmetleri Ticaret ve Sanayi Anonim
            Şirketi (TED ATAKENT KOLEJİ)’nin, faaliyetinin devamı ve amacıyla
            bağlantılı olarak her türlü etkinlik, yeni kayıt veya kayıt yenileme
            işlemleri, spor, tiyatro, müzik vb. sanatsal faaliyetler, başarı
            veya ihtiyaç bursu başvuru ve işlemleri, sms veya e mail yoluyla
            tarafıma yapılan bilgilendirmeler ya da tarafımdan talep edilen
            bilgilendirmeler, K12 mesajları, Sebit Vcloud girişleri, konferans,
            bayram, tören, karnaval, gezi, kermes, bağış ve benzeri faaliyetler,
            gizlilik yükümlülüğü bulunan Revir bölümünde görevli doktor/hemşire
            müdahale ve kayıtları, PDR görüşme ve kayıtlarına yönelik olarak,
            elektronik, görsel, yazılı veya diğer yollarla, okulun internet
            sitesi ve bunlarla sınırlı olmamak üzere her türlü kanallarla, ibraz
            edilen kişisel verilerimizin koruma yöntemlerine riayet edilerek,
            faaliyetin amacı ve yasalardaki sürelerle sınırlı olmak üzere
            işlenmesine, muhafaza edilmesine, güncellenmesine, okul içinde
            ilgili birim ve genel merkez ile ve yasal zorunluluk gerektirmesi
            halinde resmi makamlarla paylaşılmasına, faaliyetin amacı
            gerçekleştiğinde veya yasal süreleri dolduğunda silinmesine, yok
            edilmesine veya anonimleştirilmesine velisi bulunduğum{" "}
            {retrieveStudent().name} {retrieveStudent().lastname} ve şahsım
            adına, detaylı bilgi sahibi olarak, ekli aydınlatma metni ve
            haklarımı açıklayan bilgilendirmeyi okuyup, anlayarak, aydınlatılmış
            açık rızam ile;
          </Text>
        </View>
        <View style={{ paddingHorizontal: 10 }}>
          <View style={{ marginVertical: 10 }}>
            <View
              style={{
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <View style={{ width: "50%" }}>
                <View
                  style={{
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Text style={{ fontWeight: "bold" }}>
                    {convertToDateString(new Date().getTime())}
                  </Text>
                  <Text style={{ fontWeight: "bold" }}>Onay veriyorum</Text>
                </View>
                <View style={{ marginVertical: 10 }}>
                  <View style={{ padding: 5 }}>
                    <View
                      style={{
                        flexDirection: "row",
                        justifyContent: "center",
                      }}
                    >
                      <View style={{ width: "50%" }}>
                        {findParentByParentType("ANNE") ? (
                          <View style={{ marginVertical: 5 }}>
                            <Text style={{ fontWeight: "bold" }}>Anne adı</Text>
                            <Text>{`${
                              findParentByParentType("ANNE").user.name
                            } ${
                              findParentByParentType("ANNE").user.lastname
                            }`}</Text>
                          </View>
                        ) : null}
                        {findParentByParentType("BABA") ? (
                          <View style={{ marginVertical: 5 }}>
                            <Text style={{ fontWeight: "bold" }}>Baba adı</Text>
                            <Text>{`${
                              findParentByParentType("BABA").user.name
                            } ${
                              findParentByParentType("BABA").user.lastname
                            }`}</Text>
                          </View>
                        ) : null}
                      </View>
                      <View style={{ width: "50%" }}>
                        {findParentByParentType("ANNE") ? (
                          <Text
                            style={{
                              marginBottom: 20,
                              marginTop: 5,
                              fontWeight: "bold",
                            }}
                          >
                            İmza
                          </Text>
                        ) : null}
                        {findParentByParentType("BABA") ? (
                          <Text
                            style={{
                              marginBottom: 20,
                              marginTop: 5,
                              fontWeight: "bold",
                            }}
                          >
                            İmza
                          </Text>
                        ) : null}
                      </View>
                    </View>
                  </View>
                </View>
              </View>
              <View style={{ width: "50%" }}>
                <View
                  style={{
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Text style={{ fontWeight: "bold" }}>
                    {convertToDateString(new Date().getTime())}
                  </Text>
                  <Text style={{ fontWeight: "bold" }}>Onay vermiyorum</Text>
                </View>
                <View style={{ marginVertical: 10 }}>
                  <View style={{ padding: 5 }}>
                    <View
                      style={{
                        flexDirection: "row",
                        justifyContent: "center",
                      }}
                    >
                      <View style={{ width: "50%" }}>
                        {findParentByParentType("ANNE") ? (
                          <View style={{ marginVertical: 5 }}>
                            <Text style={{ fontWeight: "bold" }}>Anne adı</Text>
                            <Text>{`${
                              findParentByParentType("ANNE").user.name
                            } ${
                              findParentByParentType("ANNE").user.lastname
                            }`}</Text>
                          </View>
                        ) : null}
                        {findParentByParentType("BABA") ? (
                          <View style={{ marginVertical: 5 }}>
                            <Text style={{ fontWeight: "bold" }}>Baba adı</Text>
                            <Text>{`${
                              findParentByParentType("BABA").user.name
                            } ${
                              findParentByParentType("BABA").user.lastname
                            }`}</Text>
                          </View>
                        ) : null}
                      </View>
                      <View style={{ width: "50%" }}>
                        {findParentByParentType("ANNE") ? (
                          <Text
                            style={{
                              marginBottom: 20,
                              marginTop: 5,
                              fontWeight: "bold",
                            }}
                          >
                            İmza
                          </Text>
                        ) : null}
                        {findParentByParentType("BABA") ? (
                          <Text
                            style={{
                              marginBottom: 20,
                              marginTop: 5,
                              fontWeight: "bold",
                            }}
                          >
                            İmza
                          </Text>
                        ) : null}
                      </View>
                    </View>
                  </View>
                </View>
              </View>
            </View>
          </View>
        </View>
        <View style={{ paddingHorizontal: 10 }}>
          <Text>
            Okulda veli gözükmeyen diğer ebeveyn olarak {retrieveStudent().name}{" "}
            {retrieveStudent().lastname} ait yazılı, sözlü, sms vb. yollarla
            not, genel durum vb. bilgi talep etmem halinde, kişisel verilerimin
            işlenmesine, muhafaza edilmesine, güncellenmesine, tarafımla, Okul
            içi ilgili birimler, Genel Merkez ve yasal zorunluluk içermesi
            halinde resmi kurumlarla paylaşılmasına aydınlatılmış açık rızam
            ile;
          </Text>
        </View>
        <View style={{ paddingHorizontal: 10 }}>
          <View style={{ marginVertical: 10 }}>
            <View
              style={{
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <View style={{ width: "50%" }}>
                <View
                  style={{
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Text style={{ fontWeight: "bold" }}>
                    {convertToDateString(new Date().getTime())}
                  </Text>
                  <Text style={{ fontWeight: "bold" }}>Onay veriyorum</Text>
                </View>
                <View style={{ marginVertical: 10 }}>
                  <View style={{ padding: 5 }}>
                    <View
                      style={{
                        flexDirection: "row",
                        justifyContent: "center",
                      }}
                    >
                      <View style={{ width: "50%" }}>
                        {findParentByParentType("ANNE") ? (
                          <View style={{ marginVertical: 5 }}>
                            <Text style={{ fontWeight: "bold" }}>Anne adı</Text>
                            <Text>{`${
                              findParentByParentType("ANNE")?.user.name
                            } ${
                              findParentByParentType("ANNE")?.user.lastname
                            }`}</Text>
                          </View>
                        ) : null}

                        {findParentByParentType("BABA") ? (
                          <View style={{ marginVertical: 5 }}>
                            <Text style={{ fontWeight: "bold" }}>Baba adı</Text>
                            <Text>{`${
                              findParentByParentType("BABA")?.user.name
                            } ${
                              findParentByParentType("BABA")?.user.lastname
                            }`}</Text>
                          </View>
                        ) : null}
                      </View>
                      <View style={{ width: "50%" }}>
                        {findParentByParentType("ANNE") ? (
                          <Text
                            style={{
                              marginBottom: 20,
                              marginTop: 5,
                              fontWeight: "bold",
                            }}
                          >
                            İmza
                          </Text>
                        ) : null}
                        {findParentByParentType("BABA") ? (
                          <Text
                            style={{
                              marginBottom: 20,
                              marginTop: 5,
                              fontWeight: "bold",
                            }}
                          >
                            İmza
                          </Text>
                        ) : null}
                      </View>
                    </View>
                  </View>
                </View>
              </View>
              <View style={{ width: "50%" }}>
                <View
                  style={{
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Text style={{ fontWeight: "bold" }}>
                    {convertToDateString(new Date().getTime())}
                  </Text>
                  <Text style={{ fontWeight: "bold" }}>Onay vermiyorum</Text>
                </View>
                <View style={{ marginVertical: 10 }}>
                  <View style={{ padding: 5 }}>
                    <View
                      style={{
                        flexDirection: "row",
                        justifyContent: "center",
                      }}
                    >
                      <View style={{ width: "50%" }}>
                        {findParentByParentType("ANNE") ? (
                          <View style={{ marginVertical: 5 }}>
                            <Text style={{ fontWeight: "bold" }}>Anne adı</Text>
                            <Text>{`${
                              findParentByParentType("ANNE")?.user.name
                            } ${
                              findParentByParentType("ANNE")?.user.lastname
                            }`}</Text>
                          </View>
                        ) : null}

                        {findParentByParentType("BABA") ? (
                          <View style={{ marginVertical: 5 }}>
                            <Text style={{ fontWeight: "bold" }}>Baba adı</Text>
                            <Text>{`${
                              findParentByParentType("BABA")?.user.name
                            } ${
                              findParentByParentType("BABA")?.user.lastname
                            }`}</Text>
                          </View>
                        ) : null}
                      </View>
                      <View style={{ width: "50%" }}>
                        {findParentByParentType("ANNE") ? (
                          <Text
                            style={{
                              marginBottom: 20,
                              marginTop: 5,
                              fontWeight: "bold",
                            }}
                          >
                            İmza
                          </Text>
                        ) : null}
                        {findParentByParentType("BABA") ? (
                          <Text
                            style={{
                              marginBottom: 20,
                              marginTop: 5,
                              fontWeight: "bold",
                            }}
                          >
                            İmza
                          </Text>
                        ) : null}
                      </View>
                    </View>
                  </View>
                </View>
              </View>
            </View>
          </View>
        </View>
      </View>
    );
  };

  const RenderSchoolExpressConsentText = () => {
    return (
      <View style={{ marginVertical: 10 }}>
        <View style={{ marginVertical: 10 }}>
          <View
            style={{
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Text style={{ fontWeight: "bold" }}>TÜRK EĞİTİM DERNEĞİNE</Text>
            <Text style={{ fontWeight: "bold" }}>
              Kişisel Verilerin Korunması Kanunu Kapsamında
            </Text>
            <Text style={{ fontWeight: "bold" }}>AÇIK RIZA BEYANI</Text>
          </View>
        </View>
        <View style={{ paddingHorizontal: 10 }}>
          <Text>
            Okulunuza ait Öğrenci Kayıt Formunda ve Millî Eğitim Bakanlığı Özel
            Öğretim Kurumları Genel Müdürlüğü Öğrenci Kayıt Sözleşmesinde ve K12
            sistemi üzerinden beyan ettiğim/edeceğim bilgilerin doğru olduğunu
            ve bu verilerin ted.org.tr/kvkk adresinde bulunan ve okumuş olduğum
            Türk Eğitim Derneği Aydınlatma Metninde yer alan açıklamalara uygun
            olarak Türk Eğitim Derneği tarafından işlenmesine Kişisel Verilerin
            Korunması Kanunu kapsamında izin verdiğimi beyan ederim.
          </Text>
        </View>
        <View style={{ paddingHorizontal: 10 }}>
          <View style={{ marginVertical: 10 }}>
            <View style={{ flexDirection: "row", justifyContent: "flex-end" }}>
              <Text style={{ fontWeight: "bold" }}>
                {convertToDateString(new Date().getTime())}
              </Text>
            </View>
            <View
              style={{
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <View style={{ width: "25%" }}>
                <View style={{ marginVertical: 5 }}>
                  <Text style={{ fontWeight: "bold" }}>
                    Öğrenci TC Kimlik Numarası
                  </Text>
                </View>
                <View style={{ marginVertical: 5 }}>
                  <Text style={{ fontWeight: "bold" }}>
                    Öğrenci Adı ve Soyadı
                  </Text>
                </View>
              </View>
              <View style={{ width: "75%" }}>
                <View style={{ marginVertical: 5 }}>
                  <Text>{retrieveStudent().identityNumber}</Text>
                </View>
                <View style={{ marginVertical: 5 }}>
                  <Text>
                    {`${retrieveStudent().name} ${retrieveStudent().lastname}`}
                  </Text>
                </View>
              </View>
            </View>
          </View>
          <View style={{ marginVertical: 10 }}>
            <View
              style={{
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <View style={{ width: "33%" }}>
                <View style={{ marginVertical: 5 }}>
                  <Text style={{ fontWeight: "bold" }}>Anne Adı</Text>
                </View>
                <View style={{ marginVertical: 5 }}>
                  <Text style={{ fontWeight: "bold" }}>Baba Adı</Text>
                </View>
              </View>
              <View style={{ width: "33%" }}>
                {findParentByParentType("ANNE") ? (
                  <View style={{ marginVertical: 5 }}>
                    <Text>{`${findParentByParentType("ANNE").user.name} ${
                      findParentByParentType("ANNE").user.lastname
                    }`}</Text>
                  </View>
                ) : null}

                {findParentByParentType("BABA") ? (
                  <View style={{ marginVertical: 5 }}>
                    <Text>
                      <Text>{`${findParentByParentType("BABA")?.user.name} ${
                        findParentByParentType("BABA")?.user.lastname
                      }`}</Text>
                    </Text>
                  </View>
                ) : null}
              </View>
              <View style={{ width: "33%" }}>
                {findParentByParentType("ANNE") ? (
                  <View style={{ marginVertical: 5 }}>
                    <Text style={{ fontWeight: "bold" }}>İmza</Text>
                  </View>
                ) : null}
                {findParentByParentType("BABA") ? (
                  <View style={{ marginVertical: 5 }}>
                    <Text style={{ fontWeight: "bold" }}>İmza</Text>
                  </View>
                ) : null}
              </View>
            </View>
          </View>
        </View>
      </View>
    );
  };

  if (!id) return;
  if (!studentContract) return;

  return (
    <div
      className={`${userInformation.role === "ADMIN" ? "content" : ""} h-100`}
    >
      {userInformation.role === "ADMIN" ? (
        <div className="" style={{ width: 260 }} />
      ) : null}
      <PDFViewer className="h-100 w-100">
        <Document>
          <Page
            size="A4"
            style={{
              backgroundColor: "#E4E4E4",
              fontFamily: "Roboto",
              paddingVertical: 30,
              paddingHorizontal: 30,
              fontSize: 8,
            }}
          >
            {RenderContractHeader()}
            {RenderStudentInformationTableWithTitle()}
            {RenderParentInformationTableWithTitle()}
            {RenderFeesTableWithTitle()}
            {RenderCreditCardInstallment()}
          </Page>
          <Page
            size="A4"
            style={{
              backgroundColor: "#E4E4E4",
              fontFamily: "Roboto",
              paddingVertical: 30,
              paddingHorizontal: 30,
              fontSize: 8,
            }}
          >
            {RenderContractText()}
          </Page>
          <Page
            size="A4"
            style={{
              backgroundColor: "#E4E4E4",
              fontFamily: "Roboto",
              paddingVertical: 30,
              paddingHorizontal: 30,
              fontSize: 8,
            }}
          >
            {RenderParentExpressConsentText()}
          </Page>
          <Page
            size="A4"
            style={{
              backgroundColor: "#E4E4E4",
              fontFamily: "Roboto",
              paddingVertical: 30,
              paddingHorizontal: 30,
              fontSize: 8,
            }}
          >
            {RenderSchoolExpressConsentText()}
          </Page>
        </Document>
      </PDFViewer>
    </div>
  );
};

export default ContractPDFScreen;
