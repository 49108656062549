import React from "react";
import Letter from "./pages/Letter";

export default function ContractMainPage() {
  return (
    <React.Fragment>
      <Letter />
    </React.Fragment>
  );
}
