import FormInput from "../components/FormInput";
import { useInput } from "../../service/useInput";
import { useContext, useEffect, useState } from "react";
import Countdown from "../components/Countdown";
import Logo from "../../assets/logo.png";
import { AppContext } from "../../context/AppContextProvider";
import {
  authenticateUserByParams,
  checkCodeByCodeAndUserId,
} from "../../requests/UserRequests";
import { NotificationManager } from "react-notifications";
import TokenService from "../../service/token.service";
import { UserContext } from "../../context/UserContextProvider";

export default function Login() {
  const userContext = useContext(UserContext);
  const { setUserInformation } = userContext;
  const appContext = useContext(AppContext);
  const { setAppState } = appContext;
  const [inputs, setInputs] = useInput({ identityNumber: "", code: "" });
  const [checkCode, setCheckCode] = useState(null);
  const [step, setStep] = useState(0);
  const [smsData, setSmsData] = useState({
    phone: "",
    login_code: "",
    loginCodeLastTime: 0,
    userData: {},
  });
  const [countdown, setCountdown] = useState(0);

  useEffect(() => {
    const localStorageLoginState = localStorage.getItem("loginState");
    if (localStorageLoginState === "SMS_REQUIRED") {
      setStep(0);
      localStorage.removeItem("loginState");
    }
  }, []);

  useEffect(() => {
    const login_code = localStorage.getItem("login_code");
    const loginCodeLastTime = localStorage.getItem("loginCodeLastTime");
    if (login_code && loginCodeLastTime && loginCodeLastTime > Date.now()) {
      const time = (loginCodeLastTime - Date.now()) / 1000;
      setCountdown(time);
      setStep(1);

      const _smsData = {
        phone: localStorage.getItem("phone"),
        login_code: localStorage.getItem("login_code"),
        loginCodeLastTime: localStorage.getItem("loginCodeLastTime"),
        userData: JSON.parse(localStorage.getItem("parent_data")),
      };
      setSmsData(_smsData);
    } else {
      //localStorage.removeItem("login_code")
      localStorage.removeItem("loginCodeLastTime");
    }
  }, []);

  useEffect(() => {
    if (checkCode === true) {
      localStorage.removeItem("login_code");
      localStorage.removeItem("loginCodeLastTime");
      localStorage.removeItem("loginState");
      setAppState("COMPLETED");
    } else if (checkCode === false) {
      alert("Hatalı kod");
    }
  }, [checkCode]);

  const eventHandler = (e) => {
    e.preventDefault();
    if (step === 0) {
      handleLogin();
    } else {
      eventCheckSMS();
    }
  };

  const handleLogin = async () => {
    const { data, err } = await authenticateUserByParams({
      identityNumber: inputs.identityNumber,
      password: inputs.password,
    });
    if (err) {
      NotificationManager.error("Geçeriz TC kimlik numarası girdiniz");
      return;
    }
    TokenService.setUser(data);
    setUserInformation(TokenService.getUser(data));
    localStorage.setItem("loginState", "SMS_REQUIRED");
    let parent = localStorage.getItem("parent_data");
    let staff = localStorage.getItem("staff_data");

    const _smsData = {
      phone: localStorage.getItem("phone"),
      login_code: localStorage.getItem("login_code"),
      loginCodeLastTime: localStorage.getItem("loginCodeLastTime"),
      userData: JSON.parse(parent ? parent : staff),
    };
    const time = (_smsData.loginCodeLastTime - Date.now()) / 1000;
    setCountdown(time);
    setSmsData(_smsData);
    setStep(1);
  };

  const eventCheckSMS = async () => {
    const id = localStorage.getItem("userId");
    const user = JSON.parse(localStorage.getItem("user"));
    const { data, err } = await checkCodeByCodeAndUserId(
      inputs.code,
      id,
      user?.access_token
    );
    if (err) {
      NotificationManager.error("Kod kontrol edilirken bir hata oluştu");
      return;
    }
    setCheckCode(data);
  };

  const stopCountdown = () => {
    setCountdown(0);
    setStep(0);
    setSmsData({
      phone: "",
      login_code: "",
      loginCodeLastTime: 0,
      userData: {},
    });
    localStorage.removeItem("loginCodeLastTime");
  };

  return (
    <div className="container mt-5">
      <div className="row">
        <div className="col-12 col-xl-12">
          <div className="card card-body border-0 shadow mb-4">
            <div className="w-100 d-flex justify-content-center">
              <img
                src={Logo}
                alt="atekent_logo"
                style={{ width: "150px" }}
                className="p-3"
              />
            </div>
            <div className="w-100 d-flex justify-content-center">
              <h2 className="h5 mb-4">
                TED ATAKENT KOLEJİ KAYIT YENİLEME UYGULAMASI
              </h2>
            </div>
            <form>
              {step === 0 ? (
                <>
                  <div className="alert alert-info">
                    Sayın velimiz, <br />
                    TED Atakent Koleji online kayıt yenileme sistemine sadece
                    okulda kayıtlı velilerimiz T.C. kimlik numaraları ile
                    girilebilir.
                  </div>
                  <FormInput
                    name="identityNumber"
                    text="Kimlik Numaranız"
                    placeholder="Kimlik numaranızı giriniz."
                    type="text"
                    col="12"
                    value={inputs.identityNumber}
                    onChange={setInputs}
                  />
                </>
              ) : (
                <>
                  <div className="alert alert-info">
                    Sayın, {smsData?.userData?.name}{" "}
                    {smsData?.userData?.lastname} {smsData?.phone} nolu
                    telefonunuza {smsData?.login_code} kod gönderilmiştir.
                    Lütfen bu kodu aşağıya giriniz. Bu numarayı kullanmıyorsanız
                    lütfen öğrenci işleri ile iletişime geçiniz. Kod girmek için
                    kalan süre:{" "}
                    {countdown > 0 ? (
                      <Countdown
                        deadline={countdown}
                        stopCountdown={stopCountdown}
                      />
                    ) : null}
                  </div>

                  <FormInput
                    name="code"
                    text="SMS Kodu"
                    placeholder="Telefonunuza gelen kodu giriniz."
                    type="text"
                    col="12"
                    required
                    value={inputs.code}
                    onChange={setInputs}
                  />
                </>
              )}
              <div className="row">
                <button
                  className="btn btn-success text-light  mx-2"
                  onClick={(e) => eventHandler(e)}
                >
                  Giriş
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
