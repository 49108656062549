import React from "react";
import ReactDOM from "react-dom";
import AppContextProvider from "./context/AppContextProvider";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/site.css";
import reportWebVitals from "./reportWebVitals";
import "react-notifications/lib/notifications.css";
import "react-confirm-alert/src/react-confirm-alert.css";
import "react-credit-cards-2/dist/es/styles-compiled.css";
import ReactModal from "react-modal";
import UserContextProvider from "./context/UserContextProvider";
import Main from "./Main";
import { NotificationContainer } from "react-notifications";

const root = ReactDOM.createRoot(document.getElementById("root"));
ReactModal.setAppElement("#root");

Number.prototype.formatMoney = function (fractionDigits, decimal, separator) {
  fractionDigits = isNaN((fractionDigits = Math.abs(fractionDigits)))
    ? 2
    : fractionDigits;
  decimal = typeof decimal === "undefined" ? "." : decimal;
  separator = typeof separator === "undefined" ? "," : separator;
  var number = this;
  var neg = number < 0 ? "-" : "";
  var wholePart =
    parseInt((number = Math.abs(+number || 0).toFixed(fractionDigits))) + "";
  // eslint-disable-next-line no-use-before-define
  var separtorIndex =
    (separtorIndex = wholePart.length) > 3 ? separtorIndex % 3 : 0;
  return (
    neg +
    (separtorIndex ? wholePart.substr(0, separtorIndex) + separator : "") +
    wholePart
      .substr(separtorIndex)
      .replace(/(\d{3})(?=\d)/g, "$1" + separator) +
    (fractionDigits
      ? decimal +
        Math.abs(number - wholePart)
          .toFixed(fractionDigits)
          .slice(2)
      : "")
  );
};

root.render(
  <AppContextProvider>
    <UserContextProvider>
      <NotificationContainer />
      <Main />
    </UserContextProvider>
  </AppContextProvider>
);

reportWebVitals();
