import FeesTable from "./FeesTable";

const FeesTableWithTitle = (props) => {
  const { title, contractFees, contractDiscounts } = props;
  return (
    <div className="mb-3 mt-3">
      <div className="d-flex justify-content-center align-item-center mb-3">
        <h5>{title}</h5>
      </div>
      <FeesTable
        contractFees={contractFees}
        contractDiscounts={contractDiscounts}
      />
    </div>
  );
};

export default FeesTableWithTitle;
