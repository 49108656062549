import axios from "axios";
import config from "../config/config.json";

export const retrieveAllAdminUsersBySchoolId = async (
  schoolId,
  accessToken
) => {
  const configuration = {
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
      Authorization: `Bearer ${accessToken}`,
    },
  };

  try {
    const response = await axios.get(
      `${config.api.invokeUrl}/staff/admin/${schoolId}`,
      configuration
    );
    return { data: response.data };
  } catch (err) {
    console.log(err);
    return { err };
  }
};

export const createAdminUserInformation = async (body, accessToken) => {
  const configuration = {
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
      Authorization: `Bearer ${accessToken}`,
    },
  };

  try {
    const response = await axios.post(
      `${config.api.invokeUrl}/staff/`,
      body,
      configuration
    );
    return { data: response.data };
  } catch (err) {
    console.log(err);
    return { err };
  }
};

export const patchAdminUserInformation = async (body, accessToken) => {
  const configuration = {
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
      Authorization: `Bearer ${accessToken}`,
    },
  };

  try {
    const response = await axios.patch(
      `${config.api.invokeUrl}/staff/`,
      body,
      configuration
    );
    return { data: response.data };
  } catch (err) {
    console.log(err);
    return { err };
  }
};

export const deleteAdminUserInformation = async (userId, accessToken) => {
  const configuration = {
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
      Authorization: `Bearer ${accessToken}`,
    },
  };

  try {
    const response = await axios.delete(
      `${config.api.invokeUrl}/staff/${userId}`,
      configuration
    );
    return { data: response.data };
  } catch (err) {
    console.log(err);
    return { err };
  }
};

export const authenticateUserByParams = async (body) => {
  try {
    const axiosInstance = axios.create();
    const response = await axiosInstance.post(
      `${config.api.invokeUrl}/auth/authenticate`,
      body
    );
    return { data: response.data };
  } catch (err) {
    console.log(err);
    return { err };
  }
};

export const checkCodeByCodeAndUserId = async (code, userId, accessToken) => {
  const configuration = {
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
      Authorization: `Bearer ${accessToken}`,
    },
  };

  try {
    const response = await axios.post(
      `${config.api.invokeUrl}/staff/code/${userId}`,
      code,
      configuration
    );
    return { data: response.data };
  } catch (err) {
    console.log(err);
    return { err };
  }
};
