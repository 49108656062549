const ParentInformationTable = (props) => {
  const { parentMother, parentFather } = props;

  return (
    <div className="table-responsive col-12 w-100">
      <table
        className="table table-centered mb-0 rounded w-100 table-wrap"
        style={{ whiteSpace: "wrap" }}
      >
        <tbody>
          <tr>
            <th></th>
            {parentMother ? <th>ANNE</th> : null}
            {parentFather ? <th>BABA</th> : null}
          </tr>

          <tr>
            <th>T.C. Kimlik Numarası</th>
            {parentMother ? (
              <td style={{ whiteSpace: "wrap" }}>
                : {parentMother.user.identityNumber}
              </td>
            ) : null}
            {parentFather ? (
              <td style={{ whiteSpace: "wrap" }}>
                : {parentFather.user.identityNumber}
              </td>
            ) : null}
          </tr>
          <tr>
            <th>Adı ve Soyadı</th>
            {parentMother ? (
              <td style={{ whiteSpace: "wrap" }}>
                : {parentMother.user.name} {parentMother.user.lastname}
              </td>
            ) : null}

            {parentFather ? (
              <td style={{ whiteSpace: "wrap" }}>
                : {parentFather.user.name} {parentFather.user.lastname}
              </td>
            ) : null}
          </tr>
          <tr>
            <th>Mesleği</th>
            {parentMother ? (
              <td style={{ whiteSpace: "wrap" }}>: {parentMother.job}</td>
            ) : null}
            {parentFather ? (
              <td style={{ whiteSpace: "wrap" }}>: {parentFather.job}</td>
            ) : null}
          </tr>
          <tr>
            <th>Cep Telefonu</th>
            {parentMother ? (
              <td style={{ whiteSpace: "wrap" }}>
                : {parentMother.user.phone}
              </td>
            ) : null}
            {parentFather ? (
              <td style={{ whiteSpace: "wrap" }}>
                : {parentFather.user.phone}
              </td>
            ) : null}
          </tr>
          <tr>
            <th>Ev Telefonu</th>
            {parentMother ? (
              <td style={{ whiteSpace: "wrap" }}>: {parentMother.homePhone}</td>
            ) : null}
            {parentFather ? (
              <td style={{ whiteSpace: "wrap" }}>: {parentFather.homePhone}</td>
            ) : null}
          </tr>
          <tr>
            <th>İş Telefonu</th>
            {parentMother ? (
              <td style={{ whiteSpace: "wrap" }}>
                : {parentMother.businessPhone}
              </td>
            ) : null}
            {parentFather ? (
              <td style={{ whiteSpace: "wrap" }}>
                : {parentFather.businessPhone}
              </td>
            ) : null}
          </tr>
          <tr>
            <th>Ev Adresi</th>
            {parentMother ? (
              <td style={{ whiteSpace: "wrap" }}>
                : {parentMother.homeAddress}
              </td>
            ) : null}
            {parentFather ? (
              <td style={{ whiteSpace: "wrap" }}>
                : {parentFather.homeAddress}
              </td>
            ) : null}
          </tr>
          <tr>
            <th>İş Adresi</th>
            {parentMother ? (
              <td style={{ whiteSpace: "wrap" }}>
                : {parentMother.businessAddress}
              </td>
            ) : null}
            {parentFather ? (
              <td style={{ whiteSpace: "wrap" }}>
                : {parentFather.businessAddress}
              </td>
            ) : null}
          </tr>
          <tr>
            <th>E-Posta</th>
            {parentMother ? (
              <td style={{ whiteSpace: "wrap" }}>
                : {parentMother.user.email}
              </td>
            ) : null}
            {parentFather ? (
              <td style={{ whiteSpace: "wrap" }}>
                : {parentFather.user.email}
              </td>
            ) : null}
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default ParentInformationTable;
