import { useEffect, useState } from "react";
import * as XLSX from "xlsx";
import FormSelect from "../app/components/FormSelect";
import FormInput from "../app/components/FormInput";
import { useNavigate } from "react-router";
import BaseModal from "./components/BaseModal";
import { dataTransferByParams } from "../requests/StudentRequests";
import { NotificationManager } from "react-notifications";
import { retrieveAllFeesAndDiscountsInformationBySchoolId } from "../requests/ContractRequests";

const _listOfObject = [
  {
    name: "",
    objectClass: "",
    subObjectList: [],
  },
  {
    name: "Öğrenci",
    objectClass: "Student",
    subObjectList: [
      {
        name: "",
        objectClass: "",
        excelName: "",
      },
      {
        name: "İsim",
        objectClass: "name",
        excelName: "Öğrenci Ad Soyad",
      },
      {
        name: "Soyisim",
        objectClass: "lastname",
        excelName: "Soyisim",
      },
      {
        name: "Kimlik No",
        objectClass: "identityNumber",
        excelName: "TC Kimlik No",
      },
      {
        name: "Okul Numarası",
        objectClass: "schoolNumber",
        excelName: "Okul Numarası",
      },
      {
        name: "Mevcut Sınıfı",
        objectClass: "oldGrade",
        excelName: "Sınıf",
      },
      {
        name: "Şubesi",
        objectClass: "branchName",
        excelName: "Şube",
      },
      {
        name: "Doğum Tarihi",
        objectClass: "birthday",
        excelName: "Doğum Tarihi",
      },
      {
        name: "Şehir",
        objectClass: "hometown",
        excelName: "Öğrenci Adres İl",
      },
      {
        name: "İlçe",
        objectClass: "town",
        excelName: "Öğrenci Adres İlçe",
      },
      {
        name: "Adres",
        objectClass: "address",
        excelName: "Öğrenci Adres",
      },
      {
        name: "Telefon",
        objectClass: "phone",
        excelName: "Öğrenci Telefon",
      },
    ],
  },
  {
    name: "Veli",
    objectClass: "Parent",
    subObjectList: [
      {
        name: "",
        objectClass: "",
        excelName: "",
      },
      {
        name: "Veli Kimlik No",
        objectClass: "parent_identityNumber",
        excelName: "Veli TC Kimlik",
      },
      {
        name: "Veli Telefon Numarası",
        objectClass: "parent_phone",
        excelName: "Veli Telefon",
      },
      {
        name: "Veli Adı",
        objectClass: "parent_name",
        excelName: "Veli Ad Soyad",
      },
      {
        name: "Veli Soyadı",
        objectClass: "parent_lastname",
        excelName: "Veli Soyadı",
      },
      {
        name: "Veli Mail",
        objectClass: "parent_email",
        excelName: "Veli E-posta",
      },
      {
        name: "Veli Meslek",
        objectClass: "parent_job",
        excelName: "Veli Mesleği",
      },
      {
        name: "Veli Ev Adres",
        objectClass: "parent_homeAddress",
        excelName: "Veli Adres",
      },
      {
        name: "Veli İş Adresi",
        objectClass: "parent_businessAddress",
        excelName: "Veli İş Adres",
      },
      {
        name: "Veli İş Telefonu",
        objectClass: "parent_businessPhone",
        excelName: "Veli İş Telefonu",
      },
      {
        name: "Veli Ev Telefonu",
        objectClass: "parent_homePhone",
        excelName: "Veli Ev Telefonu",
      },
      {
        name: "Veli İlçe",
        objectClass: "parent_town",
        excelName: "Veli Adres İlçe",
      },
      {
        name: "Veli Şehir",
        objectClass: "parent_city",
        excelName: "Veli Adres İl",
      },
    ],
  },
  {
    name: "Ücret",
    objectClass: "Fees",
    subObjectList: [],
  },
  {
    name: "İndirimler",
    objectClass: "Discount",
    subObjectList: [],
  },
];

export default function ReadExcelScreen() {
  const navigate = useNavigate();
  const [excelFile, setExcelFile] = useState(null);
  const [listOfObject, setListOfObject] = useState(_listOfObject);
  const [typeError, setTypeError] = useState(null);
  const [excelData, setExcelData] = useState(null);
  const [dataList, setDataList] = useState(null);
  const [studentList, setStudentList] = useState([]);
  const [columnNameList, setColumnNameList] = useState([]);
  const [update, setUpdated] = useState(true);
  const [showPleaseWaitModal, setShowPleaseWaitModal] = useState(false);
  const schoolId = localStorage.getItem("schoolId");
  const user = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    const retrieveAndSetAllFeesAndDiscountsInformation = async () => {
      const { data, err } =
        await retrieveAllFeesAndDiscountsInformationBySchoolId(
          schoolId,
          user?.access_token
        );
      if (err) {
        NotificationManager.error(
          "Ücret kalemleri ve indirimleri sorgulanırken bir hata oluştu"
        );
        return;
      }
      setDataList(data);
    };
    retrieveAndSetAllFeesAndDiscountsInformation();
  }, []);

  useEffect(() => {
    if (!dataList) return;
    const filteredDiscountList = [];
    dataList.discounts.forEach((dis) =>
      !filteredDiscountList.find((f) => f.name === dis.name)
        ? filteredDiscountList.push({ name: dis.name, objectClass: dis.name })
        : null
    );
    listOfObject[3].subObjectList = dataList.fees;
    listOfObject[4].subObjectList = filteredDiscountList;
    setListOfObject(JSON.parse(JSON.stringify(listOfObject)));
  }, [dataList]);

  useEffect(() => {
    if (excelData) {
      const columnNames = [];
      Object.keys(excelData[3]).map((key, index) =>
        columnNames.push({
          number: index,
          name: excelData[3][key],
          excelName: key,
          objectName:
            excelData[3][key].indexOf("İndirim") > -1
              ? "Discount"
              : excelData[3][key].indexOf("Veli") > -1
              ? "Parent"
              : "Student",
          subObjectName: "",
          active: !(
            excelData[3][key].indexOf("Tarihi") > -1 ||
            excelData[3][key].indexOf("Tutar") > -1 ||
            excelData[3][key].indexOf("Sonrası") > -1 ||
            excelData[3][key].indexOf("Okul") > -1 ||
            excelData[3][key].indexOf("KDV") > -1 ||
            excelData[3][key].indexOf("Ödemeler") > -1 ||
            excelData[3][key].indexOf("Sözleşme") > -1 ||
            excelData[3][key].indexOf("Kayıt") > -1
          ),
        })
      );

      columnNames.map((c) =>
        c.objectName === "Student"
          ? (c.subObjectName = _listOfObject[1].subObjectList.find(
              (sub) => sub.excelName === c.name
            )
              ? _listOfObject[1].subObjectList.find(
                  (sub) => sub.excelName === c.name
                ).objectClass
              : "")
          : c.subObjectName
      );

      columnNames.map((c) =>
        c.objectName === "Parent"
          ? (c.subObjectName = _listOfObject[2].subObjectList.find(
              (sub) => sub.excelName === c.name
            )
              ? _listOfObject[2].subObjectList.find(
                  (sub) => sub.excelName === c.name
                ).objectClass
              : "")
          : c.subObjectName
      );
      columnNames.map((c) =>
        c.objectName === "Discount"
          ? (c.subObjectName = _listOfObject[4].subObjectList.find(
              (sub) => sub.name.substring(0, 5) === c.name.substring(0, 5)
            )
              ? _listOfObject[4].subObjectList.find(
                  (sub) => sub.name.substring(0, 5) === c.name.substring(0, 5)
                ).objectClass
              : "")
          : c.subObjectName
      );
      //.substring(0, 5)
      setColumnNameList(columnNames);
      const _studentData = [];
      for (let i = 4; i < excelData.length; i++) {
        const individualExcelData = excelData[i];
        let student = {};
        for (let j = 0; j < columnNames.length; j++) {
          student[columnNames[j].name] =
            individualExcelData[columnNames[j].excelName];
        }
        _studentData.push(student);
      }
      setStudentList(_studentData);
    }
  }, [excelData]);

  const handleFile = (e) => {
    let fileTypes = [
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "text/csv",
    ];
    let selectedFile = e.target.files[0];
    if (selectedFile) {
      if (selectedFile && fileTypes.includes(selectedFile.type)) {
        setTypeError(null);
        let reader = new FileReader();
        reader.readAsArrayBuffer(selectedFile);
        reader.onload = (e) => {
          setExcelFile(e.target.result);
        };
      } else {
        setTypeError("Sadece Excel dosyası seçiniz.");
        setExcelFile(null);
      }
    }
  };

  const handleFileSubmit = (e) => {
    e.preventDefault();
    if (excelFile !== null) {
      const workbook = XLSX.read(excelFile, { type: "buffer" });
      const worksheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[worksheetName];
      const data = XLSX.utils.sheet_to_json(worksheet);
      setExcelData(data);
    }
  };

  const SelectExcelFile = () => {
    return (
      <>
        <div>
          <div className="d-flex flex-row">
            <FormInput
              text="K12 Dosya Yükleme"
              placeholder=""
              type={"file"}
              col="11"
              onClick={(e) => {
                e.target.value = "";
                setExcelFile(null);
              }}
              onChange={handleFile}
            />
            <div className="col-1">
              {excelFile ? (
                <div className="mx-2" style={{ marginTop: 30 }}>
                  <button
                    className="btn btn-success text-light"
                    onClick={handleFileSubmit}
                  >
                    İşle
                  </button>
                </div>
              ) : null}
            </div>
          </div>
        </div>
        {typeError && (
          <div className="alert alert-danger" role="alert">
            {typeError}
          </div>
        )}
      </>
    );
  };

  //   const ShowExcelFileOld = () => {
  //     return (
  //       <div className="wrapper">
  //         <div className="viewer">
  //           {excelData ? (
  //             <div className="table-responsive">
  //               <table className="table">
  //                 <thead>
  //                   <tr>
  //                     {Object.keys(excelData[3]).map((key) => (
  //                       <th key={key}>{excelData[3][key]}</th>
  //                     ))}
  //                   </tr>
  //                 </thead>

  //                 <tbody>
  //                   {excelData.map((individualExcelData, index) =>
  //                     index > 3 ? (
  //                       <tr key={index}>
  //                         {Object.keys(individualExcelData).map((key) => (
  //                           <td key={key}>{individualExcelData[key]}</td>
  //                         ))}
  //                       </tr>
  //                     ) : null
  //                   )}
  //                 </tbody>
  //               </table>
  //             </div>
  //           ) : (
  //             <div>No File is uploaded yet!</div>
  //           )}
  //         </div>
  //       </div>
  //     );
  //   };

  const ShowExcelFile = () => {
    return (
      <div className="wrapper">
        <div className="viewer">
          {excelData ? (
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    {columnNameList.map((column, key) =>
                      column.active ? <th key={key}>{column.name}</th> : null
                    )}
                  </tr>
                </thead>
                <tbody>
                  {studentList.map((student, index) =>
                    index > 3 ? (
                      <tr key={index}>
                        {columnNameList.map((column, key) =>
                          column.active ? (
                            <td key={key}>{student[column.name]}</td>
                          ) : null
                        )}
                      </tr>
                    ) : null
                  )}
                </tbody>
              </table>
            </div>
          ) : null}
        </div>
      </div>
    );
  };

  const handleObjectName = (e, name, index) => {
    e.preventDefault();
    columnNameList[index].objectName = name;
    setColumnNameList(JSON.parse(JSON.stringify(columnNameList)));
    setUpdated(!update);
  };

  const handleSubOjectName = (e, name, index) => {
    e.preventDefault();
    columnNameList[index].subObjectName = name;
    setColumnNameList(JSON.parse(JSON.stringify(columnNameList)));
  };

  const handleSaveEvent = async (e) => {
    setShowPleaseWaitModal(true);
    e.preventDefault();
    const userId = localStorage.getItem("userId");

    const body = {
      studentList: studentList,
      columnNameList: columnNameList,
      userId: userId,
    };
    const { data, err } = await dataTransferByParams(
      JSON.stringify(body),
      user?.access_token
    );
    if (err) {
      NotificationManager.error("Veri aktarımı yapılırken bir hata oluştu");
      return;
    }
    navigate("/");
  };

  const handleCloumnRemove = async (e, index) => {
    e.preventDefault();
    columnNameList[index].active = false;
    setColumnNameList(JSON.parse(JSON.stringify(columnNameList)));
  };

  const ColumnMatching = () => {
    return (
      <div className="wrapper">
        <div className="viewer">
          {excelData ? (
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th></th>
                    <th>Kolon</th>
                    <th>Alan</th>
                    <th>Alt Alan</th>
                    <th></th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {columnNameList.map((column, index) =>
                    column.active ? (
                      <tr key={index}>
                        <td>
                          <div
                            onClick={(e) => {
                              handleCloumnRemove(e, index);
                            }}
                            style={{
                              textDecoration: "underline",
                              color: "blue",
                              cursor: "pointer",
                              marginLeft: 5,
                            }}
                          >
                            (Sil)
                          </div>
                        </td>
                        <td>{column.name}</td>
                        <td>{column.objectName}</td>
                        <td>{column.subObjectName}</td>
                        <td>
                          <FormSelect
                            value={column.objectName}
                            onChange={(e) =>
                              handleObjectName(e, e.target.value, index)
                            }
                            data={listOfObject}
                            valuekey="objectClass"
                            textkey="name"
                          />
                        </td>
                        <td>
                          <FormSelect
                            value={column.subObjectName}
                            onChange={(e) =>
                              handleSubOjectName(e, e.target.value, index)
                            }
                            data={
                              listOfObject.find(
                                (o) => o.objectClass === column.objectName
                              ).subObjectList
                            }
                            valuekey="objectClass"
                            textkey="name"
                          />
                        </td>
                        <td></td>
                      </tr>
                    ) : null
                  )}
                </tbody>
              </table>
            </div>
          ) : null}
        </div>
      </div>
    );
  };

  const RenderPleaseWaitModal = () => {
    return (
      <BaseModal
        modalIsOpen={showPleaseWaitModal}
        contentLabel={"Ücret kalemi bilgileri"}
      >
        <div className="col-md-12">
          <div className="d-flex flex-row justify-content-center">
            <div>Lütfen bekleyin öğrencileri oluşturuyoruz</div>
          </div>
        </div>
      </BaseModal>
    );
  };

  return (
    <div className="content">
      {RenderPleaseWaitModal()}
      {SelectExcelFile()}
      {ColumnMatching()}
      {ShowExcelFile()}
      {studentList?.length > 0 && columnNameList?.length > 0 ? (
        <div className="my-2">
          <div className="d-flex justify-content-end">
            <button
              className="btn btn-success text-light"
              onClick={handleSaveEvent}
            >
              Kaydet
            </button>
          </div>
        </div>
      ) : null}
    </div>
  );
}
