import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import FormInput from "../app/components/FormInput";
import BaseModal from "./components/BaseModal";
import { NotificationManager } from "react-notifications";
import { retrieveAllContractsInformationBySchoolId } from "../requests/ContractRequests";
import { retrieveQuotaListBySchoolId } from "../requests/SchoolRequests";
import { retrieveAllStudentsBySchoolIdInformation } from "../requests/StudentRequests";

const paymentType = ["PESIN", "TAKSİT", "HAVALE", "MANUEL"];

export default function Report() {
  const [dataList, setDataList] = useState([]);
  const [quotaList, setQuotaList] = useState(null);
  const [selectedGrade, setSelectedGrade] = useState(null);
  const [studentList, setStudentList] = useState([]);
  const [page, setPage] = useState(0);
  const [showReportByGradeModal, setShowReportByGradeModal] = useState(false);
  const schoolId = localStorage.getItem("schoolId");
  const user = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    const retrieveAndSetContractInformationBySchoolId = async () => {
      const { data, err } = await retrieveAllContractsInformationBySchoolId(
        schoolId,
        user?.access_token
      );
      if (err) {
        NotificationManager.error(
          "Öğrenci sözleşmeleri sorgulanırken bir hata oluştu"
        );
        return;
      }
      setDataList(data);
    };
    const retrieveAndSetQuotaListBySchoolId = async () => {
      const { data, err } = await retrieveQuotaListBySchoolId(
        schoolId,
        user?.access_token
      );
      if (err) {
        NotificationManager.error("Kota listesi sorgulanırken bir hata oluştu");
        return;
      }
      setQuotaList(data);
    };
    const retrieveAndSetStudentListBySchoolId = async () => {
      const { data, err } = retrieveAllStudentsBySchoolIdInformation(
        schoolId,
        user?.access_token
      );
      if (err) {
        NotificationManager.error(
          "Öğrenci listesi sorgulanırken bir hata oluştu"
        );
        return;
      }
      setStudentList(data);
    };
    if (!schoolId) return;
    retrieveAndSetContractInformationBySchoolId();
    retrieveAndSetQuotaListBySchoolId();
    retrieveAndSetStudentListBySchoolId();
  }, [schoolId]);

  const calculateCountDone = (gradeId) => {
    if (!dataList) return 0;
    const count = dataList.filter(
      (d) => d.student.grade.id === gradeId && d.status === "END"
    );
    return count.length;
  };

  const textStatus = (contract, student) => {
    if (student === "NOT_RENEWED") {
      return "KAYIT DIŞI";
    }
    if (contract === "NORMAL") {
      return "BAŞLAMADI";
    } else if (contract === "CONTINUE") {
      return "DEVAM";
    } else if (contract === "COMPLETED") {
      return "SÖZLEŞME";
    } else if (contract === "INVITATION") {
      return "OKULA ÇAĞRILDI";
    } else if (contract === "FORBIDDEN") {
      return "KAYIT ONAYSIZ";
    }
    return "BAŞLAMADI";
  };

  const filterStudentList = () => {
    if (!studentList) return [];
    if (!Array.isArray(studentList)) return [];
    if (!selectedGrade) return [];
    return studentList.filter(
      (student) => student.grade.id === selectedGrade.id
    );
  };

  const RenderShowReportByGradeModalContent = () => {
    return (
      <BaseModal
        modalIsOpen={showReportByGradeModal}
        contentLabel={"İndirim kalemi bilgileri"}
        closeModal={() => {
          setShowReportByGradeModal(false);
        }}
      >
        <div className="my-3">
          <h5>Öğrenci kayıt durumu</h5>
        </div>
        <div className="table-responsive">
          <table className="table table-centered table-nowrap mb-0 rounded table-hover">
            <thead className="thead-light">
              <tr>
                <th className="border-0 rounded-start">#</th>
                <th className="border-0">Öğrenci</th>
                <th className="border-0">Durumu</th>
              </tr>
            </thead>
            <tbody>
              {filterStudentList().map((student, key) =>
                student.grade.id === selectedGrade.id ? (
                  <tr key={key}>
                    <td>
                      <Link to="#" className="text-primary fw-bold">
                        {key + 1}
                      </Link>
                    </td>
                    <td>
                      {student.name} {student.lastname}{" "}
                    </td>

                    <td>
                      {textStatus(
                        student.registrationStatus,
                        student.studentStatus
                      )}
                    </td>
                  </tr>
                ) : null
              )}
            </tbody>
          </table>
        </div>
      </BaseModal>
    );
  };

  const reContractReport = () => {
    return (
      <div className="table-responsive">
        <table className="table table-centered mb-0 rounded table-hover w-100">
          <thead className="thead-light">
            <tr>
              <th className="border-0">Sınıf Düzeyi</th>
              <th className="border-0">Kontenjan</th>
              <th className="border-0">Kayıtlı Öğrenci</th>
              <th className="border-0"></th>
            </tr>
          </thead>
          <tbody>
            {dataList &&
            Array.isArray(dataList) &&
            quotaList &&
            quotaList.gradeList
              ? quotaList.gradeList.map((f, key) => (
                  <tr
                    key={key}
                    onClick={() => setSelectedGrade(f)}
                    style={{ cursor: "pointer" }}
                    className={`${
                      selectedGrade && selectedGrade.id === f.id
                        ? "table-warning"
                        : ""
                    } `}
                  >
                    <td>{f.name}</td>
                    <td>{f.count}</td>
                    <td>{calculateCountDone(f.id, f.count)}</td>
                    <td>
                      <button
                        className="btn btn-secondary "
                        onClick={() => setShowReportByGradeModal(true)}
                      >
                        Detay
                      </button>
                    </td>
                  </tr>
                ))
              : null}
          </tbody>
        </table>
      </div>
    );
  };

  const calculatePayment = (paymentType) => {
    const count = studentList.filter(
      (d) => d.contract.paymentType === paymentType
    );
    return count.length;
  };

  const methotReport = () => {
    return (
      <div className="table-responsive">
        <form>
          <div className="row">
            <FormInput
              name="beginAt"
              text="Rapor Bailangıç Tarihi"
              placeholder=""
              type="date"
              col="3"
              required
            />
            <FormInput
              name="beginAt"
              text="Rapor Bitiş Tarihi"
              placeholder=""
              type="date"
              col="3"
              required
            />
          </div>
        </form>
        <table className="table table-centered mb-0 rounded table-hover w-100">
          <thead className="thead-light">
            <tr>
              <th className="border-0">Ödeme Yöntemi</th>
              <th className="border-0">Öğrenci Sayısı</th>
            </tr>
          </thead>
          <tbody>
            {paymentType.map((f, key) => (
              <tr key={key}>
                <td>{f}</td>
                <td>{calculatePayment(f)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  const noStudentReport = () => {
    return (
      <div className="table-responsive">
        <table className="table table-centered mb-0 rounded table-hover w-100">
          <thead className="thead-light">
            <tr>
              <th className="border-0">Öğrenci Adı Soyadı</th>
              <th className="border-0">Sınıfı</th>
              <th className="border-0">İşlem Yapan</th>
              <th className="border-0">Gerekçe</th>
            </tr>
          </thead>
        </table>
      </div>
    );
  };

  const exportReport = () => {
    return <div className="alert alert-info"> Rapor Hazırlanıyor...</div>;
  };

  return (
    <>
      {RenderShowReportByGradeModalContent()}
      <div className="content">
        <div className="col-12 col-xl-12">
          <div className="card border-0 shadow">
            <div className="card-body">
              <h4>Raporlamalar</h4>

              <div className="row">
                <div className="col-3 mb-2">
                  <button
                    onClick={() => setPage(0)}
                    className="btn btn-sm btn-secondary me-2 w-100"
                  >
                    Sınıflara göre kayıt yenilemeler
                  </button>
                </div>
                <div className="col-3">
                  <button
                    onClick={() => setPage(1)}
                    className="btn btn-sm btn-secondary me-2 w-100"
                  >
                    Ödeme yöntemlerine göre kayıt yenilemeler
                  </button>
                </div>
                <div className="col-3">
                  <button
                    onClick={() => setPage(2)}
                    className="btn btn-sm btn-secondary me-2 w-100"
                  >
                    Kaydı yenilenmeyecek öğrenciler
                  </button>
                </div>
                <div className="col-3">
                  <button
                    onClick={() => setPage(3)}
                    className="btn btn-sm btn-secondary me-2 w-100"
                  >
                    Sözleşmeleri dışa aktar
                  </button>
                </div>
              </div>
              <div className="mt-5">
                {page === 0
                  ? reContractReport()
                  : page === 1
                  ? methotReport()
                  : page === 2
                  ? noStudentReport()
                  : page === 3
                  ? exportReport()
                  : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
