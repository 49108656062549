import axios from "axios";
import config from "../config/config.json";

export const queryUserLogsByUserId = async (userId, accessToken) => {
  const configuration = {
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
      Authorization: `Bearer ${accessToken}`,
    },
  };

  try {
    const response = await axios.post(
      `${config.api.invokeUrl}/logs/query/by-userId`,
      { userId },
      configuration
    );
    return { data: response.data };
  } catch (err) {
    console.log(err);
    return { err };
  }
};

export const queryUserLogsByEntityId = async (entityId, accessToken) => {
  const configuration = {
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
      Authorization: `Bearer ${accessToken}`,
    },
  };

  try {
    const response = await axios.post(
      `${config.api.invokeUrl}/logs/query/by-entityId`,
      { entityId },
      configuration
    );
    return { data: response.data };
  } catch (err) {
    console.log(err);
    return { err };
  }
};

export const queryUserLogsByParams = async (queryParams, accessToken) => {
  const configuration = {
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
      Authorization: `Bearer ${accessToken}`,
    },
  };

  try {
    const response = await axios.post(
      `${config.api.invokeUrl}/logs/query/by-params`,
      queryParams,
      configuration
    );
    return { data: response.data };
  } catch (err) {
    console.log(err);
    return { err };
  }
};
