import axios from "axios";
import config from "../config/config.json";

export const retrieveSchoolSettingsBySchoolId = async (
  schoolId,
  accessToken
) => {
  const configuration = {
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
      Authorization: `Bearer ${accessToken}`,
    },
  };

  try {
    const response = await axios.get(
      `${config.api.invokeUrl}/schools/settings/${schoolId}`,
      configuration
    );
    return { data: response.data };
  } catch (err) {
    console.log(err);
    return { err };
  }
};

export const patchSchoolSettings = async (body, accessToken) => {
  const configuration = {
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
      Authorization: `Bearer ${accessToken}`,
    },
  };

  try {
    const response = await axios.patch(
      `${config.api.invokeUrl}/schools/settings/`,
      body,
      configuration
    );
    return { data: response.data };
  } catch (err) {
    console.log(err);
    return { err };
  }
};

export const retrieveSchoolQuotaByGradeId = async (
  gradeId,
  quota,
  accessToken
) => {
  const configuration = {
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
      Authorization: `Bearer ${accessToken}`,
    },
  };

  try {
    const response = await axios.get(
      `${config.api.invokeUrl}/schools/grade/${gradeId}/cont/${quota}`,
      configuration
    );
    return { data: response.data };
  } catch (err) {
    console.log(err);
    return { err };
  }
};

export const retrieveQuotaListBySchoolId = async (schoolId, accessToken) => {
  const configuration = {
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
      Authorization: `Bearer ${accessToken}`,
    },
  };

  try {
    const response = await axios.get(
      `${config.api.invokeUrl}/schools/parameter/${schoolId}`,
      configuration
    );
    return { data: response.data };
  } catch (err) {
    console.log(err);
    return { err };
  }
};
