import Logo from "../../../assets/logo.png";

const ContractHeader = (props) => {
  const { student } = props;
  return (
    <div>
      <div className="w-100 text-center">
        T.C.
        <br />
        MİLLÎ EĞİTİM BAKANLIĞI
        <br />
        ÖZEL ÖĞRETİM KURUMLARI GENEL MÜDÜRLÜĞÜ ÖĞRENCİ KAYIT SÖZLEŞMESİ
        <br />
      </div>
      <div className="d-flex flex-row justify-content-between align-items-center mb-5">
        <div className="w-100 d-flex justify-content-start">
          <div className="" style={{ width: "200px" }}>
            <img
              src={Logo}
              alt="atekent_logo"
              style={{ width: "100%" }}
              className="p-3"
            />
          </div>
        </div>
        <div className="w-100 text-center">
          <b>
            {student.name} {student.lastname}
          </b>
          <br />
          <br />
          <span style={{ fontStyle: "italic" }}>
            <b>{student.school.name}</b>
            <br />
            2024-2025 ÖĞRETİM YILI
          </span>
        </div>
        <div className="w-100 d-flex justify-content-end">
          <div className="" style={{ width: "200px" }}>
            <img
              src={Logo}
              alt="atekent_logo"
              style={{ width: "100%" }}
              className="p-3"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContractHeader;
