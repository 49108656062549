import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import FormInput from "../app/components/FormInput";
import FormSelect from "../app/components/FormSelect";
import React from "react";
import AddDiscountPanel from "./page-parts/AddDiscountPanel";
import ParentInformation from "./page-parts/ParentInformation";
import CreditCartInstallment from "./page-parts/payments/CreditCartInstallment";
import AddFeesPanel from "./page-parts/AddFeesPanel";
import { formatMoneyToTL } from "../utilization/MoneyOperations";
import BaseModal from "./components/BaseModal";
import { retrieveQuotaListBySchoolId } from "../requests/SchoolRequests";
import { NotificationManager } from "react-notifications";
import {
  queryStudentListByParams,
  retrieveStudentWithData,
} from "../requests/StudentRequests";
import {
  addDiscountToStudentByParams,
  addFeeToStudentByParams,
  removeDiscountFromStudentByParams,
  removeFeeFromStudentByParams,
  retrieveContractPaymentByStudentId,
  retrieveDiscountListByFeeId,
  retrieveFeeListBySchoolIdAndGradeId,
  retrieveStudentContractByStudentIdAndParentId,
} from "../requests/ContractRequests";

const statusList = [
  {
    status: "all",
    name: "TÜMÜ",
  },
  {
    status: "NORMAL",
    name: "BAŞLAMADI",
  },
  {
    status: "CONTINUE",
    name: "DEVAM EDİYOR",
  },
  {
    status: "COMPLETED",
    name: "TAMAMLANDI",
  },
  {
    status: "INVITATION",
    name: "OKULA ÇAĞRILDI",
  },
  {
    status: "FORBIDDEN",
    name: "KAYIT ONAYSIZ",
  },
];

export default function Student() {
  const navigate = useNavigate();
  const [studentList, setStudentList] = useState([]);
  const [quotaList, setQuotaList] = useState(null);
  const [studentWithData, setStudentWithData] = useState([]);
  const [studentContract, setStudentContract] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedFee, setSelectedFee] = useState(null);
  const [feeList, setFeeList] = useState([]);
  const [discountList, setDiscountList] = useState([]);
  const [addFeesSelected, setAddFeesSelected] = useState(null);
  const [addDiscountSelected, setAddDiscountSelected] = useState(null);
  const [indirimPanelVisible, setIndirimPanelVisible] = useState(false);
  const [ucretPanelVisible, setUcretPanelVisible] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState("all");
  const [selectedGradeId, setSelectedGradeId] = useState(null);
  const [selectedBranchId, setSelectedBranchId] = useState(null);
  const [textContains, setTextContains] = useState("");
  const [paymentList, setPaymentList] = useState([]);
  const [showSelectedStudentModal, setShowSelectedStudentModal] =
    useState(false);
  const schoolId = localStorage.getItem("schoolId");
  const user = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    const retrieveAndSetQuotaListBySchoolId = async () => {
      const { data, err } = await retrieveQuotaListBySchoolId(
        schoolId,
        user?.access_token
      );
      if (err) {
        NotificationManager.error("Kota listesi sorgulanırken bir hata oluştu");
        return;
      }
      setQuotaList(data);
    };
    if (!schoolId) return;
    retrieveAndSetQuotaListBySchoolId();
  }, []);

  useEffect(() => {
    if (!quotaList) return;
    const { gradeList, branchList } = quotaList;
    const grade = gradeList[0];
    const foundBranch = branchList.find(
      (branch) => branch.grade.id === grade.id
    );
    if (grade) {
      setSelectedGradeId(grade.id);
    }
    if (foundBranch) {
      setSelectedBranchId(foundBranch.id);
    }
  }, [quotaList]);

  useEffect(() => {
    const retrieveAndSetDiscountListByFeeId = async () => {
      const { data, err } = await retrieveDiscountListByFeeId(
        selectedFee.schoolFee.id,
        user?.access_token
      );
      if (err) {
        NotificationManager.error(
          "İndirim kalemleri sorgulanırken bir hata oluştu"
        );
        return;
      }
      setDiscountList(data);
    };
    if (!selectedFee) return;
    retrieveAndSetDiscountListByFeeId();
  }, [selectedFee]);

  useEffect(() => {
    const retrieveAndSetStudentWithData = async () => {
      const { data, err } = await retrieveStudentWithData(
        selectedRow.id,
        user?.access_token
      );
      if (err) {
        NotificationManager.error(
          "Öğrenci bilgisi sorgulanırken bir hata oluştu"
        );
        return;
      }
      setStudentWithData(data);
    };
    if (selectedRow && selectedRow.id) {
      retrieveAndSetStudentWithData();
    }
  }, [selectedRow]);

  useEffect(() => {
    const retrieveAndSetStudentListByParams = async () => {
      const { data, err } = await queryStudentListByParams(
        { gradeId: selectedGradeId, branchId: selectedBranchId },
        user?.access_token
      );
      if (err) {
        NotificationManager.error(
          "Öğrenci listesi sorgulanırken bir hata oluştu"
        );
        return;
      }
      setStudentList(data);
    };
    if (!selectedBranchId) return;
    if (!selectedGradeId) return;
    retrieveAndSetStudentListByParams();
  }, [selectedGradeId, selectedBranchId]);

  useEffect(() => {
    const retrieveAndSetFeeListBySchoolIdAndGradeId = async () => {
      const { data, err } = await retrieveFeeListBySchoolIdAndGradeId(
        studentWithData.school.id,
        studentWithData.grade.id,
        user?.access_token
      );
      if (err) {
        NotificationManager.error(
          "Ücret kalemleri sorgulanırken bir hata oluştu"
        );
        return;
      }
      setFeeList(data);
    };
    const retrieveAndSetContractPaymentListByStudentId = async () => {
      const { data, err } = await retrieveContractPaymentByStudentId(
        studentWithData.id,
        user?.access_token
      );
      if (err) {
        NotificationManager.error(
          "Sözleşme ödemeleri sorgulanırken bir hata oluştu"
        );
        return;
      }
      setPaymentList(data);
    };
    if (studentWithData && studentWithData.id) {
      handlePanelVisible("");
      setDiscountList(null);
      retrieveAndSetFeeListBySchoolIdAndGradeId();
      retrieveAndSetStudentContractByStudentIdAndParentId();
      retrieveAndSetContractPaymentListByStudentId();
      setSelectedFee(null);
      localStorage.setItem(
        "parent_data",
        JSON.stringify({
          ...studentWithData.parents[0].user,
          user_id: studentWithData.parents[0].user.id,
        })
      );
    }
  }, [studentWithData]);

  useEffect(() => {
    if (!studentContract) return;
    if (!studentContract.contractFees) return;
    const filteredFeeList = filterFeesList();
    if (filteredFeeList.length > 0) {
      setAddFeesSelected(filteredFeeList[0].id);
    }
  }, [studentContract, feeList]);

  useEffect(() => {
    if (!selectedFee) return;
    const filteredDiscountList = filterDiscountList();
    if (filteredDiscountList.length > 0) {
      setAddDiscountSelected(filteredDiscountList[0].id);
    }
  }, [selectedFee, studentContract, discountList]);

  useEffect(() => {
    setSelectedFee(null);
    setUcretPanelVisible(false);
    setIndirimPanelVisible(false);
  }, [studentContract]);

  const retrieveAndSetStudentContractByStudentIdAndParentId = async () => {
    const { data, err } = await retrieveStudentContractByStudentIdAndParentId(
      studentWithData.id,
      studentWithData.parents[0].id,
      user?.access_token
    );
    if (err) {
      NotificationManager.error(
        "Öğrenci sözleşme detayı sorgulanırken bir hata oluştu"
      );
      return;
    }
    setStudentContract(data);
  };

  const filterFeesList = () => {
    if (!feeList) return [];
    if (!studentContract) return [];
    const filteredFeeList = [];
    feeList.forEach((fee) => {
      const foundIndex = studentContract.contractFees.findIndex(
        (contractFee) => contractFee.name === fee.name
      );
      if (foundIndex === -1) {
        filteredFeeList.push(fee);
      }
    });
    return filteredFeeList;
  };

  const filterDiscountList = () => {
    if (!selectedFee) return [];
    if (!studentContract) return [];
    if (!discountList) return [];
    const filteredContracts = studentContract.contractDiscounts.filter(
      (discount) => discount.contractFees.id === selectedFee.id
    );
    const filteredDiscountList = [];
    discountList.forEach((discount) => {
      const foundIndex = filteredContracts.findIndex(
        (contract) => contract.name === discount.name
      );
      if (foundIndex === -1) {
        filteredDiscountList.push(discount);
      }
    });
    return filteredDiscountList.filter(
      (item) =>
        item.rule !== "EARLY_DISCOUNT" &&
        item.rule !== "CASH_DISCOUNT" &&
        item.rule !== "SIBLING_DISCOUNT"
    );
  };

  const hasDiscountInFees = (feesId) => {
    if (
      studentContract.contractDiscounts &&
      Array.isArray(studentContract.contractDiscounts)
    ) {
      const discountListForFees = studentContract.contractDiscounts.filter(
        (discount) =>
          discount.contractFees && discount.contractFees.id === feesId
      );
      return discountListForFees;
    }
    return [];
  };

  const feesTable = () => {
    return (
      <div className="table-responsive mt-3">
        <table className="table table-centered mb-0 rounded table-hover w-100">
          <thead className="thead-light">
            <tr>
              <th className="border-0">ÜCRET KALEMİ</th>
              <th className="border-0" style={{ whiteSpace: "wrap" }}>
                KURUMUN İLAN ETTİĞİ ÜCRETLER (KDV DAHİL)
              </th>
              <th className="border-0" style={{ whiteSpace: "wrap" }}>
                ÖĞRENCİ İÇİN BELİRLENEN ÜCRETLER (KDV DAHİL)
              </th>
            </tr>
          </thead>
          <tbody>
            {studentContract && Array.isArray(studentContract.contractFees)
              ? studentContract.contractFees.map((fees, key) => (
                  <>
                    <tr
                      onClick={() => setSelectedFee(fees)}
                      key={key}
                      style={{ cursor: "pointer" }}
                      className={`${
                        selectedFee?.id === fees.id ? "table-warning" : ""
                      } `}
                    >
                      <td>
                        <div>
                          {fees.name}
                          {fees.schoolFee.isNecessary === false &&
                          studentWithData.registrationStatus !== "COMPLETED" &&
                          hasDiscountInFees(fees.id).length === 0 ? (
                            <span
                              onClick={() => {
                                handlerRemoveFees(fees.id);
                              }}
                              style={{
                                textDecoration: "underline",
                                color: "blue",
                                cursor: "pointer",
                                marginLeft: 5,
                              }}
                            >
                              (Sil)
                            </span>
                          ) : null}
                        </div>
                      </td>
                      <td>{formatMoneyToTL(fees.feesWithTax)} TL</td>
                      <td>
                        <b>{formatMoneyToTL(fees.totalFees)} TL</b>
                      </td>
                    </tr>
                    {hasDiscountInFees(fees.id).map((discount, key) => (
                      <tr className="w-100" key={key}>
                        <td style={{ maxWidth: "20%" }}>
                          <div>
                            {discount.name}(%{discount.discount})
                            {studentWithData.registrationStatus !==
                            "COMPLETED" ? (
                              <span
                                onClick={() =>
                                  handlerRemoveDiscountFees(discount.id)
                                }
                                style={{
                                  textDecoration: "underline",
                                  color: "blue",
                                  cursor: "pointer",
                                  marginLeft: 5,
                                }}
                              >
                                (Sil)
                              </span>
                            ) : null}
                          </div>
                        </td>
                        <td>-</td>
                        <td>{formatMoneyToTL(discount.discountFees)} TL</td>
                      </tr>
                    ))}
                  </>
                ))
              : null}

            <tr>
              <td></td>
              <td>
                <b>GENEL TOPLAM:</b>
              </td>
              <td>
                <b>
                  {studentContract &&
                  Array.isArray(studentContract.contractFees)
                    ? generalTotalFees()
                    : null}
                </b>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  };

  const textStatus = (contract, student) => {
    if (student === "NOT_RENEWED") {
      return "KAYIT DIŞI";
    }
    if (contract === "NORMAL") {
      return "BAŞLAMADI";
    } else if (contract === "CONTINUE") {
      return "DEVAM";
    } else if (contract === "COMPLETED") {
      return "SÖZLEŞME";
    } else if (contract === "INVITATION") {
      return "OKULA ÇAĞRILDI";
    } else if (contract === "FORBIDDEN") {
      return "KAYIT ONAYSIZ";
    }
    return "BAŞLAMADI";
  };

  const handlerAddFees = async () => {
    const body = {
      studentId: studentWithData.id,
      parentId: studentWithData.parents[0].id,
      schoolFeesId: addFeesSelected,
    };
    const { data, err } = await addFeeToStudentByParams(
      body,
      user?.access_token
    );
    if (err) {
      NotificationManager.error(
        "Öğrenciye ücret kalemi eklenirken bir hata oluştu"
      );
      return;
    }
    retrieveAndSetStudentContractByStudentIdAndParentId();
  };

  const handlerRemoveFees = async (id) => {
    const body = {
      studentId: studentWithData.id,
      parentId: studentWithData.parents[0].id,
      schoolFeesId: id,
    };
    const { data, err } = await removeFeeFromStudentByParams(
      body,
      user?.access_token
    );
    if (err) {
      NotificationManager.error(
        "Öğrenciden ücret kalemi silinirken bir hata oluştu"
      );
      return;
    }
    retrieveAndSetStudentContractByStudentIdAndParentId();
  };

  const handlerAddDiscountFees = async () => {
    const body = {
      studentId: studentWithData.id,
      parentId: studentWithData.parents[0].id,
      schoolDiscountId: addDiscountSelected,
      contractFeesId: selectedFee.id,
    };
    const { data, err } = await addDiscountToStudentByParams(
      body,
      user?.access_token
    );
    if (err) {
      NotificationManager.error(
        "Öğrenciye indirim kalemi eklenirken bir hata oluştu"
      );
      return;
    }
    retrieveAndSetStudentContractByStudentIdAndParentId();
  };

  const handlerRemoveDiscountFees = async (id) => {
    const body = {
      studentId: studentWithData.id,
      parentId: studentWithData.parents[0].id,
      schoolDiscountId: id,
      contractFeesId: null,
    };
    const { data, err } = await removeDiscountFromStudentByParams(
      body,
      user?.access_token
    );
    if (err) {
      NotificationManager.error(
        "Öğrenciden indirim kalemi silinirken bir hata oluştu"
      );
      return;
    }
    retrieveAndSetStudentContractByStudentIdAndParentId();
  };

  const generalTotalFees = () => {
    let total = 0;
    for (let i = 0; i < studentContract.contractFees.length; i++) {
      total += studentContract.contractFees[i].totalFees;
    }
    return formatMoneyToTL(total) + " TL";
  };

  const handlePanelVisible = (panel) => {
    if (panel === "indirim") {
      setIndirimPanelVisible(true);
      setUcretPanelVisible(false);
    } else if (panel === "odeme") {
      setIndirimPanelVisible(false);
      setUcretPanelVisible(true);
    } else {
      setIndirimPanelVisible(false);
      setUcretPanelVisible(false);
    }
  };

  const handleSelectGradeOnChange = (e) => {
    const { branchList } = quotaList;
    const foundBranch = branchList.find(
      (branch) => branch.grade.id === e.target.value
    );
    setSelectedGradeId(e.target.value);
    if (foundBranch) {
      setSelectedBranchId(foundBranch.id);
    }
  };

  const handleSelectBranchOnChange = (e) => {
    setSelectedBranchId(e.target.value);
  };

  const retrieveFilteredBranchList = () => {
    if (!quotaList) return [];
    return quotaList.branchList.filter(
      (branch) => branch.grade.id === selectedGradeId
    );
  };

  const retrieveFilteredStudentList = () => {
    if (textContains) {
      return studentList.filter(
        (student) =>
          student.name.toUpperCase().includes(textContains.toUpperCase()) ||
          student.lastname.toUpperCase().includes(textContains.toUpperCase())
      );
    } else {
      return studentList;
    }
  };

  const RenderSelectedStudentModalContent = () => {
    const feeList = filterFeesList();
    const discountList = filterDiscountList();
    return (
      <BaseModal
        modalIsOpen={showSelectedStudentModal}
        contentLabel={"Öğrenci bilgileri"}
        closeModal={() => {
          setShowSelectedStudentModal(false);
        }}
      >
        <div className="col-12 ">
          <div className="my-3">
            <h5>Öğrenci bilgileri</h5>
          </div>
          <div className="card shadow border-0 text-center p-4">
            {studentWithData ? (
              <div className="card-body">
                <h4 className="h3 pt-3">
                  {" "}
                  {studentWithData.name} {studentWithData.lastname}{" "}
                </h4>
                <hr />
                <h5 className="h5 pt-3">Veli bilgileri</h5>
                {<ParentInformation selectedStudent={studentWithData} />}
                <h5 className="h5 pt-3">Kayıt bilgileri</h5>
                {feesTable()}

                {paymentList.length > 0 ? (
                  <div className="pt-3 pb-3">
                    <CreditCartInstallment payments={paymentList} />
                  </div>
                ) : null}

                {paymentList.length > 0 ? (
                  studentContract?.contract.status === "END" ? (
                    <div className="d-flex flex-row justify-content-center align-items-center">
                      <div
                        className="btn btn-sm btn-gray-800 d-inline-flex align-items-center mx-2 mt-3"
                        onClick={() => {
                          navigate(
                            `/parent-contract/${studentContract?.student.id}`
                          );
                        }}
                      >
                        Sözleşme göster
                      </div>
                      <div
                        className="btn btn-sm btn-gray-800 d-inline-flex align-items-center mx-2 mt-3"
                        onClick={() => {
                          navigate(
                            `/parent-contract/${studentContract?.student.id}/pdf`
                          );
                        }}
                      >
                        PDF göster
                      </div>
                    </div>
                  ) : null
                ) : (
                  <div className="mt-4">
                    <button
                      className="btn btn-sm btn-secondary mx-2"
                      onClick={() => handlePanelVisible("odeme")}
                    >
                      Ücret ekle
                    </button>
                    {selectedFee ? (
                      <button
                        className="btn btn-sm btn-secondary mx-2"
                        onClick={() => handlePanelVisible("indirim")}
                      >
                        İndirim ekle
                      </button>
                    ) : null}
                  </div>
                )}
                {indirimPanelVisible && discountList.length > 0 ? (
                  <div className="mt-3">
                    <AddDiscountPanel
                      discountList={discountList}
                      addDiscountSelected={addDiscountSelected}
                      setAddDiscountSelected={setAddDiscountSelected}
                      handlerDiscountFees={handlerAddDiscountFees}
                    />
                  </div>
                ) : null}
                {ucretPanelVisible && feeList.length > 0 ? (
                  <div className="mt-3">
                    <AddFeesPanel
                      addFeesSelected={addFeesSelected}
                      setAddFeesSelected={setAddFeesSelected}
                      handlerAddFees={handlerAddFees}
                      feeList={feeList}
                    />
                  </div>
                ) : null}
              </div>
            ) : null}
          </div>
        </div>
      </BaseModal>
    );
  };

  return (
    <>
      {RenderSelectedStudentModalContent()}
      <div className="content">
        <div className="col-12">
          <div className="card border-0 shadow">
            <div className="card-body">
              <form>
                <div className="row">
                  {quotaList?.gradeList ? (
                    <FormSelect
                      name="gradeId"
                      text="Sınıf"
                      placeholder=""
                      type="text"
                      col="3"
                      required
                      value=""
                      onChange={handleSelectGradeOnChange}
                      id={"inputs.id"}
                      data={quotaList.gradeList}
                      valuekey="id"
                      textkey="name"
                    />
                  ) : null}
                  {selectedGradeId && quotaList?.branchList ? (
                    <FormSelect
                      name="branchId"
                      text="Şube"
                      placeholder=""
                      type="text"
                      col="3"
                      required
                      value=""
                      onChange={handleSelectBranchOnChange}
                      id={"inputs.id"}
                      data={retrieveFilteredBranchList()}
                      valuekey="id"
                      textkey="name"
                    />
                  ) : null}
                  <FormInput
                    name="beginAt"
                    text="Kelime"
                    placeholder=""
                    type="text"
                    col="3"
                    required
                    value={textContains}
                    onChange={(e) => setTextContains(e.target.value)}
                  />
                  <FormSelect
                    name="statusId"
                    text="Durum"
                    placeholder=""
                    type="text"
                    col="3"
                    required
                    value={selectedStatus}
                    onChange={(e) => setSelectedStatus(e.target.value)}
                    id={"inputs.id"}
                    data={statusList}
                    valuekey="status"
                    textkey="name"
                  />
                </div>
              </form>
              <div className="table-responsive">
                <table className="table table-centered table-nowrap mb-0 rounded table-hover">
                  <thead className="thead-light">
                    <tr>
                      <th className="border-0 rounded-start">#</th>
                      <th className="border-0">ÖĞRENCİ</th>
                      <th className="border-0">ESKİ SINIFI</th>
                      <th className="border-0">YENİ SINIFI</th>
                      <th className="border-0">ŞUBESİ</th>
                      <th className="border-0">DURUMU</th>
                      <th className="border-0">NUMARASI</th>
                      <th className="border-0"></th>
                      <th className="border-0"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {retrieveFilteredStudentList()?.map((student, key) =>
                      selectedStatus === "all" ||
                      student.registrationStatus === selectedStatus ? (
                        <tr
                          className={`${
                            selectedRow && selectedRow.id === student.id
                              ? "table-warning"
                              : ""
                          } `}
                          key={key}
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setSelectedRow(student);
                          }}
                        >
                          <td>
                            <Link to="#" className="text-primary fw-bold">
                              {key + 1}
                            </Link>
                          </td>
                          <td>
                            {student.name} {student.lastname}{" "}
                          </td>
                          <td>{student.oldGrade.name}</td>
                          <td>{student.grade.name}</td>
                          <td>{student.branch.name}</td>
                          <td>
                            {textStatus(
                              student.registrationStatus,
                              student.studentStatus
                            )}
                          </td>
                          <td>{student.identityNumber}</td>
                          <td>
                            <button
                              className="btn btn-secondary "
                              onClick={() => setShowSelectedStudentModal(true)}
                            >
                              Detay
                            </button>
                          </td>
                          <td>
                            {student.registrationStatus ===
                            "COMPLETED" ? null : (
                              <Link
                                className="btn btn-info "
                                to="/parent"
                                target="_blank"
                              >
                                İşlem
                              </Link>
                            )}
                          </td>
                        </tr>
                      ) : null
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
