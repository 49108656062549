import axios from "axios";
import config from "../config/config.json";

export const retrieveContractInformation = async (
  studentId,
  parentId,
  accessToken
) => {
  const configuration = {
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
      Authorization: `Bearer ${accessToken}`,
    },
  };

  try {
    const response = await axios.get(
      `${config.api.invokeUrl}/contracts/${studentId}/parent/${parentId}`,
      configuration
    );
    return { data: response.data };
  } catch (err) {
    console.log(err);
    return { err };
  }
};

export const retrieveAllFeesAndDiscountsInformationBySchoolId = async (
  schoolId,
  accessToken
) => {
  const configuration = {
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
      Authorization: `Bearer ${accessToken}`,
    },
  };
  try {
    const response = await axios.get(
      `${config.api.invokeUrl}/contracts/fees/${schoolId}`,
      configuration
    );
    return { data: response.data };
  } catch (err) {
    console.log(err);
    return { err };
  }
};

export const retrieveAllContractsInformationBySchoolId = async (
  schoolId,
  accessToken
) => {
  const configuration = {
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
      Authorization: `Bearer ${accessToken}`,
    },
  };
  try {
    const response = await axios.get(
      `${config.api.invokeUrl}/contracts/${schoolId}`,
      configuration
    );
    return { data: response.data };
  } catch (err) {
    console.log(err);
    return { err };
  }
};

export const handleCreateFeeInformation = async (body, accessToken) => {
  const configuration = {
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
      Authorization: `Bearer ${accessToken}`,
    },
  };
  try {
    const response = await axios.post(
      `${config.api.invokeUrl}/contracts/fees`,
      body,
      configuration
    );
    return { data: response.data };
  } catch (err) {
    console.log(err);
    return { err };
  }
};

export const handleUpdateFeeInformation = async (feeId, body, accessToken) => {
  const configuration = {
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
      Authorization: `Bearer ${accessToken}`,
    },
  };
  try {
    const response = await axios.put(
      `${config.api.invokeUrl}/contracts/fees/${feeId}`,
      body,
      configuration
    );
    return { data: response.data };
  } catch (err) {
    console.log(err);
    return { err };
  }
};

export const handleCreateDiscountInformation = async (body, accessToken) => {
  const configuration = {
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
      Authorization: `Bearer ${accessToken}`,
    },
  };
  try {
    const response = await axios.post(
      `${config.api.invokeUrl}/contracts/discounts`,
      body,
      configuration
    );
    return { data: response.data };
  } catch (err) {
    console.log(err);
    return { err };
  }
};

export const handleUpdateDiscountInformation = async (
  discountId,
  body,
  accessToken
) => {
  const configuration = {
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
      Authorization: `Bearer ${accessToken}`,
    },
  };
  try {
    const response = await axios.put(
      `${config.api.invokeUrl}/contracts/discounts/${discountId}`,
      body,
      configuration
    );
    return { data: response.data };
  } catch (err) {
    console.log(err);
    return { err };
  }
};

export const queryContractPaymentsByParams = async (body, accessToken) => {
  const configuration = {
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
      Authorization: `Bearer ${accessToken}`,
    },
  };
  try {
    const response = await axios.post(
      `${config.api.invokeUrl}/contracts/contract-payments/query/by-params`,
      body,
      configuration
    );
    return { data: response.data };
  } catch (err) {
    console.log(err);
    return { err };
  }
};

export const approveContractPaymentInformation = async (id, accessToken) => {
  const configuration = {
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
      Authorization: `Bearer ${accessToken}`,
    },
  };
  try {
    const response = await axios.post(
      `${config.api.invokeUrl}/contracts/contract-payments/${id}/approve`,
      {},
      configuration
    );
    return { data: response.data };
  } catch (err) {
    console.log(err);
    return { err };
  }
};

export const rejectContractPaymentInformation = async (id, accessToken) => {
  const configuration = {
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
      Authorization: `Bearer ${accessToken}`,
    },
  };
  try {
    const response = await axios.post(
      `${config.api.invokeUrl}/contracts/contract-payments/${id}/reject`,
      {},
      configuration
    );
    return { data: response.data };
  } catch (err) {
    console.log(err);
    return { err };
  }
};

export const retrieveContractPaymentByStudentId = async (
  studentId,
  accessToken
) => {
  const configuration = {
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
      Authorization: `Bearer ${accessToken}`,
    },
  };
  try {
    const response = await axios.get(
      `${config.api.invokeUrl}/contracts/payment/${studentId}`,
      configuration
    );
    return { data: response.data };
  } catch (err) {
    console.log(err);
    return { err };
  }
};

export const retrieveDiscountListByFeeId = async (feeId, accessToken) => {
  const configuration = {
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
      Authorization: `Bearer ${accessToken}`,
    },
  };
  try {
    const response = await axios.get(
      `${config.api.invokeUrl}/contracts/fees/${feeId}/discounts`,
      configuration
    );
    return { data: response.data };
  } catch (err) {
    console.log(err);
    return { err };
  }
};

export const retrieveFeeListBySchoolIdAndGradeId = async (
  schoolId,
  gradeId,
  accessToken
) => {
  const configuration = {
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
      Authorization: `Bearer ${accessToken}`,
    },
  };
  try {
    const response = await axios.get(
      `${config.api.invokeUrl}/contracts/fees/by/${schoolId}/${gradeId}`,
      configuration
    );
    return { data: response.data };
  } catch (err) {
    console.log(err);
    return { err };
  }
};

export const retrieveStudentContractByParentId = async (
  parentId,
  accessToken
) => {
  const configuration = {
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
      Authorization: `Bearer ${accessToken}`,
    },
  };
  try {
    const response = await axios.get(
      `${config.api.invokeUrl}/contracts/parent/${parentId}`,
      configuration
    );
    return { data: response.data };
  } catch (err) {
    console.log(err);
    return { err };
  }
};

export const retrieveStudentContractByStudentIdAndParentId = async (
  studentId,
  parentId,
  accessToken
) => {
  const configuration = {
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
      Authorization: `Bearer ${accessToken}`,
    },
  };
  try {
    const response = await axios.get(
      `${config.api.invokeUrl}/contracts/${studentId}/parent/${parentId}`,
      configuration
    );
    return { data: response.data };
  } catch (err) {
    console.log(err);
    return { err };
  }
};

export const changeStudentContractStatusByStudentId = async (
  body,
  accessToken
) => {
  const configuration = {
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
      Authorization: `Bearer ${accessToken}`,
    },
  };
  try {
    const response = await axios.put(
      `${config.api.invokeUrl}/contracts/status`,
      body,
      configuration
    );
    return { data: response.data };
  } catch (err) {
    console.log(err);
    return { err };
  }
};

export const addFeeToStudentByParams = async (body, accessToken) => {
  const configuration = {
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
      Authorization: `Bearer ${accessToken}`,
    },
  };
  try {
    const response = await axios.post(
      `${config.api.invokeUrl}/contracts/add/fees`,
      body,
      configuration
    );
    return { data: response.data };
  } catch (err) {
    console.log(err);
    return { err };
  }
};

export const removeFeeFromStudentByParams = async (body, accessToken) => {
  const configuration = {
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
      Authorization: `Bearer ${accessToken}`,
    },
  };
  try {
    const response = await axios.post(
      `${config.api.invokeUrl}/contracts/remove/fees`,
      body,
      configuration
    );
    return { data: response.data };
  } catch (err) {
    console.log(err);
    return { err };
  }
};

export const addDiscountToStudentByParams = async (body, accessToken) => {
  const configuration = {
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
      Authorization: `Bearer ${accessToken}`,
    },
  };
  try {
    const response = await axios.post(
      `${config.api.invokeUrl}/contracts/add/discounts`,
      body,
      configuration
    );
    return { data: response.data };
  } catch (err) {
    console.log(err);
    return { err };
  }
};

export const removeDiscountFromStudentByParams = async (body, accessToken) => {
  const configuration = {
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
      Authorization: `Bearer ${accessToken}`,
    },
  };
  try {
    const response = await axios.post(
      `${config.api.invokeUrl}/contracts/remove/discounts`,
      body,
      configuration
    );
    return { data: response.data };
  } catch (err) {
    console.log(err);
    return { err };
  }
};

export const addPaymentToContractByParams = async (
  studentId,
  body,
  accessToken
) => {
  const configuration = {
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
      Authorization: `Bearer ${accessToken}`,
    },
  };
  try {
    const response = await axios.post(
      `${config.api.invokeUrl}/contracts/payment/add/${studentId}`,
      body,
      configuration
    );
    return { data: response.data };
  } catch (err) {
    console.log(err);
    return { err };
  }
};

export const addPartialPaymentToContractByParams = async (
  studentId,
  body,
  accessToken
) => {
  const configuration = {
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
      Authorization: `Bearer ${accessToken}`,
    },
  };
  try {
    const response = await axios.post(
      `${config.api.invokeUrl}/contracts/payment/add/partial/${studentId}`,
      body,
      configuration
    );
    return { data: response.data };
  } catch (err) {
    console.log(err);
    return { err };
  }
};

export const addSiblingDiscountToStudent = async (body, accessToken) => {
  const configuration = {
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
      Authorization: `Bearer ${accessToken}`,
    },
  };
  try {
    const response = await axios.post(
      `${config.api.invokeUrl}/contracts/add/sibling-discount`,
      body,
      configuration
    );
    return { data: response.data };
  } catch (err) {
    console.log(err);
    return { err };
  }
};

export const removeSiblingDiscountFromStudent = async (body, accessToken) => {
  const configuration = {
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
      Authorization: `Bearer ${accessToken}`,
    },
  };
  try {
    const response = await axios.post(
      `${config.api.invokeUrl}/contracts/remove/sibling-discount`,
      body,
      configuration
    );
    return { data: response.data };
  } catch (err) {
    console.log(err);
    return { err };
  }
};
