import React, { useContext, useEffect, useState } from "react";
import FormSelect from "../components/FormSelect";
import Back from "../../assets/back.png";
import Open from "../../assets/open.png";
import CreditCart from "../components/CreditCart";
import FormInput from "../components/FormInput";
import { useNavigate, useParams } from "react-router-dom";
import Logo from "../../assets/logo.png";
import { useInput } from "../../service/useInput";
import BaseModal from "../components/BaseModal";
import { formatMoneyToTL } from "../../utilization/MoneyOperations";
import { UserContext } from "../../context/UserContextProvider";
import FormCheckbox from "../components/FormCheckbox";
import ContractText from "../page-parts/contract/ContractText";
import ParentExpressConsent from "../page-parts/contract/ParentExpressConsent";
import SchoolExpressConsent from "../page-parts/contract/SchoolExpressConsent";
import { convertToDateString } from "../../utilization/DateOperations";
import { convertToTurkish } from "../../utilization/LanguageOperations";
import {
  addPartialPaymentToContractByParams,
  addPaymentToContractByParams,
  changeStudentContractStatusByStudentId,
  retrieveDiscountListByFeeId,
  retrieveStudentContractByStudentIdAndParentId,
} from "../../requests/ContractRequests";
import { NotificationManager } from "react-notifications";
import { retrieveStudentWithData } from "../../requests/StudentRequests";
import {
  retrieveTransactionInformationByProcessId,
  startContractPaymentTransaction,
} from "../../requests/TransactionRequests";

const posList = [
  { name: "İŞ POS" },
  { name: "AKBANK POS" },
  { name: "YAPI KREDI POS" },
  { name: "ZİRAAT POS" },
  { name: "DENİZ BONUS POS" },
  { name: "HALKBANK BONUS POS" },
];

const paymentMethodList = [
  {
    name: "NAKİT PEŞİN",
  },
  {
    name: "KREDİ KARTI PEŞİN",
  },
  {
    name: "KREDİ KARTI TAKSİT",
  },
  {
    name: "KMH",
  },
];

export default function Payment() {
  const navigate = useNavigate();
  const { id } = useParams();
  const userContext = useContext(UserContext);
  const { userInformation, schoolSettings } = userContext;
  const [startTransaction, setStartTransaction] = useState({});
  const [transaction, setTransaction] = useState(null);
  const [studentContract, setStudentContract] = useState(null);
  const [totalPrice, setTotalPrice] = useState(0);
  const [numberOfInstallments, setNumberOfInstallments] = useState(9);
  const [panel1, setPanel1] = useState(false);
  const [panel2, setPanel2] = useState(false);
  const [panel3, setPanel3] = useState(false);
  const [tab1, setTab1] = useState(false);
  const [tab2, setTab2] = useState(false);
  const [tab3, setTab3] = useState(false);
  const [tab4, setTab4] = useState(false);
  const [discountList, setDiscountList] = useState([]);
  const [selectedDiscountForCash, setSelectedDiscountForCash] = useState(null);
  const [selectedFeesForCash, setSelectedFeesForCash] = useState(null);
  const [cashMoney, setCashMoney] = useState(0);
  const [advance, setAdvance] = useState(50000);
  const [studentWithData, setStudentWithData] = useState([]);
  const [showTransactionModal, setShowTransactionModal] = useState(false);
  const [transactionModalData, setTransactionModalData] = useState(null);
  const [installmentStage, setInstallmentStage] = useState(0);
  const [showContractTextModal, setShowContractTextModal] = useState(false);
  const [isContractTextApproved, setIsContractTextApproved] = useState(false);
  const [showParentExpressConsentModal, setShowParentExpressConsentModal] =
    useState(false);
  const [isParentExpressConsentApproved, setIsParentExpressConsentApproved] =
    useState(false);
  const [showSchoolExpressConsentModal, setShowSchoolExpressConsentModal] =
    useState(false);
  const [isSchoolExpressConsentApproved, setIsSchoolExpressConsentApproved] =
    useState(false);
  let modalIntervalId;
  const [creditCardInputs, setCreditCardInputs] = useInput({
    ccNumber: "4546711234567894",
    ccCVC: "000",
    ccName: "Test Kullanıcı",
    ccMonth: "12",
    ccYear: "26",
  });
  const [useMoreThanOneCard, setUseMoreThanOneCard] = useState(false);
  const [manualPaymentType, setManualPaymentType] = useState("NAKİT PEŞİN");
  const [manualPaymentPOS, setManualPaymentPOS] = useState("İŞ POS");
  const [selectedFile, setSelectedFile] = useState(null);
  const [MIN_ADVANCE_AMOUNT, setMinAdvanceAmount] = useState(50000);
  const role = localStorage.getItem("userRole");
  const user = JSON.parse(localStorage.getItem("user"));
  const parentUser = JSON.parse(localStorage.getItem("parent_data"));

  useEffect(() => {
    const retrieveAndSetStudentWithData = async () => {
      const { data, err } = await retrieveStudentWithData(
        id,
        user?.access_token
      );
      if (err) {
        NotificationManager.error(
          "Öğrenci bilgisi sorgulanırken bir hata oluştu"
        );
        return;
      }
      setStudentWithData(data);
    };
    const changeAndSetStudentContractStatusByStudentId = async () => {
      const { data, err } = await changeStudentContractStatusByStudentId(
        { studentId: id, status: "CONTINUE" },
        user?.access_token
      );
      if (err) {
        NotificationManager.error(
          "Öğrenci sözleşmesinin durumu güncellenirken bir hata oluştu"
        );
        return;
      }
    };

    if (!id) return;
    retrieveAndSetStudentContractByStudentIdAndParentId();
    retrieveAndSetStudentWithData();
    changeAndSetStudentContractStatusByStudentId();
  }, []);

  useEffect(() => {
    if (!schoolSettings) return;
    const { minCash } = schoolSettings;
    setMinAdvanceAmount(minCash);
  }, [schoolSettings]);

  useEffect(() => {
    if (studentContract && Array.isArray(studentContract.contractFees)) {
      generalTotalFees();
    }
  }, [studentContract]);

  useEffect(() => {
    if (!discountList) return;
    if (!selectedFeesForCash) return;
    const mainDiscount = discountList.find((c) => c.rule === "CASH_DISCOUNT");
    if (!mainDiscount) return;
    setSelectedDiscountForCash(mainDiscount);
    setCashMoney((selectedFeesForCash.totalFees / 100) * mainDiscount.discount);
  }, [selectedFeesForCash, discountList]);

  useEffect(() => {
    const retrieveAndSetDiscountListByFeeId = async (feeId) => {
      const { data, err } = await retrieveDiscountListByFeeId(
        feeId,
        user?.access_token
      );
      if (err) {
        NotificationManager.error(
          "İndirim kalemleri sorgulanırken bir hata oluştu"
        );
        return;
      }
      setDiscountList(data);
    };
    if (studentContract && studentContract.contractFees) {
      const mainFee = studentContract.contractFees.find(
        (c) => c.schoolFee.groupNo === 1
      );
      retrieveAndSetDiscountListByFeeId(mainFee.schoolFee.id);
      setSelectedFeesForCash(mainFee);
    }
  }, [studentContract]);

  useEffect(() => {
    if (startTransaction?.ucdUrl) {
      setShowTransactionModal(true);
    }
  }, [startTransaction]);

  useEffect(() => {
    const retrieveAndSetTransactionByProcessId = async () => {
      const { data, err } = await retrieveTransactionInformationByProcessId(
        startTransaction.transactionId,
        user?.access_token
      );
      if (err) {
        NotificationManager.error(
          "Başlatılan ödeme sorgulanırken bir hata oluştu"
        );
        return;
      }
      setTransaction(data);
    };
    if (showTransactionModal) {
      modalIntervalId = setInterval(async () => {
        retrieveAndSetTransactionByProcessId();
      }, 3000);
    } else {
      if (modalIntervalId) {
        clearInterval(modalIntervalId);
      }
    }
    return () => {
      if (modalIntervalId) {
        clearInterval(modalIntervalId);
      }
    };
  }, [showTransactionModal]);

  useEffect(() => {
    if (transaction) {
      const { status } = transaction;
      if (status === "SUCCESS" || status === "ERROR") {
        setShowTransactionModal(false);
        nextStep(transactionModalData.method);
      }
    }
  }, [transaction]);

  useEffect(() => {
    if (!studentContract) return;
    const foundPayment = findAdvancePayment();
    if (foundPayment) {
      setInstallmentStage(1);
      setUseMoreThanOneCard(false);
    } else {
      setInstallmentStage(0);
    }
  }, [studentContract]);

  useEffect(() => {
    if (!studentContract) return;
    const { contract } = studentContract;
    const { status } = contract;
    if (status === "END") {
      navigate(`/parent-contract/${id}`);
    }
  }, [studentContract]);

  useEffect(() => {
    if (!studentContract) return;
    const { payments } = studentContract;
    if (payments.length > 0) {
      setIsContractTextApproved(true);
      setIsParentExpressConsentApproved(true);
      setIsSchoolExpressConsentApproved(true);
    }
  }, [studentContract]);

  useEffect(() => {
    if (role === "ADMIN") {
      setIsContractTextApproved(true);
      setIsParentExpressConsentApproved(true);
      setIsSchoolExpressConsentApproved(true);
    }
  }, [role]);

  useEffect(() => {
    const currentNumberOfInstallments = 9 - new Date().getMonth();
    if (currentNumberOfInstallments < 2) {
      setNumberOfInstallments(2);
    } else {
      setNumberOfInstallments(currentNumberOfInstallments);
    }
  }, []);

  const retrieveAndSetStudentContractByStudentIdAndParentId = async () => {
    const { data, err } = await retrieveStudentContractByStudentIdAndParentId(
      id,
      parentUser.user_id,
      user?.access_token
    );
    if (err) {
      NotificationManager.error(
        "Öğrenci sözleşme detayı sorgulanırken bir hata oluştu"
      );
      return;
    }
    setStudentContract(data);
  };

  const findAdvancePayment = () => {
    if (!studentContract) return;
    const { payments } = studentContract;
    const foundPayment = payments.find(
      (payment) => payment.paymentType === "PESİNAT"
    );
    return foundPayment;
  };

  const findAdvancePaymentList = () => {
    if (!studentContract) return [];
    const { payments } = studentContract;
    return payments.filter((payment) => payment.paymentType === "PESIN");
  };

  const findInstallmentPaymentList = () => {
    if (!studentContract) return [];
    const { payments } = studentContract;
    return payments.filter((payment) => payment.paymentType === "TAKSİT");
  };

  const findTransferPaymentList = () => {
    if (!studentContract) return [];
    const { payments } = studentContract;
    return payments.filter((payment) => payment.paymentType === "HAVALE");
  };

  const calculateRemainingInstallmentPrice = () => {
    let payedPrice = 0;
    const foundPayment = findAdvancePayment();
    if (foundPayment) {
      payedPrice += foundPayment.cash;
    }
    findAdvancePaymentList().forEach((payment) => {
      payedPrice += payment.cash;
    });
    findInstallmentPaymentList().forEach((payment) => {
      payedPrice += payment.installmentFee;
    });
    return totalPrice - payedPrice;
  };

  const calculateTotalTransferPrice = (statusType) => {
    let totalPrice = 0;
    findTransferPaymentList().forEach((payment) => {
      const { contractPaymentTransferItem } = payment;
      const { amount, status } = contractPaymentTransferItem;
      if (status === statusType) {
        totalPrice += amount;
      }
    });
    return totalPrice;
  };

  const checkCanShowManualPaymentButton = () => {
    if (Math.abs(calculateRemainingInstallmentPrice()) < 20) {
      return false;
    }
    if (manualPaymentType === "NAKİT PEŞİN") {
      if (advance >= MIN_ADVANCE_AMOUNT) {
        return true;
      }
    } else if (manualPaymentType === "KREDİ KARTI PEŞİN") {
      if (advance >= 10000) {
        return true;
      }
    } else if (manualPaymentType === "KREDİ KARTI TAKSİT") {
      if (useMoreThanOneCard) {
        if (advance >= 10000) {
          return true;
        }
      } else {
        return true;
      }
    } else if (manualPaymentType === "KMH") {
      return true;
    }
    return false;
  };

  const checkCanShowTransferPaymentButton = () => {
    if (advance > 0 && selectedFile) {
      return true;
    }
    return false;
  };

  const constructInstallmentOptionList = () => {
    const optionList = [];
    const currentNumberOfInstallments = 9 - new Date().getMonth();
    for (let i = 2; i <= currentNumberOfInstallments; i++) {
      optionList.push({
        name: i.toString(),
        value: i,
      });
    }
    return optionList;
  };

  const constructKMHList = () => {
    const kmhList = [];
    const installment =
      calculateRemainingInstallmentPrice() / numberOfInstallments;
    let totalDays = new Date().getDate();
    for (let i = 1; i <= numberOfInstallments; i++) {
      const date = new Date();
      const numberOfDays = new Date(
        date.getFullYear(),
        date.getMonth() + i,
        0
      ).getDate();
      totalDays += numberOfDays;
      date.setDate(totalDays);
      const payment = {
        number: i,
        timestamp: new Date(date).getTime(),
        dateString: convertToDateString(date),
        amount: installment,
      };
      kmhList.push(payment);
    }
    return kmhList;
  };

  const generalTotalFees = () => {
    let total = 0;
    for (let i = 0; i < studentContract.contractFees.length; i++) {
      total += studentContract.contractFees[i].totalFees;
    }
    setTotalPrice(total);
  };

  const tabloIndirimHesapla = (fees) => {
    if (!fees) return;
    if (fees.schoolFee.groupNo === 1 && (tab1 === true || tab3 === true)) {
      return formatMoneyToTL(
        fees.fees + (fees.fees * fees.taxRatio) / 100 - fees.totalFees
      );
    }
    return formatMoneyToTL(
      fees.fees + (fees.fees * fees.taxRatio) / 100 - fees.totalFees
    );
  };

  const tabloOdemeHesapla = (fees) => {
    if (fees.schoolFee.groupNo === 1 && (tab1 === true || tab3 === true)) {
      return formatMoneyToTL(fees.totalFees - cashMoney);
    }
    return formatMoneyToTL(fees.totalFees);
  };

  const feesTable = () => {
    return (
      <div className="table-responsive">
        <table className="table table-centered mb-0 rounded table-hover w-100">
          <thead className="thead-light">
            <tr>
              <th className="border-0">ÜCRET KALEMİ</th>
              <th className="border-0">BEDELİ</th>
              <th className="border-0">KDV ORANI</th>
              <th className="border-0">İNDİRİM</th>
              <th className="border-0">TOPLAM TUTAR</th>
            </tr>
          </thead>
          <tbody>
            {studentContract && Array.isArray(studentContract.contractFees)
              ? studentContract?.contractFees?.map((fees, key) => {
                  return (
                    <>
                      <tr key={key} style={{ cursor: "pointer" }}>
                        <td>
                          {fees.name}
                          <br />
                          {fees.explain}
                        </td>
                        <td>{formatMoneyToTL(fees.fees)} TL</td>
                        <td>%{fees.taxRatio}</td>
                        <td>{tabloIndirimHesapla(fees)} TL</td>
                        <td>
                          <b>{tabloOdemeHesapla(fees)} TL</b>
                        </td>
                      </tr>
                    </>
                  );
                })
              : null}
            <tr>
              <td></td>
              <td></td>
              <td>
                <b>GENEL TOPLAM:</b>
              </td>
              <td>
                <b>
                  {studentContract &&
                  Array.isArray(studentContract.contractFees)
                    ? formatMoneyToTL(totalPrice) + " TL"
                    : null}
                </b>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  };

  const managePanels = (no) => {
    if (no === 1) {
      setPanel1(true);
    } else {
      setPanel1(false);
    }
    if (no === 2) {
      setPanel2(true);
    } else {
      setPanel2(false);
    }
    if (no === 3) {
      setPanel3(true);
    } else {
      setPanel3(false);
    }
  };

  const manageTabs = (no) => {
    setPanel1(false);
    setPanel2(false);
    setPanel3(false);
    if (no === 1) {
      setTab1(true);
    } else {
      setTab1(false);
    }
    if (no === 2) {
      setTab2(true);
    } else {
      setTab2(false);
    }
    if (no === 3) {
      setTab3(true);
    } else {
      setTab3(false);
    }
    if (no === 4) {
      setTab4(true);
    } else {
      setTab4(false);
    }
  };

  const handleStartTransaction = async (installment, transactionData) => {
    const { data, err } = await startContractPaymentTransaction(
      {
        ...creditCardInputs,
        transactionPrice: (totalPrice - cashMoney).toFixed(2),
        installment,
        userId: parentUser.user_id,
        contractId: studentContract.contract.id,
      },
      user?.access_token
    );
    if (err) {
      NotificationManager.error(
        "Ödeme başlatılırken bir hata oluştu, lütfen tekrar deneyin"
      );
      return;
    }
    setStartTransaction(data);
    setTransactionModalData({ ...transactionData });
    setShowTransactionModal(true);
  };

  const handleStartCustomTransaction = async (
    price,
    installment,
    transactionData
  ) => {
    const { data, err } = await startContractPaymentTransaction(
      {
        ...creditCardInputs,
        transactionPrice: price.toFixed(2),
        installment,
        userId: parentUser.user_id,
        contractId: studentContract.contract.id,
      },
      user?.access_token
    );
    if (err) {
      NotificationManager.error(
        "Ödeme başlatılırken bir hata oluştu, lütfen tekrar deneyin"
      );
      return;
    }
    setStartTransaction(data);
    setTransactionModalData({ ...transactionData });
    setShowTransactionModal(true);
  };

  const convertFileToBase64 = async () => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(selectedFile);
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = reject;
    });
  };

  const handleAddPaymentToContract = async (studentId, body) => {
    const { data, err } = await addPaymentToContractByParams(
      studentId,
      body,
      user?.access_token
    );
    if (err) {
      NotificationManager.error("Ödeme eklenirken bir hata oluştu");
      return;
    }
  };

  const handleAddPartialPaymentToContract = async (studentId, body) => {
    const { data, err } = await addPartialPaymentToContractByParams(
      studentId,
      body,
      user?.access_token
    );
    if (err) {
      NotificationManager.error("Ödeme eklenirken bir hata oluştu");
      return;
    }
  };

  const nextStep = async (method) => {
    const body = [];
    let paymentType = "";
    if (method === 1) {
      paymentType = "PESIN";
      const bodyPart = {
        cash: totalPrice - cashMoney,
        installmentNo: null,
        installmentDate: null,
        installmentFee: null,
        paymentType,
        paidBy: parentUser.user_id,
      };
      body.push(bodyPart);
      await handleAddPaymentToContract(id, body);
    } else if (method === 2) {
      paymentType = "TAKSİT";
      if (installmentStage > 0) {
        if (useMoreThanOneCard) {
          const bodyPart = {
            cash: null,
            installmentNo: numberOfInstallments,
            installmentDate: new Date().getTime(),
            installmentFee: advance,
            paymentType,
            paidBy: parentUser.user_id,
          };
          body.push(bodyPart);
          await handleAddPartialPaymentToContract(id, body);
        } else {
          const bodyPart = {
            cash: null,
            installmentNo: numberOfInstallments,
            installmentDate: new Date().getTime(),
            installmentFee: calculateRemainingInstallmentPrice(),
            paymentType,
            paidBy: parentUser.user_id,
          };
          body.push(bodyPart);
          await handleAddPaymentToContract(id, body);
        }
      } else {
        const bodyPart = {
          cash: advance,
          installmentNo: null,
          installmentDate: null,
          installmentFee: null,
          paymentType: "PESİNAT",
          paidBy: parentUser.user_id,
        };
        body.push(bodyPart);
        await handleAddPartialPaymentToContract(id, body);
      }
    } else if (method === 3) {
      const base64Image = await convertFileToBase64();
      paymentType = "HAVALE";
      const bodyPart = {
        cash: advance,
        paymentType,
        paidBy: parentUser.user_id,
        transferDiscountAmount: cashMoney,
        contractPaymentTransferItem: {
          amount: advance,
          base64Image,
        },
      };
      body.push(bodyPart);
      await handleAddPartialPaymentToContract(id, body);
    } else if (method === 4) {
      if (
        manualPaymentType === "NAKİT PEŞİN" ||
        manualPaymentType === "KREDİ KARTI PEŞİN"
      ) {
        paymentType = "PESIN";
        const bodyPart = {
          cash: advance,
          installmentNo: null,
          installmentDate: null,
          installmentFee: null,
          paymentType,
          manualPaymentType:
            manualPaymentType === "NAKİT PEŞİN" ? "ELDEN" : manualPaymentPOS,
          paidBy: parentUser.user_id,
        };
        body.push(bodyPart);
        if (Math.abs(calculateRemainingInstallmentPrice() - advance) < 20) {
          await handleAddPaymentToContract(id, body);
        } else {
          await handleAddPartialPaymentToContract(id, body);
        }
      } else if (manualPaymentType === "KREDİ KARTI TAKSİT") {
        paymentType = "TAKSİT";
        if (useMoreThanOneCard) {
          const bodyPart = {
            cash: null,
            installmentNo: 0,
            installmentDate: new Date().getTime(),
            installmentFee: advance,
            paymentType,
            paidBy: parentUser.user_id,
          };
          body.push(bodyPart);
          await handleAddPartialPaymentToContract(id, body);
        } else {
          const bodyPart = {
            cash: null,
            installmentNo: 0,
            installmentDate: new Date().getTime(),
            installmentFee: calculateRemainingInstallmentPrice(),
            paymentType,
            paidBy: parentUser.user_id,
          };
          body.push(bodyPart);
          await handleAddPaymentToContract(id, body);
        }
      } else if (manualPaymentType === "KMH") {
        const kmhList = constructKMHList();
        paymentType = "KMH";
        for (const kmh of kmhList) {
          const bodyPart = {
            cash: null,
            installmentNo: kmh.number,
            installmentDate: kmh.timestamp,
            installmentFee: kmh.amount,
            paymentType,
            paidBy: parentUser.user_id,
          };
          body.push(bodyPart);
        }
        await handleAddPaymentToContract(id, body);
      }
    }
    retrieveAndSetStudentContractByStudentIdAndParentId();
  };

  const RenderTransferDetailsContent = () => {
    const { schoolTransferSettingsDtoList } = schoolSettings;

    return (
      <div className="">
        {schoolTransferSettingsDtoList.map((schoolTransferSettingsDto) => (
          <div className="my-2">
            <div
              style={{
                fontWeight: "bold",
                textDecoration: "underline",
                marginBottom: 5,
              }}
            >
              {schoolTransferSettingsDto.name}
            </div>
            <div style={{ fontWeight: "bold" }}>
              Hesap adı:{" "}
              <span style={{ fontWeight: "normal" }}>
                {schoolTransferSettingsDto.accountName}
              </span>
            </div>
            <div style={{ fontWeight: "bold" }}>
              Şube adı:{" "}
              <span style={{ fontWeight: "normal" }}>
                {schoolTransferSettingsDto.accountBranchName}
              </span>
            </div>
            <div style={{ fontWeight: "bold" }}>
              Hesap türü:{" "}
              <span style={{ fontWeight: "normal" }}>
                {schoolTransferSettingsDto.accountType}
              </span>
            </div>
            <div style={{ fontWeight: "bold" }}>
              IBAN:{" "}
              <span style={{ fontWeight: "normal" }}>
                {schoolTransferSettingsDto.iban}
              </span>
            </div>
          </div>
        ))}
      </div>
    );
  };

  const creditCart = () => {
    return (
      <div>
        <div className="alert alert-info">
          Kredi kartı peşin
          <div className="row" style={{ paddingLeft: "10px" }}>
            <div className="col alert alert-light mt-3">
              Peşin ödemelerde eğitim bedeli üzerinden %
              {selectedDiscountForCash
                ? selectedDiscountForCash.discount
                : null}{" "}
              indirim uygulanmaktadır. İndirim miktarı:{" "}
              {formatMoneyToTL(cashMoney)} TL
            </div>
            <div className="col-auto mt-3" onClick={() => manageTabs(1)}>
              <div style={{ width: 60, marginTop: 11 }}>
                <img
                  src={Open}
                  alt="open"
                  style={{ width: "100%", cursor: "pointer" }}
                />
              </div>
            </div>
          </div>
          {tab1 ? (
            <div className="alert alert-light mt-3">
              <h5>
                Toplam ödenecek tutar:
                {studentContract && Array.isArray(studentContract.contractFees)
                  ? " " + formatMoneyToTL(totalPrice - cashMoney)
                  : null}{" "}
                TL
              </h5>
              <br />
              {panel1 ? (
                <CreditCart
                  inputs={creditCardInputs}
                  setInputs={setCreditCardInputs}
                />
              ) : null}

              {!panel1 ? (
                <div className="mx-2">
                  <button
                    className="btn btn-success text-light "
                    onClick={() => managePanels(1)}
                  >
                    Peşin olarak ödemek istiyorum
                  </button>
                </div>
              ) : (
                <div className="d-flex justify-content-end">
                  <div className="mx-2">
                    <button
                      className="btn btn-success text-light "
                      onClick={() => {
                        handleStartTransaction(1, { method: 1 });
                      }}
                    >
                      Ödeme yap
                    </button>
                  </div>
                </div>
              )}
            </div>
          ) : null}
        </div>
      </div>
    );
  };

  const RenderStageContent = () => {
    if (installmentStage > 0) {
      return (
        <div>
          <div className="">
            <h5>
              Aşama 2: Kredili ödeme ekranı{" "}
              {useMoreThanOneCard
                ? formatMoneyToTL(advance)
                : formatMoneyToTL(calculateRemainingInstallmentPrice())}{" "}
              TL ({numberOfInstallments} taksit)
            </h5>
            <p>Geriye kalan ücreti taksitle ödeyebilirsiniz.</p>
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <div className="">
            <h5>Aşama 1: Peşinat ödeme ekranı {formatMoneyToTL(advance)} TL</h5>
            <p>İlk olarak peşinatı ödemeniz gerekmektedir.</p>
          </div>
        </div>
      );
    }
  };

  const creditCartInstallment = () => {
    return (
      <div className="alert alert-info">
        Kredi kartı taksit
        <div className="row" style={{ paddingLeft: "10px" }}>
          <div className="col alert alert-light mt-3">
            Taksit miktarı en fazla 10 ay olabilir.
          </div>
          <div className="col-auto mt-3" onClick={() => manageTabs(2)}>
            <div style={{ width: 60, marginTop: 11 }}>
              <img
                src={Open}
                alt="open"
                style={{ width: "100%", cursor: "pointer" }}
              />
            </div>
          </div>
        </div>
        {tab2 ? (
          <div className="alert alert-light mt-3">
            <h5>
              Toplam ödenecek tutar:
              {" " + formatMoneyToTL(calculateRemainingInstallmentPrice())} TL
            </h5>
            <br />
            <div className="row">
              {installmentStage === 0 ? (
                <FormInput
                  name="description"
                  text={`Peşinat (min ${formatMoneyToTL(
                    MIN_ADVANCE_AMOUNT
                  )} TL)`}
                  placeholder={`Min ${formatMoneyToTL(
                    MIN_ADVANCE_AMOUNT
                  )} TL olmalı`}
                  type="number"
                  col="4"
                  value={advance}
                  onChange={(e) => {
                    if (
                      parseFloat(e.target.value) <=
                      calculateRemainingInstallmentPrice()
                    ) {
                      setAdvance(parseFloat(e.target.value));
                    }
                  }}
                />
              ) : null}
            </div>
            <br />
            {installmentStage === 0 && advance < MIN_ADVANCE_AMOUNT ? null : (
              <div
                className="table-responsive"
                style={{ paddingBottom: "25px" }}
              >
                <table className="table table-centered mb-0 rounded table-hover w-100">
                  <thead className="thead-light">
                    <tr>
                      <th className="border-0">TÜR</th>
                      <th className="border-0">DETAY</th>
                      <th className="border-0">TUTAR</th>
                      <th className="border-0">DURUM</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Peşin ödeme</td>
                      <td>Tek seferde</td>
                      <td>
                        {formatMoneyToTL(
                          installmentStage > 0
                            ? findAdvancePayment()?.cash
                            : advance
                        ) + " TL"}
                      </td>
                      <td>{installmentStage > 0 ? "ALINDI" : null}</td>
                    </tr>
                    {findInstallmentPaymentList().map((ins, key) => (
                      <tr key={key} style={{ cursor: "pointer" }}>
                        <td>Taksitli ödeme</td>
                        <td>
                          {new Date(ins.installmentDate).toLocaleDateString(
                            "TR"
                          )}
                        </td>
                        <td>{formatMoneyToTL(ins.installmentFee) + " TL"}</td>
                        <td>ALINDI</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}

            {installmentStage > 0 ? (
              <>
                <div className="row align-items-center">
                  {calculateRemainingInstallmentPrice() >= 10000 ? (
                    <FormCheckbox
                      name="description"
                      text="Birden fazla kart kullanmak istiyorum"
                      col="6"
                      value={useMoreThanOneCard}
                      onClick={(e) => {
                        if (useMoreThanOneCard) {
                          setUseMoreThanOneCard(false);
                        } else {
                          setUseMoreThanOneCard(true);
                        }
                      }}
                    />
                  ) : null}
                </div>
                <div className="row">
                  <FormSelect
                    name="description"
                    text="Taksit sayısı"
                    placeholder=""
                    type="number"
                    col="4"
                    required
                    value={numberOfInstallments}
                    onChange={(e) =>
                      setNumberOfInstallments(parseFloat(e.target.value))
                    }
                    data={constructInstallmentOptionList()}
                    valuekey="value"
                    textkey="name"
                  />
                  {useMoreThanOneCard ? (
                    <FormInput
                      name="description"
                      text="Tutar"
                      placeholder="Tutar"
                      type="number"
                      col="4"
                      value={advance}
                      onChange={(e) => {
                        if (
                          parseFloat(e.target.value) <=
                          calculateRemainingInstallmentPrice()
                        ) {
                          setAdvance(parseFloat(e.target.value));
                        }
                      }}
                    />
                  ) : null}
                </div>
                <div
                  className="table-responsive"
                  style={{ paddingBottom: "25px" }}
                >
                  <table className="table table-centered mb-0 rounded table-hover w-100">
                    <thead className="thead-light">
                      <tr>
                        <th className="border-0">TÜR</th>
                        <th className="border-0">DETAY</th>
                        <th className="border-0">TUTAR</th>
                        <th className="border-0">DURUM</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          {useMoreThanOneCard ? "Kısım ödeme" : "Kalan ödeme"}
                        </td>
                        <td>{numberOfInstallments} taksit</td>
                        <td>
                          {useMoreThanOneCard
                            ? formatMoneyToTL(advance)
                            : formatMoneyToTL(
                                calculateRemainingInstallmentPrice()
                              )}{" "}
                          TL
                        </td>
                        <td></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </>
            ) : null}

            {panel2 &&
            ((installmentStage === 0 && advance >= MIN_ADVANCE_AMOUNT) ||
              (installmentStage > 0 && advance >= 10000)) ? (
              <>
                {RenderStageContent()}
                <CreditCart
                  inputs={creditCardInputs}
                  setInputs={setCreditCardInputs}
                />
              </>
            ) : null}

            <br />
            {!panel2 ? (
              (installmentStage === 0 && advance >= MIN_ADVANCE_AMOUNT) ||
              (installmentStage > 0 &&
                useMoreThanOneCard &&
                advance >= 10000) ||
              (installmentStage > 0 && !useMoreThanOneCard) ? (
                <div className="mx-2">
                  <button
                    className="btn btn-success text-light "
                    onClick={() => managePanels(2)}
                  >
                    {installmentStage === 0
                      ? "Peşinatı ödemek istiyorum"
                      : "Kalan ödemeyi taksitle ödemek istiyorum"}
                  </button>
                </div>
              ) : null
            ) : (installmentStage === 0 && advance >= MIN_ADVANCE_AMOUNT) ||
              (installmentStage > 0 &&
                useMoreThanOneCard &&
                advance >= 10000) ||
              (installmentStage > 0 && !useMoreThanOneCard) ? (
              <div className="d-flex justify-content-end">
                <div className="mx-2">
                  <button
                    className="btn btn-success text-light "
                    onClick={() => {
                      if (installmentStage > 0) {
                        if (useMoreThanOneCard) {
                          handleStartCustomTransaction(
                            advance,
                            numberOfInstallments,
                            {
                              method: 2,
                            }
                          );
                        } else {
                          handleStartCustomTransaction(
                            calculateRemainingInstallmentPrice(),
                            numberOfInstallments,
                            {
                              method: 2,
                            }
                          );
                        }
                      } else {
                        handleStartCustomTransaction(advance, 1, {
                          method: 2,
                        });
                      }
                    }}
                  >
                    Ödeme yap
                  </button>
                </div>
              </div>
            ) : null}
          </div>
        ) : null}
      </div>
    );
  };

  const RenderTransferContent = () => {
    return (
      <div>
        <div className="alert alert-info">
          Havale / EFT Peşin
          <div className="row" style={{ paddingLeft: "10px" }}>
            <div className="col alert alert-light mt-3">
              Peşin ödemelerde eğitim bedeli üzerinden %
              {selectedDiscountForCash
                ? selectedDiscountForCash.discount
                : null}{" "}
              indirim uygulanmaktadır. İndirim miktarı:{" "}
              {formatMoneyToTL(cashMoney)} TL
            </div>
            <div className="col-auto mt-3" onClick={() => manageTabs(3)}>
              <div style={{ width: 60, marginTop: 11 }}>
                <img
                  src={Open}
                  alt="open"
                  style={{ width: "100%", cursor: "pointer" }}
                />
              </div>
            </div>
          </div>
          {tab3 ? (
            <div className="alert alert-light mt-3">
              <h5>
                Toplam ödenecek tutar:
                {studentContract && Array.isArray(studentContract.contractFees)
                  ? " " + formatMoneyToTL(totalPrice - cashMoney)
                  : null}{" "}
                TL
              </h5>
              {RenderTransferDetailsContent()}
              <br />
              {findTransferPaymentList().length > 0 ? (
                <>
                  <div
                    className="table-responsive"
                    style={{ paddingBottom: "25px" }}
                  >
                    <table className="table table-centered mb-0 rounded table-hover w-100">
                      <thead className="thead-light">
                        <tr>
                          <th className="border-0">TÜR</th>
                          <th className="border-0">DETAY</th>
                          <th className="border-0">TUTAR</th>
                          <th className="border-0">DURUM</th>
                        </tr>
                      </thead>
                      <tbody>
                        {findTransferPaymentList().map((payment, key) => (
                          <tr key={key} style={{ cursor: "pointer" }}>
                            <td>Havale ödeme</td>
                            <td>Dosya yükleme</td>
                            <td>
                              {formatMoneyToTL(
                                payment.contractPaymentTransferItem.amount
                              ) + " TL"}
                            </td>
                            <td
                              className={`${
                                payment.contractPaymentTransferItem.status ===
                                "APPROVED"
                                  ? "text-success"
                                  : payment.contractPaymentTransferItem
                                      .status === "REJECTED"
                                  ? "text-danger"
                                  : ""
                              }`}
                            >
                              {convertToTurkish(
                                payment.contractPaymentTransferItem.status
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <div
                    className="table-responsive"
                    style={{ paddingBottom: "25px" }}
                  >
                    <table className="table table-centered mb-0 rounded table-hover w-100">
                      <thead className="thead-light">
                        <tr>
                          <th className="border-0">SON DURUM</th>
                          <th className="border-0"></th>
                          <th className="border-0"></th>
                          <th className="border-0"></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Bekleyen tutar</td>
                          <td>
                            <b>
                              {formatMoneyToTL(
                                calculateTotalTransferPrice("WAITING")
                              ) + " TL"}
                            </b>
                          </td>
                          <td>Onaylanan tutar</td>
                          <td>
                            <b>
                              {formatMoneyToTL(
                                calculateTotalTransferPrice("APPROVED")
                              ) + " TL"}
                            </b>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div className="my-2"></div>
                  </div>
                </>
              ) : null}

              <div className="row">
                <FormInput
                  name="description"
                  text="Tutar"
                  placeholder=""
                  type="number"
                  col="4"
                  value={advance}
                  onChange={(e) => {
                    if (
                      parseFloat(e.target.value) <=
                      calculateRemainingInstallmentPrice()
                    ) {
                      setAdvance(parseFloat(e.target.value));
                    }
                  }}
                />
                <FormInput
                  text="Dekont"
                  placeholder=""
                  type={"file"}
                  col="8"
                  onClick={(e) => {
                    e.target.value = "";
                  }}
                  onChange={(e) => {
                    setSelectedFile(e.target.files[0]);
                  }}
                />
              </div>
              {checkCanShowTransferPaymentButton() ? (
                <div className="d-flex justify-content-end">
                  <div className="mx-2">
                    <button
                      className="btn btn-success text-light "
                      onClick={() => {
                        nextStep(3);
                        setSelectedFile(null);
                      }}
                    >
                      Dekont yükle
                    </button>
                  </div>
                </div>
              ) : null}
            </div>
          ) : null}
        </div>
      </div>
    );
  };

  const RenderManuelPaymentByPaymentType = () => {
    if (manualPaymentType === "NAKİT PEŞİN") {
      return (
        <>
          <FormInput
            name="description"
            text={`Peşinat (min ${formatMoneyToTL(MIN_ADVANCE_AMOUNT)} TL)`}
            placeholder={`Min ${formatMoneyToTL(MIN_ADVANCE_AMOUNT)} TL olmalı`}
            type="number"
            col="4"
            value={advance}
            onChange={(e) => {
              if (
                parseFloat(e.target.value) <=
                calculateRemainingInstallmentPrice()
              ) {
                setAdvance(parseFloat(e.target.value));
              }
            }}
          />
        </>
      );
    } else if (manualPaymentType === "KREDİ KARTI PEŞİN") {
      return (
        <>
          <FormSelect
            name="description"
            text="POS Seç"
            placeholder=""
            type="text"
            col="4"
            required
            value={manualPaymentPOS}
            onChange={(e) => setManualPaymentPOS(e.target.value)}
            data={posList}
            valuekey="name"
            textkey="name"
          />
          <FormInput
            name="description"
            text="Tutar"
            placeholder=""
            type="number"
            col="4"
            value={advance}
            onChange={(e) => {
              if (
                parseFloat(e.target.value) <=
                calculateRemainingInstallmentPrice()
              ) {
                setAdvance(parseFloat(e.target.value));
              }
            }}
          />
        </>
      );
    } else if (manualPaymentType === "KREDİ KARTI TAKSİT") {
      return (
        <>
          <div className="row align-items-center">
            {calculateRemainingInstallmentPrice() >= 10000 ? (
              <FormCheckbox
                name="description"
                text="Birden fazla kart kullanmak istiyorum"
                col="6"
                value={useMoreThanOneCard}
                onClick={(e) => {
                  if (useMoreThanOneCard) {
                    setUseMoreThanOneCard(false);
                  } else {
                    setUseMoreThanOneCard(true);
                  }
                }}
              />
            ) : null}
          </div>
          <div className="row">
            {useMoreThanOneCard ? (
              <FormInput
                name="description"
                text="Tutar"
                placeholder="Tutar"
                type="number"
                col="4"
                value={advance}
                onChange={(e) => {
                  if (
                    parseFloat(e.target.value) <=
                    calculateRemainingInstallmentPrice()
                  ) {
                    setAdvance(parseFloat(e.target.value));
                  }
                }}
              />
            ) : null}
          </div>
        </>
      );
    } else if (manualPaymentType === "KMH") {
      return (
        <>
          <FormSelect
            name="description"
            text="Taksit sayısı"
            placeholder=""
            type="number"
            col="4"
            required
            value={numberOfInstallments}
            onChange={(e) =>
              setNumberOfInstallments(parseFloat(e.target.value))
            }
            data={constructInstallmentOptionList()}
            valuekey="value"
            textkey="name"
          />
          <div className="table-responsive" style={{ paddingBottom: "25px" }}>
            <table className="table table-centered mb-0 rounded table-hover w-100">
              <thead className="thead-light">
                <tr>
                  <th className="border-0">NO</th>
                  <th className="border-0">DETAY</th>
                  <th className="border-0">TUTAR</th>
                </tr>
              </thead>
              <tbody>
                {constructKMHList().map((kmh, index) => (
                  <tr key={index}>
                    <td>{kmh.number}</td>
                    <td>{kmh.dateString}</td>
                    <td>{formatMoneyToTL(kmh.amount)} TL</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </>
      );
    }
  };

  const RenderManualPaymentContent = () => {
    return (
      <div>
        <div className="alert alert-info">
          Personel manuel ödeme
          <div className="row" style={{ paddingLeft: "10px" }}>
            <div className="col alert alert-light mt-3">
              Buradan okula gelen velinin ödemesini oluşturabilirsiniz.
            </div>
            <div className="col-auto mt-3" onClick={() => manageTabs(4)}>
              <div style={{ width: 60, marginTop: 11 }}>
                <img
                  src={Open}
                  alt="open"
                  style={{ width: "100%", cursor: "pointer" }}
                />
              </div>
            </div>
          </div>
          {tab4 ? (
            <div className="alert alert-light mt-3">
              <h5>
                Toplam ödenecek tutar:{" "}
                {formatMoneyToTL(calculateRemainingInstallmentPrice())} TL
              </h5>
              <br />
              {findAdvancePaymentList().length > 0 ||
              findInstallmentPaymentList().length > 0 ? (
                <div
                  className="table-responsive"
                  style={{ paddingBottom: "25px" }}
                >
                  <table className="table table-centered mb-0 rounded table-hover w-100">
                    <thead className="thead-light">
                      <tr>
                        <th className="border-0">TÜR</th>
                        <th className="border-0">DETAY</th>
                        <th className="border-0">TUTAR</th>
                        <th className="border-0">DURUM</th>
                      </tr>
                    </thead>
                    <tbody>
                      {findAdvancePaymentList().map((payment, key) => (
                        <tr key={key}>
                          <td>Peşin ödeme</td>
                          <td>Tek seferde</td>
                          <td>{formatMoneyToTL(payment.cash) + " TL"}</td>
                          <td>{"ALINDI"}</td>
                        </tr>
                      ))}
                      {findInstallmentPaymentList().map((payment, key) => (
                        <tr key={key} style={{ cursor: "pointer" }}>
                          <td>Taksitli ödeme</td>
                          <td>
                            {convertToDateString(payment.installmentDate)}
                          </td>
                          <td>
                            {formatMoneyToTL(payment.installmentFee) + " TL"}
                          </td>
                          <td>ALINDI</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              ) : null}
              <div className="row">
                <FormSelect
                  name="branchId"
                  text="Ödeme Şekli Seç"
                  placeholder=""
                  type="text"
                  col="12"
                  required
                  value=""
                  onChange={(e) => setManualPaymentType(e.target.value)}
                  id={"inputs.id"}
                  data={paymentMethodList}
                  valuekey="name"
                  textkey="name"
                />
                {RenderManuelPaymentByPaymentType()}
              </div>
              {checkCanShowManualPaymentButton() ? (
                <div className="d-flex justify-content-end">
                  <div className="mx-2">
                    <button
                      className="btn btn-success text-light "
                      onClick={() => {
                        const answer = window.confirm(
                          "Ödeme eklemek istediğinize emin misiniz?"
                        );
                        if (answer) {
                          nextStep(4);
                        }
                      }}
                    >
                      Ödeme yap
                    </button>
                  </div>
                </div>
              ) : null}
            </div>
          ) : null}
        </div>
      </div>
    );
  };

  const RenderPaymentMethodContent = () => {
    if (role === "ADMIN") {
      return <>{RenderManualPaymentContent()}</>;
    } else {
      return (
        <>
          {installmentStage === 0 && findTransferPaymentList().length === 0
            ? creditCart()
            : null}
          {findTransferPaymentList().length === 0
            ? creditCartInstallment()
            : null}
          {installmentStage === 0 ? RenderTransferContent() : null}
        </>
      );
    }
  };

  const RenderApproveContractContent = () => {
    if (role === "ADMIN") {
      return null;
    }
    return (
      <div className="mt-3 mb-3">
        <FormCheckbox
          name="description"
          text="Sözleşme metnini onaylıyorum"
          col="12"
          value={isContractTextApproved}
          onClick={(e) => {
            if (!isContractTextApproved) {
              setShowContractTextModal(true);
            }
          }}
        />
        <FormCheckbox
          name="description"
          text="Öğrenci velisine ait açık rıza beyanını onaylıyorum"
          col="12"
          value={isParentExpressConsentApproved}
          onClick={(e) => {
            if (!isParentExpressConsentApproved) {
              setShowParentExpressConsentModal(true);
            }
          }}
        />
        <FormCheckbox
          name="description"
          text="Türk eğitim derneğine kişisel verilerin korunması kanunu kapsamında açık rıza beyanını onaylıyorum"
          col="12"
          value={isSchoolExpressConsentApproved}
          onClick={(e) => {
            if (!isSchoolExpressConsentApproved) {
              setShowSchoolExpressConsentModal(true);
            }
          }}
        />
      </div>
    );
  };

  const RenderTransactionModalContent = () => {
    return (
      <BaseModal
        modalIsOpen={showTransactionModal}
        contentLabel={"Ödeme ekranı"}
      >
        <div className="" style={{ height: 600 }}>
          <iframe className="w-100 h-100" src={startTransaction?.ucdUrl} />
        </div>
      </BaseModal>
    );
  };

  const RenderShowContractTextContent = () => {
    return (
      <BaseModal
        modalIsOpen={showContractTextModal}
        contentLabel={"Ücret kalemi bilgileri"}
      >
        <div className="col-12">
          <ContractText />
        </div>
        <div className="d-flex flex-row justify-content-end">
          <div className="mx-2">
            <button
              className="btn btn-success text-light "
              onClick={() => {
                setIsContractTextApproved(true);
                setShowContractTextModal(false);
              }}
            >
              Onaylıyorum
            </button>
          </div>
        </div>
      </BaseModal>
    );
  };

  const RenderShowParentExpressConsentContent = () => {
    return (
      <BaseModal
        modalIsOpen={showParentExpressConsentModal}
        contentLabel={"Ücret kalemi bilgileri"}
        // closeModal={() => {
        //   setShowSelectedFeeModal(false);
        // }}
      >
        <div className="col-12">
          <ParentExpressConsent student={studentWithData} />
        </div>
        <div className="d-flex flex-row justify-content-end">
          <div className="mx-2">
            <button
              className="btn btn-success text-light "
              onClick={() => {
                setIsParentExpressConsentApproved(true);
                setShowParentExpressConsentModal(false);
              }}
            >
              Onaylıyorum
            </button>
          </div>
        </div>
      </BaseModal>
    );
  };

  const RenderShowSchoolExpressConsentContent = () => {
    return (
      <BaseModal
        modalIsOpen={showSchoolExpressConsentModal}
        contentLabel={"Ücret kalemi bilgileri"}
        // closeModal={() => {
        //   setShowSelectedFeeModal(false);
        // }}
      >
        <div className="col-12">
          <SchoolExpressConsent student={studentWithData} />
        </div>
        <div className="d-flex flex-row justify-content-end">
          <div className="mx-2">
            <button
              className="btn btn-success text-light "
              onClick={() => {
                setIsSchoolExpressConsentApproved(true);
                setShowSchoolExpressConsentModal(false);
              }}
            >
              Onaylıyorum
            </button>
          </div>
        </div>
      </BaseModal>
    );
  };

  return (
    <div className={`${userInformation.role === "ADMIN" ? "content" : ""}`}>
      {userInformation.role === "ADMIN" ? (
        <div className="" style={{ width: 260 }} />
      ) : null}
      {RenderTransactionModalContent()}
      {RenderShowContractTextContent()}
      {RenderShowParentExpressConsentContent()}
      {RenderShowSchoolExpressConsentContent()}
      <div className="container">
        <div className="row">
          <div className="col-12 col-xl-12">
            <div className="card card-body border-0 shadow mb-4">
              <div className="w-100 d-flex justify-content-center">
                <img
                  src={Logo}
                  alt="atekent_logo"
                  style={{ width: "150px" }}
                  className="p-3"
                />
              </div>
              <div className="row">
                <div className="col-auto">
                  <div
                    className="d-inline-flex"
                    onClick={() => navigate(`/parent-detail/${id}`)}
                  >
                    <img
                      src={Back}
                      alt="back"
                      style={{ width: "30px", cursor: "pointer" }}
                    />
                  </div>
                </div>
                <div className="col">
                  <h2 className="h5 mb-4">
                    {studentWithData ? studentWithData.name : ""}{" "}
                    {studentWithData ? studentWithData.lastname : ""} için ödeme
                    seçenekleri
                  </h2>
                </div>
              </div>
              {feesTable()}
              {RenderApproveContractContent()}
              {isContractTextApproved &&
              isParentExpressConsentApproved &&
              isSchoolExpressConsentApproved
                ? RenderPaymentMethodContent()
                : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
