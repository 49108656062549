import Login from "../app/auth/Login";
import { IconDashboard, IconUsers } from "../app/components/Icons";
import Common from "../admin/Common";
import Contract from "../admin/Contract";
import Report from "../admin/Report";
import Student from "../admin/Student";
import SystemUser from "../admin/SystemUser";
import Parent from "../admin/Parent";
import SchoolQuotaScreen from "../admin/SchoolQuotaScreen";
import Letter from "../app/pages/Letter";
import ParentContract from "../app/pages/Contract";
import ParentStudent from "../app/pages/ParentStudent";
import Payment from "../app/pages/Payment";
import StudentContractDetail from "../app/pages/StudentContractDetail";
import ContractMainPage from "../app/ContractMainPage";
import ContractPDFScreen from "../admin/page-parts/pdf-preview/ContractPDFScreen";
import ManuelPaymentDetail from "../admin/ManuelPaymentDetail";
import ReadExcelScreen from "../admin/ReadExcelScreen";
import TransferApprovalsScreen from "../admin/TransferApprovalsScreen";
import ContractLogsScreen from "../admin/ContractLogsScreen";
import SchoolSettingsScreen from "../admin/SchoolSettingsScreen";

export const adminRoutes = [
  {
    path: "/",
    name: "Ana sayfa",
    icon: IconDashboard,
    component: Student /*Main*/,
    menu: true,
    sort: 1,
    sidePanelVisible: true,
    fullScreen: false,
  },
  {
    path: "/fees-discounts",
    name: "Tanımlamalar",
    icon: IconDashboard,
    component: Common,
    menu: true,
    sort: 2,
    sidePanelVisible: true,
    fullScreen: false,
  },
  {
    path: "/contracts",
    name: "Sözleşmeler",
    icon: IconDashboard,
    component: Contract,
    menu: true,
    sort: 3,
    sidePanelVisible: true,
    fullScreen: false,
  },
  {
    path: "/reports",
    name: "Raporlar",
    icon: IconDashboard,
    component: Report,
    menu: true,
    sort: 4,
    sidePanelVisible: true,
    fullScreen: false,
  },
  {
    path: "/users",
    name: "Kullanıcılar",
    icon: IconDashboard,
    component: SystemUser,
    menu: true,
    sort: 5,
    sidePanelVisible: true,
    fullScreen: false,
  },
  {
    path: "/quotas",
    name: "Kontenjanlar",
    icon: IconDashboard,
    component: SchoolQuotaScreen,
    menu: true,
    sort: 6,
    sidePanelVisible: true,
    fullScreen: true,
  },
  {
    path: "/transfer-approvals",
    name: "Havale onaylarım",
    icon: IconDashboard,
    component: TransferApprovalsScreen,
    menu: true,
    sort: 7,
    sidePanelVisible: true,
    fullScreen: true,
  },
  {
    path: "/contract-logs",
    name: "Sözleşme kayıtları",
    icon: IconDashboard,
    component: ContractLogsScreen,
    menu: true,
    sort: 8,
    sidePanelVisible: true,
    fullScreen: true,
  },
  {
    path: "/excel",
    name: "Veri aktar",
    icon: IconDashboard,
    component: ReadExcelScreen,
    menu: true,
    sort: 9,
    sidePanelVisible: true,
    fullScreen: true,
  },
  {
    path: "/school-settings",
    name: "Kurum ayarları",
    icon: IconDashboard,
    component: SchoolSettingsScreen,
    menu: true,
    sort: 10,
    sidePanelVisible: true,
    fullScreen: true,
  },
  {
    path: "/parent",
    name: "Dashboard",
    icon: IconUsers,
    component: Parent,
    menu: true,
    sort: 0,
    sidePanelVisible: false,
    fullScreen: true,
  },
  {
    path: "/student",
    name: "Öğrenciler",
    icon: IconDashboard,
    component: Student,
    menu: true,
    sort: 2,
    sidePanelVisible: false,
    fullScreen: false,
  },

  {
    path: "/letter",
    name: "",
    icon: IconUsers,
    component: Letter,
    menu: true,
    sort: 9,
    sidePanelVisible: false,
    fullScreen: true,
  },
  {
    path: "/parent-letter",
    name: "",
    icon: IconUsers,
    component: Letter,
    menu: true,
    sort: 9,
    sidePanelVisible: false,
    fullScreen: true,
  },
  {
    path: "/parent-contract/:id",
    name: "",
    icon: IconUsers,
    component: ParentContract,
    menu: true,
    sort: 9,
    sidePanelVisible: false,
    fullScreen: true,
  },
  {
    path: "/parent-contract/:id/pdf",
    name: "",
    icon: IconUsers,
    component: ContractPDFScreen,
    menu: true,
    sort: 9,
    sidePanelVisible: false,
    fullScreen: true,
  },
  {
    path: "/parent-student",
    name: "",
    icon: IconUsers,
    component: ParentStudent,
    menu: true,
    sort: 9,
    sidePanelVisible: false,
    fullScreen: true,
  },
  {
    path: "/parent-payment/:id",
    name: "",
    icon: IconUsers,
    component: Payment,
    menu: true,
    sort: 9,
    sidePanelVisible: false,
    fullScreen: true,
  },
  {
    path: "/parent-detail/:id",
    name: "",
    icon: IconUsers,
    component: StudentContractDetail,
    menu: true,
    sort: 9,
    sidePanelVisible: false,
    fullScreen: true,
  },
  {
    path: "/manual-payment/:id",
    name: "",
    icon: IconDashboard,
    component: ManuelPaymentDetail,
    menu: true,
    sort: 9,
    sidePanelVisible: false,
    fullScreen: false,
  },
];
export const parentRoutes = [
  {
    path: "/",
    name: "Ana sayfa",
    icon: IconDashboard,
    component: ContractMainPage,
    menu: true,
    sort: 2,
    sidePanelVisible: true,
    fullScreen: false,
  },
  {
    path: "/student",
    name: "Öğrenciler",
    icon: IconDashboard,
    component: Student,
    menu: true,
    sort: 2,
    sidePanelVisible: false,
    fullScreen: false,
  },
  {
    path: "/letter",
    name: "",
    icon: IconUsers,
    component: Letter,
    menu: true,
    sort: 9,
    sidePanelVisible: false,
    fullScreen: true,
  },
  {
    path: "/parent-letter",
    name: "",
    icon: IconUsers,
    component: Letter,
    menu: true,
    sort: 9,
    sidePanelVisible: false,
    fullScreen: true,
  },
  {
    path: "/parent-contract/:id",
    name: "",
    icon: IconUsers,
    component: ParentContract,
    menu: true,
    sort: 9,
    sidePanelVisible: false,
    fullScreen: true,
  },
  {
    path: "/parent-student",
    name: "",
    icon: IconUsers,
    component: ParentStudent,
    menu: true,
    sort: 9,
    sidePanelVisible: false,
    fullScreen: true,
  },
  {
    path: "/parent-payment/:id",
    name: "",
    icon: IconUsers,
    component: Payment,
    menu: true,
    sort: 9,
    sidePanelVisible: false,
    fullScreen: true,
  },
  {
    path: "/parent-detail/:id",
    name: "",
    icon: IconUsers,
    component: StudentContractDetail,
    menu: true,
    sort: 9,
    sidePanelVisible: false,
    fullScreen: true,
  },
];
export const loginRoutes = [
  {
    path: "/",
    name: "Dashboard",
    icon: IconUsers,
    component: Login,
    menu: true,
    sort: 0,
    sidePanelVisible: false,
    fullScreen: true,
  },
];
