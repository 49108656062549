import axios from "axios";
import config from "../config/config.json";

export const startContractPaymentTransaction = async (body, accessToken) => {
  const configuration = {
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
      Authorization: `Bearer ${accessToken}`,
    },
  };
  try {
    const response = await axios.post(
      `${config.api.invokeUrl}/transactions/`,
      body,
      configuration
    );
    return { data: response.data };
  } catch (err) {
    console.log(err);
    return { err };
  }
};

export const retrieveTransactionInformationByProcessId = async (
  processId,
  accessToken
) => {
  const configuration = {
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
      Authorization: `Bearer ${accessToken}`,
    },
  };
  try {
    const response = await axios.get(
      `${config.api.invokeUrl}/transactions/by-processId/${processId}`,
      configuration
    );
    return { data: response.data };
  } catch (err) {
    console.log(err);
    return { err };
  }
};
