import ContractMainPage from "../app/ContractMainPage";
import React, { useContext } from "react";
import { UserContext } from "../context/UserContextProvider";

export default function Parent() {
  const userContext = useContext(UserContext);
  const { userInformation } = userContext;
  return (
    <div className={`${userInformation.role === "ADMIN" ? "content" : ""}`}>
      {userInformation.role === "ADMIN" ? (
        <div className="" style={{ width: 260 }} />
      ) : null}

      <ContractMainPage />
    </div>
  );
}
