import ParentInformationTable from "./ParentInformationTable";

const ParentInformationTableWithTitle = (props) => {
  const { title, parentMother, parentFather } = props;

  return (
    <div className="mb-3 mt-3">
      <div className="d-flex justify-content-center align-item-center mb-3">
        <h5>Aile bilgileri</h5>
      </div>
      <ParentInformationTable
        parentFather={parentFather}
        parentMother={parentMother}
      />
    </div>
  );
};

export default ParentInformationTableWithTitle;
