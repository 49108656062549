export default function ContractText() {
  return (
    <div className="mt-3 mb-3">
      <h5 className="">GENEL HUSUSLAR</h5>
      <div style={{ paddingTop: 10, marginBottom: 10 }} />
      <ol>
        <li>
          Öğrencinin bir sonraki eğitim öğretim yılında okula devam etmek
          istemesi durumunda, yukarıda “Ödeme Bilgileri Bölümünde Öğrenci İçin
          Belirlenen Öğrenim Ücreti” üzerinden artış yapılacaktır. Bir sonraki
          yılda öğrencinin aynı okulun ara sınıfına devam emek istemesi
          durumunda eğitim ücreti, yılsonu TÜFE oranı dikkate alınarak
          Bakanlıkça belirlenen oranı aşmayacak şekilde tespit edilir.
        </li>
        <li>
          Kurumumuz, bir sonraki yılın eğitim ve öğretim ücretini ve diğer
          (takviye kursları / ÜDEP vb), etkinlik, yemek, kıyafet, kırtasiye,
          yatakhane vb. ve etkinlik ücretleri, ocak ayından itibaren mayıs
          ayının sonuna kadar ilan eder. Bu ilanlarda ders yılı veya ders saati
          ücretiyle birlikte, peşin veya taksitli ödemeler ve diğer indirimler
          de belirtilir.
        </li>
        <li>
          Sosyal ve kültürel faaliyetler, gezi ve gözlem gibi diğer etkinlikler
          için ücretler, faaliyet öncesi belirlenir. Faaliyetlere katılım ise
          veli/vasi izni doğrultusunda gerçekleşir.
        </li>
        <li>
          Kurumumuz, öğrenci veya velilerinden beceri eğitimi / staj çalışması
          adı altında ayrı bir ücret talep etmeyecektir.
        </li>
        <li>
          Kurumumuzun süresi içinde eğitim ve öğretim ücretini ilan etmemesi
          halinde, kurumumuzda bir önceki yılın sözleşmesindeki eğitim ve
          öğretim ücreti geçerli olacaktır.
        </li>
        <li>
          Kurumumuzun eğitim ve öğretim ücreti kurum adına açılan ve Valiliğe
          bildirilen banka hesap numarasına yatırılarak tahsil edilir.
          Öğrencilerden alınan ücretler e-Okul sistemine işlenir ve ücretler
          velilere duyurulur.
        </li>
        <li>
          Kurumumuz, çalışma takviminde belirtilen ders yılı sonunda ücretini
          ödemeyen öğrencilerin kayıtlarını yenilemeyebilir. Ücretini ödememekte
          ısrarcı olan velinin/vasinin çocuğunun kaydı, maarif müfettişleri
          tarafından yapılacak inceleme sonucunda nakil ve yerleştirme komisyonu
          aracılığıyla uygun olan resmi okula veya açık liseye alınır.
        </li>
        <li>
          <div style={{ fontWeight: "bold", paddingTop: 20, marginBottom: 10 }}>
            Kayıt Yenileme İşlemleri
          </div>
          <ol type="a">
            <li>
              TED Okulları Kurum Yönetmeliği’nin (MEB - 16.03.2018 - 95178073
              sayılı onayı) ilgili maddeleri gereği okullarımızda öğrenim gören
              öğrencilerin kayıt yenileme işlemlerini okulun belirlediği
              tarihlerde tamamlaması gerekmektedir.
            </li>
            <li>
              TED Okulları Kurum Yönetmeliğin gereği belirlenen son kayıt
              tarihine kadar mazeret belirtmeksizin kaydını yenilemeyen
              öğrenciler için, açıklanan o yıla ait indirimler uygulanmayacağı
              gibi kontenjanı dolu olan okullarımızda kayıt yenileme işlemi
              yapılamayacaktır.
            </li>
            <li>
              İçinde bulunulan eğitim öğretim yılında peşin ödeme, taksitli
              ödeme, kardeş indirimi , öğretmen indirimi , kurumsal ( THY, TSK,
              TOKİ, Kanuni Sultan Süleyman Hst. , Acıbadem Hst. , Esenyurt
              N.K.Devlet Hst. Vb.) uygulanan indirimler 1 yıl geçerli olup,
              sonraki yıl kayıt yenileme işlemlerinde yeniden güncellenir. Ted
              Atakent Koleji söz konusu İndirimleri kaldırma, değişiklik yapma
              ve oranlarını azaltma hakkını saklı tutar.
            </li>
            <li>
              TED Okulları Kurum Yönetmeliğin gereği belirlenen son kayıt
              tarihine kadar mazeret belirtmeksizin kaydını yenilemeyen burslu
              öğrenciler burs haklarını kaybedeceklerdir.
            </li>
          </ol>
        </li>
        <li>
          MEB Özel Öğretim Kurumları Yönetmeliğinin 56-3a Maddesi hükümlerince;
          eğitim ve öğretim yılı başlamadan kurumumuzdan ayrılanlara, öğrencinin
          ödeyeceği yıllık ücretin %10 dışındaki kısmı, eğitim ve öğretim yılı
          başladıktan sonra kurumumuzdan ayrılanlara ise öğrencinin ödeyeceği
          yıllık ücretin %10’u ile öğrenim gördüğü günlere göre hesaplanan
          miktarın dışındaki kısmı öğrenci veli/vasisine iade edilir.
        </li>
        <li>
          Kurumumuz tarafından öğrencinin ücretsiz okumasının uygun bulunması
          veya indirim hakkı elde etmesi halinde kurumumuza ödenen ücret, eğitim
          ve öğretimin başladıktan sonra iki ay içinde öğrenci veli/vasisine
          geri ödenir.
        </li>
        <li>
          Veliler, kurumumuzda kardeş indiriminden yararlanan öğrencinin,
          kardeşinin okuldan ayrılması halinde, kardeş indirimi tutarını kuruma
          geri ödemeyi kabul etmiş sayılır.
        </li>
        <li>
          Herhangi bir şekilde kurumumuzdan ayrılan burslu öğrencilerden öğrenim
          gördüğü süre için kurumumuz tarafından herhangi bir ücret talep
          edilmeyecektir.
        </li>
        <li>
          Öğrencinin okula devam etmesi esastır. Devamsızlık yapan öğrencilerin
          velileri/vasileri, öğrencilerin mazeretlerini okula bildirir.
          Veli/vasilerce söz konusu bildirimin okula yapılmaması halinde, okul
          idaresi gelmeyen öğrencilerin velisiyle/vasisiyle iletişim kurarak
          devamsız öğrenci hakkında veli/vasiye bilgi verir.
        </li>
        <li>
          Taraflar; Milli Eğitim Bakanlığı ve resmi kurumların alacağı kararlar
          doğrultusunda belirlenen yöntemle; yüz yüze, online, uzaktan ve
          benzeri bir şekilde yapılacak eğitim- öğretim hizmetinin, kurumun iş
          bu sözleşmede belirlenen ve yapmakla yükümlü olduğu eğitim-öğretim
          hizmetini ve buna ilişkin her türlü edimini ifa ettiği ve ifa etmiş
          sayılacağı hususunda mutabık olduklarını kabul ve beyan etmişlerdir.
        </li>
        <li>
          İş bu sözleşmede yer alan hususların yerine getirilmesi ve gerekli
          tebligatların yapılabilmesi için veli/vasi tarafından ilk sayfada
          belirtilen adresin yasal ikametgâh adresi olduğu ve adres değişikliği
          durumunda 15 gün içerisinde yazılı olarak okula bildirilmediği
          takdirde bu adrese yapılacak tebligat geçerli olacaktır. Öğrenci kayıt
          sözleşmesinin tüm hükümleri veli/vasi tarafından eksiksiz olarak
          okunmuş, anlaşılmış ve herhangi bir tereddüt olmaksızın tamamen hür
          irade ve arzu ile kesin olarak kabul edilmiştir.
        </li>
        <li>
          Veliler; öğrencinin eğitim, sağlık, sosyal, hukuksal alanlarına
          ilişkin kişisel verilerinin okul ile paylaşılması ve okul tarafından
          kullanılması ile ilgili olarak, Kişisel Verileri Koruma Kanunu
          gereğince bilgilendirildiği, aydınlatıldığı ve bu bilgileri
          onayladığını kabul etmiş sayılır.
        </li>
      </ol>
      <h5 className="">ÖZEL HUSUSLAR</h5>
      <ol>
        <div style={{ fontWeight: "bold", paddingTop: 20, marginBottom: 10 }}>
          A. Kayıt Şartları
        </div>
        <li>
          Ders ve yardımcı ders kitapları, kılık kıyafet, Öğrenci kayıt kabul
          işlemleri ve nakillerinde öncelikle MEB onaylı TED Okulları
          Anaokulu-İlkokul-Ortaokul-Ortaöğretim Kurumları Yönetmeliği ve/veya
          TED Atakent Koleji Kurum Yönetmeliği hükümlerinin, yönetmelikte hüküm
          bulunmayan hallerde 5580 sayılı yasa ve MEB Özel Öğretim Kurumları
          Yönetmeliği başta olmak üzere Milli Eğitim Bakanlığı mevzuatının
          uygulanacağı hususunda taraflar arasında mutabakata varılmıştır.
        </li>
        <li>
          Sınıf tekrarı yapacak olan öğrenciden, devam edeceği sınıftaki diğer
          öğrencilerin ödedikleri ücret alınır.
        </li>
        <div style={{ fontWeight: "bold", paddingTop: 20, marginBottom: 10 }}>
          B. Eğitim Öğretim
        </div>
        <li>
          Okulda eğitim öğretim 1739 sayılı Milli Eğitim Temel Kanununda ifade
          edilen Türk Milli Eğitiminin genel amaç ve temel ilkelerine uygun
          olarak yürütülür. Okulun amacı; Öğrencileri Atatürk inkılap ve
          ilkelerine ve Anayasada ifadesini bulan Atatürk milliyetçiliğine
          bağlı; Türk Milletinin milli, ahlaki, insani, manevi ve kültürel
          değerlerini benimseyen, koruyan ve geliştiren; ailesini, vatanını,
          milletini seven ve daima yüceltmeye çalışan; insan haklarına ve
          Anayasanın başlangıcındaki temel ilkelere dayanan demokratik, laik ve
          sosyal bir hukuk devleti olan Türkiye Cumhuriyetine karşı görev ve
          sorumluluklarını bilen ve bunları davranış haline getirmiş yurttaşlar
          olarak yetiştirmektir.
        </li>
        <li>
          Okul idaresinin yazılı izni olmadan öğrencilerin kendi aralarında
          yapacakları gezi ve etkinliklerden Okul sorumlu olmayıp Okul, yasal
          görevini yerine getirmek üzere sorumlular hakkında inceleme ve
          soruşturma başlatma hakkına da sahiptir.
        </li>
        <li>
          Öğrenci, ders saatleri içerisinde haklı ve geçerli mazeretlerin vuku
          halinde ancak velinin yazılı talep ve isteği ile Okul’dan ayrılabilir.
        </li>
        <li>
          Veli, Öğrencinin Okula, derslere ve sınavlara yönelik düzenlenen
          Takviye Kursu veya üniversite destek programına düzenli bir şekilde
          devamını sağlamak ve Okul’a zamanında gelmesini temin etmekle birinci
          derecede sorumludur.
        </li>
        <li>
          Okul yönetimi ve öğretmenler tarafından yapılan ve velinin katılması
          gereken her türlü toplantı ve etkinliklere velinin katılması
          zorunludur.
        </li>
        <li>
          BTEC Uluslararası Program 9 ve 10. Sınıflarda zorunlu olup ücreti
          eğitim ücretine ek olarak ödenir.
        </li>
        <li>
          Okul, değişik sosyal, kültürel ve sportif etkinliklere katılacak
          öğrencileri seçme hakkına sahip olup, Öğrenci ve velinin bireysel
          istekleri imkânlar ölçüsünde dikkate alınacaktır.
        </li>
        <div style={{ fontWeight: "bold", paddingTop: 20, marginBottom: 10 }}>
          C. Öğrenci Davranışları
        </div>
        <li>
          Öğrenci, Okul içi ve dışında Millî Eğitim Bakanlığı tarafından
          belirlenen tutum ve davranışlara uymak, öğretmenlerinin uyarılarını
          dikkate almak zorundadır.
        </li>
        <li>
          Öğrenci, öğretmen ve arkadaşları ile personele saygılı davranmak
          zorundadır.
        </li>
        <li>
          Öğrencinin, derslere zamanında girmesi, dersin işlenişini bozmaması,
          öğretmen ve arkadaşlarını derste rahatsız etmemesi gerekmektedir.
        </li>
        <li>
          Öğrenci, ders içinde ya da dışında hiçbir siyasi veya ideolojik
          konuşma yapamayacağı gibi, Okula delici/kesici alet, radyo teyp,
          gazete, tablet gibi görüntü ve kayıt özelliği olan teknolojik araçlar,
          gayri ahlaki ve siyasi içerikli yayın, kaset, CD, vb. şeyleri
          getiremez. Akıllı saat, akıllı telefon ve MP3 getirmesi halinde okul
          bitiminde almak üzere ilgili müdür yardımcısına teslim etmek
          zorundadır. Okul tarafından belirlenen kılık-kıyafet kurallarına ve
          yönetmelik hükümlerine aynen uymak zorundadır.
        </li>
        <li>
          Öğrencinin, davranış ve disiplinsizlikleri hakkında MEB Okul Öncesi ve
          İlköğretim Kurumları Yönetmeliği ve MEB Ortaöğretim Kurumları
          Yönetmeliğinin öğrenci davranışları ile ilgili hükümleri aynen
          uygulanacaktır.
        </li>
        <li>
          Öğrenci, öğretmenleri tarafından istenen her türlü araç-gereç ve ders
          kaynaklarını zamanında getirmek, verilen ödev ve projeleri zamanında
          istenilen şekilde yapmak, derslerine düzenli olarak çalışmak,
          öğretmenleri tarafından verilen görevleri yerine getirmek zorundadır.
        </li>
        <li>
          Eğitim öğretimin sağlıklı sürdürülebilmesi bakımından Okul, sınıfları
          istediği gibi oluşturmaya; gerekirse öğrencilerin sınıfını
          değiştirmeye, sınıf içi oturma düzenini planlamaya yetkili olup, Zümre
          Öğretmenler Kurulu kararları uygulamada önceliğe sahip olacaktır.
        </li>
        <li>
          Okulun, işbu sözleşme hükümlerine uymayan öğrencinin kaydının
          alınmasını veliden talep etme hakkının varlığı ve bu hakka itiraz
          edilmeyeceği hususunda taraflar arasında mutabakata varılmıştır.
        </li>
        <li>
          Öğrenci velisi öğrencinin sağlık durumu ile ilgili bilgileri okul ile
          paylaşmak durumundadır. Kayıt yenileme veya yeni kayıt döneminde veya
          okul devam ederken gelişen sağlık sorunlarını /daha önceden bilinen
          sağlık sorunlarını, nasıl bir tedavi sürecinde olduğunu okula
          bildirmek zorundadır. Okul yönetimi bu sağlık sorunu veya sorunlarının
          öğrenciyi, okul arkadaşlarını nasıl etkileyeceği konusunda ayrıca bu
          sağlık sorunu veya sorunlarının okulun sağlık departmanı tarafından
          takip edilip edilmeyeceği konusunda da karar verir ve bu hususu veliye
          bildirir.
        </li>
        <div style={{ fontWeight: "bold", paddingTop: 20, marginBottom: 10 }}>
          D. Servis ve Yemek
        </div>
        <li>
          Okulun Servis yönergesi ve MEB Okul Servis Araçları Yönetmeliği
          hükümlerine uygun olarak akdedilen Servis Sözleşmesi doğrultusunda
          Okul Aile Birliği’nin de görüşleri alınarak belirlenen servis
          güzergâhlarına göre Öğrencinin Okula ulaştırılması servis şirketi ile
          sağlanır. Servisten yararlanma zorunluluğu bulunmamakta olup,
          servisten yararlanan ya da yararlanmayan Öğrencinin Okulumuza
          getirilmesi ve götürülmesinden dolayı tüm sorumluluk veliye aittir.
        </li>
        <li>
          Öğrenci davranış ve disiplin sorumlulukları servis araçları içinde de
          aynen geçerli olup, aksi durumda Öğrencinin servis kullanma hakkının
          sona erdirilmesinden dolayı tüm sorumluluk veliye aittir.
        </li>
        <li>
          Servis ile ilgili sorunların giderilmesinde öncelikle veliler ile
          taşıyıcı firma birbirlerine karşı sorumlu olup Okulun tüm sorumluluğu,
          Servis Firması ile akdedilen sözleşme hükümleri doğrultusunda gerekli
          tedbirleri almaktan ibarettir.
        </li>
        <li>
          Servis içerisinde yemek yenmesi yasak olup, gerek Öğrenci, gerekse
          veli servis aracının bekletilmemesi, araçta uygulanan güvenlik
          tedbirlerine aynen uyulması, şoför ve rehberin uyarılarına riayet
          edilmesi konusunda üzerlerine düşen yükümlülükleri eksiksiz yerine
          getirmekle sorumludur. Güvenlik tedbirleri yanında şoför ve rehberin
          uyarılarına riayet edilmemesi halinde doğacak tüm sorumluluk veliye
          ait olup, Okulumuza hiçbir yükümlülük getirilemez.
        </li>
        <li>
          Servis güzergâhları Öğrenci adresine 100 m’den daha uzak mesafede
          olamaz. Öğrenci servisleri site ve müstakil evlerde bahçe içine
          girmez.
        </li>
        <li>
          Öğrenciye Okul tarafından her eğitim öğretim yılı başında ilan
          edildiği şekilde yemek hizmeti verilir. Kurum ilan edilen yemek
          hizmeti ücretini yıl içerisinde meydana gelebilecek TÜFE artışı vb.
          nedenlerle güncelleme hakkını saklı tutar.
        </li>
        <li>
          Yemek saatinde tüm öğrencilerimizin okul yemekhanesinde öğle yemeğine
          katılım göstermeleri okulumuzdaki eğitim öğretim faaliyetlerinin ve
          günlük programın bir parçasıdır. Bu husus göz önünde bulundurularak
          yemek hizmeti düzenlenir. Sadece eğitim -öğretim hizmeti bedelini
          ödeyerek eğitim -öğretim hizmeti almak istiyoruz, yemek hizmeti almak
          istemiyoruz şeklindeki talepler; bu madde ve aşağıdaki maddelerdeki
          gerekçelerle kabul edilemez. Veli işbu sözleşmeyi imzalamakla velisi
          olduğu öğrencinin yemek hizmetinden yararlanacağını talep, beyan ve
          kabul etmektedir.
        </li>
        <li>
          Yemekler belirlenirken özel beslenme gereksinimi olan öğrencilerimiz
          için diyet veya vejetaryen yemekleri için okul doktoru ve okul
          diyetisyenlerinin görüşü alınarak menü oluşturulur.
        </li>
        <li>
          Okulda sunulan yemek hizmetleri Millî Eğitim Bakanlığı, Sağlık
          Bakanlığı, Gıda Tarım ve Hayvancılık Bakanlığı ile ilgili
          kurum/kuruluşlarca hazırlanan 10.03.2016 tarih ve 2852893 sayılı
          Okulda Satılacak Gıdalar ve Eğitim Kurumlarındaki Gıda İşletmelerinin
          Hijyen Yönünden Denetlenmesi Konulu Genelge doğrultusunda
          yürütülmektedir.
        </li>
        <li>
          Yürürlükteki mevzuat gereği öğrenci ve çalışanlara, sağlıklı beslenme
          ortamlarının oluşturulması, yeterli ve dengeli beslenmenin teşvik
          edilmesi ile doğru beslenme alışkanlıkları kazandırılması, güvenilir
          gıdaya erişim sağlanarak olabilecek gıda zehirlenmeleri, bulaşıcı
          hastalıklar ile yetersiz ve dengesiz beslenmeye bağlı hastalıkların
          önlenmesi (obezite, diyabet, kalp damar hastalıkları vb.) amaç
          edinildiğinden okuldan yemek hizmeti almak zorunlu olup, dışarıdan
          yemek getirilmesi uygun değildir.
        </li>
        <li>
          Okullarda sağlıklı beslenmenin sağlanması amacıyla; İlçe Millî Eğitim
          Müdürlüğü, İlçe Sağlık Müdürlüğü ve Tarım ve Orman İlçe Müdürlüğü
          temsilcilerinden oluşan İlçe Denetim Ekipleri denetim yapmakta
          olduğundan, olası sorunlar için kontrolsüz gıda girişine izin
          vermemektedir.
        </li>
        <li>
          Yemek listeleri mevsime göre aylık olarak belirlenerek ilan edilir.
          Listelerin hazırlanmasında Öğrencilerin bireysel isteklerinden çok
          sağlıklı ve dengeli beslenme kuralları dikkate alınır.
        </li>
        <li>
          Öğrencinin herhangi bir nedenle devamsızlığı ya da Okulun idari
          nedenlerle tatili halinde belirlenen yemek ücretinden herhangi bir
          indirim yapılmaz.
        </li>
        <div style={{ fontWeight: "bold", paddingTop: 20, marginBottom: 10 }}>
          E. Ödeme
        </div>
        <li>
          İşbu sözleşme gereğince Ödeme Bilgilerinde yer alan yıllık öğrenim,
          etkinlik ücreti, 7.ve 8. Sınıflar için liselere giriş sınavına
          hazırlık için takviye kursu ile 11. ve 12. sınıflar için Üniversite
          Destek Eğitim Programı (ÜDEP) ücreti, yemek, kahvaltı, varsa pansiyon
          (yatakhane) ücretlerinin planlanan tarihlerde yatırılacağı veli
          tarafından kabul ve taahhüt edilmiştir. Toplam ücrete KDV dâhildir.
          KDV oranındaki değişiklikler hiçbir ihbara gerek olmaksızın ücrete
          yansıtılır.
        </li>
        <li>
          Ödeme Bilgileri kısmında yer alan ücretlerin banka ödeme araçları
          (POS, KMH vb.) ile ödenmesi halinde Veli ile Banka /Bankalar arasında
          doğacak uyuşmazlıkların ya da ödeme sorunlarının Okula herhangi bir
          yükümlülük getirmeyeceği hususunda taraflar mutabıktır.
        </li>
        <li>
          Ödeme Bilgilerinde belirlenen ödeme tutarlarının belirlenen sürede
          yapılmaması halinde geciken ödemelerin aylık %3 oranındaki gecikme
          faiziyle birlikte talep ve tahsil edileceği hususunda da taraflar
          mutabıktır.
        </li>
        <li>
          Veli, mutabık kalınan Ödeme Bilgileri gereğince doğan borcun tamamını
          veya taksitlerden birini belirtilen tarihte ödemediği takdirde okul
          yönetiminin mahkeme ve icra müdürlüklerine başvurmasına sebebiyet
          verirse tüm mahkeme masraflarını, İcra İnkâr Tazminatını, vekâlet
          ücretini, yasal her türlü mükellefiyeti derhal ödemeyi peşinen kabul
          ve taahhüt eder.
        </li>
        <li>
          İşbu sözleşmenin akdedilmesiyle birlikte öğrencinin kesin kaydı
          yapılmış olup, Velinin öğrenim ücreti borçlarını ödememesi nedeniyle
          Okulun öğrencinin Öğrencilik durumuna son verme hak ve yetkisinin
          varlığı da kabul edilmiştir. Ancak, bu durumun Öğrencinin eğitimini
          sürdürdüğü süreye ilişkin eğitim borcu ile ferilerinin ödenmeyeceği
          anlamına gelmediği hususunda da taraflar arasında mutabakata
          varılmıştır.
        </li>
        <li>
          Öğrenim ya da yemek ve servis ücretinin tamamının ödenmemesi halinde
          Öğrencinin Diploma ya da Tasdiknamesinin borç ödeninceye kadar Öğrenci
          veya Veliye verilmeyeceği hususunda da taraflar arasında anlaşmaya
          varılmıştır.
        </li>
        <div style={{ fontWeight: "bold", paddingTop: 20, marginBottom: 10 }}>
          F. Yetki
        </div>
        <li>
          İş bu sözleşme 54 maddeden oluşmaktadır. Veli, bu öğrenci kayıt
          sözleşmesinin tüm hükümlerini eksiksiz olarak okuduğunu, anladığını ve
          herhangi bir tereddüdü olmaksızın tamamen hür irade ve arzusuyla kesin
          olarak kabul eder. Sözleşme 6 sayfadan ibaret olup, iki nüsha olarak
          tanzim edilmiş ve bir nüshası veliye teslim edilmiştir.
        </li>
      </ol>
    </div>
  );
}
