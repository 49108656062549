const ParentExpressConsent = (props) => {
  const { student } = props;

  const constructStudentName = () => {
    return student.name + " " + student.lastname;
  };

  const constructParentNameByParentType = (parentType) => {
    const parent = student.parents.find((p) => p.parentType === parentType);
    if (parent) {
      return parent.user.name + " " + parent.user.lastname;
    } else {
      return "";
    }
  };

  if (!student) return;
  return (
    <div className="mt-3 mb-3">
      <h5 className="">ÖĞRENCİ VELİSİNE AİT AÇIK RIZA BEYANI</h5>
      <div style={{ paddingTop: 10, marginBottom: 10 }} />
      6698 sayılı Kişisel Verilerin Korunması Kanunu'na uygun olarak Özel
      Atakent Kolej Eğitim Yayın Basım Hizmetleri Ticaret ve Sanayi Anonim
      Şirketi (TED ATAKENT KOLEJİ)’nin, faaliyetinin devamı ve amacıyla
      bağlantılı olarak her türlü etkinlik, yeni kayıt veya kayıt yenileme
      işlemleri, spor, tiyatro, müzik vb. sanatsal faaliyetler, başarı veya
      ihtiyaç bursu başvuru ve işlemleri, sms veya e mail yoluyla tarafıma
      yapılan bilgilendirmeler ya da tarafımdan talep edilen bilgilendirmeler,
      K12 mesajları, Sebit Vcloud girişleri, konferans, bayram, tören, karnaval,
      gezi, kermes, bağış ve benzeri faaliyetler, gizlilik yükümlülüğü bulunan
      Revir bölümünde görevli doktor/hemşire müdahale ve kayıtları, PDR görüşme
      ve kayıtlarına yönelik olarak, elektronik, görsel, yazılı veya diğer
      yollarla, okulun internet sitesi ve bunlarla sınırlı olmamak üzere her
      türlü kanallarla, ibraz edilen kişisel verilerimizin koruma yöntemlerine
      riayet edilerek, faaliyetin amacı ve yasalardaki sürelerle sınırlı olmak
      üzere işlenmesine, muhafaza edilmesine, güncellenmesine, okul içinde
      ilgili birim ve genel merkez ile ve yasal zorunluluk gerektirmesi halinde
      resmi makamlarla paylaşılmasına, faaliyetin amacı gerçekleştiğinde veya
      yasal süreleri dolduğunda silinmesine, yok edilmesine veya
      anonimleştirilmesine velisi bulunduğum{" "}
      <span style={{ fontWeight: "bold" }}>{constructStudentName()}</span> ve
      şahsım adına, detaylı bilgi sahibi olarak, ekli aydınlatma metni ve
      haklarımı açıklayan bilgilendirmeyi okuyup, anlayarak, aydınlatılmış açık
      rızam ile;
      <div className="row">
        <div className="col-md-12">
          <div className="mt-3">
            <div className="d-flex flex-row">
              <div style={{ marginRight: 10 }}>Tarih: </div>
              <div style={{ fontWeight: "bold" }}>
                {new Date().toLocaleDateString("TR")}
              </div>
            </div>
          </div>
          <div className="d-flex flex-row justify-content-between">
            <p style={{ fontWeight: "bold" }}>Onay veriyorum</p>
            <div>
              {constructParentNameByParentType("ANNE") ? (
                <div>
                  Anne adı:{" "}
                  <span style={{ fontWeight: "bold" }}>
                    {constructParentNameByParentType("ANNE")}
                  </span>
                </div>
              ) : null}
              {constructParentNameByParentType("BABA") ? (
                <div>
                  Baba adı:{" "}
                  <span style={{ fontWeight: "bold" }}>
                    {constructParentNameByParentType("BABA")}
                  </span>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      <div style={{ paddingTop: 10, marginBottom: 10 }} />
      Okulda veli gözükmeyen diğer ebeveyn olarak{" "}
      <span style={{ fontWeight: "bold" }}>{constructStudentName()}</span> ait
      yazılı, sözlü, sms vb. yollarla not, genel durum vb. bilgi talep etmem
      halinde, kişisel verilerimin işlenmesine, muhafaza edilmesine,
      güncellenmesine, tarafımla, Okul içi ilgili birimler, Genel Merkez ve
      yasal zorunluluk içermesi halinde resmi kurumlarla paylaşılmasına
      aydınlatılmış açık rızam ile;
      <div className="row">
        <div className="col-md-12">
          <div className="mt-3">
            <div className="d-flex flex-row">
              <div style={{ marginRight: 10 }}>Tarih: </div>
              <div style={{ fontWeight: "bold" }}>
                {new Date().toLocaleDateString("TR")}
              </div>
            </div>
          </div>
          <div className="d-flex flex-row justify-content-between">
            <p style={{ fontWeight: "bold" }}>Onay veriyorum</p>
            <div>
              {constructParentNameByParentType("ANNE") ? (
                <div>
                  Anne adı:{" "}
                  <span style={{ fontWeight: "bold" }}>
                    {constructParentNameByParentType("ANNE")}
                  </span>
                </div>
              ) : null}
              {constructParentNameByParentType("BABA") ? (
                <div>
                  Baba adı:{" "}
                  <span style={{ fontWeight: "bold" }}>
                    {constructParentNameByParentType("BABA")}
                  </span>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ParentExpressConsent;
