import Logo from "../../assets/logo.png";
import { Link } from "react-router-dom";
export default function Letter() {
  return (
    <div className="container">
      <div className="row">
        <div className="col-12 col-xl-12">
          <div className="card card-body border-0 shadow mb-4">
            <div className="w-100 d-flex justify-content-center">
              <img
                src={Logo}
                alt="atekent_logo"
                style={{ width: "150px" }}
                className="p-3"
              />
            </div>

            <h2 className="h5 mb-4">
              TED Atakent Koleji ailemizin çok kıymetli velileri;
            </h2>

            <p>
              <b>Merhaba,</b>
            </p>

            <p>
              TED Atakent Koleji eğitim kurumları olarak Türkiye genelinde,
              kaliteli eğitimi ülkemizin her yerine ulaştırmaya devam ediyoruz.
            </p>

            <p>
              Yüz yüze eğitim sürecini başarıyla yürüttüğümüz gibi uzaktan
              eğitim sürecini de en etkin yürüten kurumların başında geliyoruz.
              "Eğitimin kalitesini içeriği belirler" diyoruz;
            </p>

            <p>
              Basarimizin kaynağı elbette yalnızca yatırımlarımız ve
              yazılımlarımızdan ibaret değil. Bu dönemin basariye ulaşmasındaki
              en büyük pay, öğrencilerimizin akademik ve psikososyal
              gelişimlerinin kesintisiz devam etmesi için canla başla çalışan
              öğretmenlerimiz ve çocuklarının eğitimi için her ayrıntı üzerinde
              dikkatle duran siz değerli velilerimizindir. Koşullar ne olursa
              olsun kaliteli eğitimi çocuklarımızla buluşturan öğretmenlerimize
              ve bu hassasiyet çerçevesinde öncelikle bizlere duyduğunuz güven
              ve verdiğiniz destek için siz değerli velilerimize teşekkür
              ediyoruz.
            </p>

            <p>2024-2025 eğitim öğretim yılı kayıt dönemimiz başlamıştır.</p>

            <p>
              Yeni eğitim öğretim döneminde kayıt önceliği okulumuzun
              öğrencilerine aittir.
            </p>
            <p>
              Aylara göre farklılık gösterecek oranlar belirlenmiştir, yeni
              kayıt döneminde size sunduğumuz ayrıcalığın detaylarını
              okulumuzdan öğrenebilirsiniz. Dışarıdan kayıt yaptırmak isteyen
              öğrencilerimizin kayıtlar da bu süreçte devam edeceğinden;
              mağduriyet yasamamanız adına kayıt yenileme takviminizi
              sınıf/montör öğretmenlerimiz ile iletişime geçerek paylaşmanızı
              önemle dikkatinize sunarız.
            </p>

            <p>Başarlarla ve sağlıkla dolu bir yıl dileriz.</p>

            <p>
              <b>Saygılarımızla</b>
            </p>

            <Link
              className="btn btn-success text-light m-2"
              to="/parent-student"
            >
              Online kayıt yenileme sistemine giriş yap
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
