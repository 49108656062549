import React, { useEffect, useState } from "react";
import BaseModal from "./components/BaseModal";
import FormInput from "../app/components/FormInput";
import { useInput } from "../service/useInput";
import {
  createAdminUserInformation,
  deleteAdminUserInformation,
  patchAdminUserInformation,
  retrieveAllAdminUsersBySchoolId,
} from "../requests/UserRequests";
import { NotificationManager } from "react-notifications";

const userDetail = {
  id: "",
  identityNumber: "",
  password: "",
  loginCode: "",
  loginCodeLastTime: 0,
  role: "ADMIN",
  status: "WORKING",
  phone: "",
  name: "",
  lastname: "",
  email: "",
  lastLoginTime: 0,
};

const staffDetail = {
  schools: [],
  grades: [],
};

export default function SystemUser() {
  const [dataList, setDataList] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [inputs, setInputs] = useInput(userDetail);
  const [staffInputs, setStaffInputs] = useInput(staffDetail);
  const [showUserModal, setShowUserModal] = useState(false);
  const schoolId = localStorage.getItem("schoolId");
  const user = JSON.parse(localStorage.getItem("user"));
  const userId = localStorage.getItem("userId");

  useEffect(() => {
    if (!schoolId) return;
    retrieveAndSetAllAdminUsersBySchoolId();
  }, [schoolId]);

  useEffect(() => {
    if (selectedUser) {
      setInputs({
        id: selectedUser.id,
        name: selectedUser.name,
        lastname: selectedUser.lastname,
        identityNumber: selectedUser.identityNumber,
        phone: selectedUser.phone,
        email: selectedUser.email,
      });
    } else {
      setInputs(JSON.parse(JSON.stringify(userDetail)));
    }
  }, [selectedUser]);

  const retrieveAndSetAllAdminUsersBySchoolId = async () => {
    const { data, err } = await retrieveAllAdminUsersBySchoolId(
      schoolId,
      user?.access_token
    );
    if (err) {
      NotificationManager.error(
        "Admin kullanıcı listesi sorgulanırken bir hata oluştu"
      );
      return;
    }
    setDataList(data);
  };

  const handleSaveStaffOnClick = async () => {
    const { data, err } = await createAdminUserInformation(
      {
        user: {
          ...inputs,
          school: {
            id: schoolId,
          },
        },
        staff: staffInputs,
        school: {
          id: schoolId,
        },
      },
      user?.access_token
    );
    if (err) {
      NotificationManager.error("Kullanıcı oluşturulurken bir hata oluştu");
      return;
    }
    NotificationManager.success("Kullanıcı başarıyla oluşturuldu");
    retrieveAndSetAllAdminUsersBySchoolId();
    setShowUserModal(false);
  };

  const handleUpdateUserOnClick = async () => {
    const { data, err } = await patchAdminUserInformation(
      inputs,
      user?.access_token
    );
    if (err) {
      NotificationManager.error("Kullanıcı güncellenirken bir hata oluştu");
      return;
    }
    NotificationManager.success("Kullanıcı başarıyla güncellendi");
    retrieveAndSetAllAdminUsersBySchoolId();
    setShowUserModal(false);
  };

  const handleDeleteStaffOnClick = async (userId) => {
    const { data, err } = await deleteAdminUserInformation(
      userId,
      user?.access_token
    );
    if (err) {
      NotificationManager.error("Kullanıcı silinirken bir hata oluştu");
      return;
    }
    NotificationManager.success("Kullanıcı başarıyla silindi");
    retrieveAndSetAllAdminUsersBySchoolId();
    setShowUserModal(false);
  };

  const RenderUserModalContent = () => {
    return (
      <BaseModal
        modalIsOpen={showUserModal}
        contentLabel={"Ücret kalemi bilgileri"}
        closeModal={() => {
          setShowUserModal(false);
        }}
      >
        <div className="col-12 ">
          <>
            <div className="my-3">
              {selectedUser ? (
                <h5>Kullanıcı düzenle</h5>
              ) : (
                <h5>Kullanıcı ekle</h5>
              )}
            </div>
            <div className="row p-4">
              <FormInput
                name="identityNumber"
                text="Kimlik numarası"
                placeholder="Kimlik numarası"
                type="number"
                col="6"
                value={inputs.identityNumber}
                onChange={setInputs}
              />

              <FormInput
                name="name"
                text="İsim"
                placeholder="İsim"
                type="text"
                col="6"
                value={inputs.name}
                onChange={setInputs}
              />

              <FormInput
                name="lastname"
                text="Soyisim"
                placeholder="Soyisim"
                type="text"
                col="6"
                value={inputs.lastname}
                onChange={setInputs}
              />

              <FormInput
                name="email"
                text="E-Mail"
                placeholder="E-Mail"
                type="text"
                col="6"
                value={inputs.email}
                onChange={setInputs}
              />
              <div className="col-6">
                <div className="d-flex flex-row">
                  <div className="1" style={{ marginTop: 40, marginRight: 10 }}>
                    <p>+90</p>
                  </div>
                  <div className="col">
                    <FormInput
                      name="phone"
                      text="Telefon"
                      placeholder="Telefon"
                      type="text"
                      col="12"
                      value={inputs.phone}
                      onChange={setInputs}
                    />
                  </div>
                </div>
              </div>

              {selectedUser?.id ? (
                <button
                  className="btn btn-success text-light "
                  onClick={() => {
                    handleUpdateUserOnClick();
                  }}
                >
                  Güncelle
                </button>
              ) : (
                <button
                  className="btn btn-success text-light "
                  onClick={handleSaveStaffOnClick}
                >
                  Kaydet
                </button>
              )}
            </div>
          </>
        </div>
      </BaseModal>
    );
  };

  return (
    <>
      {RenderUserModalContent()}
      <div className="content">
        <div className="row">
          <button
            className="btn btn-sm btn-secondary me-2"
            onClick={() => {
              setSelectedUser(null);
              setInputs(JSON.parse(JSON.stringify(userDetail)));
              setShowUserModal(true);
            }}
          >
            Yeni kullanıcı tanımla
          </button>
        </div>

        <div className="col-12 col-xl-12">
          <div className="card border-0 shadow">
            <div className="card-body">
              <div className="table-responsive">
                <table className="table table-centered mb-0 rounded table-hover w-100">
                  <thead className="thead-light">
                    <tr>
                      <th className="border-0">Kullanıcı</th>
                      <th className="border-0">Kullanıcı Adı</th>
                      <th className="border-0">Rol</th>
                      <th className="border-0"></th>
                      <th className="border-0"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {dataList?.map((user, key) => (
                      <tr key={key} style={{ cursor: "pointer" }}>
                        <td>
                          {user.name} {user.lastname}
                        </td>
                        <td>{user.identityNumber}</td>
                        <td>{user.role}</td>
                        <td>
                          <button
                            className="btn btn-info "
                            onClick={() => {
                              setSelectedUser(user);
                              setShowUserModal(true);
                            }}
                          >
                            İşlem
                          </button>
                        </td>
                        <td style={{ maxWidth: "20%" }}>
                          {dataList.length > 1 && user.id !== userId ? (
                            <div>
                              <span
                                onClick={() => {
                                  const answer = window.confirm(
                                    "Silmek istediğinize emin misiniz?"
                                  );
                                  if (answer) {
                                    handleDeleteStaffOnClick(user.id);
                                  }
                                }}
                                style={{
                                  textDecoration: "underline",
                                  color: "blue",
                                  cursor: "pointer",
                                  marginLeft: 5,
                                }}
                              >
                                (Sil)
                              </span>
                            </div>
                          ) : null}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
