import { useContext, useEffect, useState } from "react";
import Back from "../../assets/back.png";
import { useNavigate, useParams } from "react-router-dom";
import Logo from "../../assets/logo.png";
import { UserContext } from "../../context/UserContextProvider";
import FeesTable from "../page-parts/fees/FeesTable";
import { retrieveStudentWithData } from "../../requests/StudentRequests";
import { NotificationManager } from "react-notifications";
import { retrieveStudentContractByStudentIdAndParentId } from "../../requests/ContractRequests";

export default function StudentContractDetail() {
  const { id } = useParams();
  const navigate = useNavigate();
  const userContext = useContext(UserContext);
  const { userInformation } = userContext;
  const [studentContract, setStudentContract] = useState(null);
  const parentUser = JSON.parse(localStorage.getItem("parent_data"));
  const user = JSON.parse(localStorage.getItem("user"));
  const [studentWithData, setStudentWithData] = useState([]);

  useEffect(() => {
    const retrieveAndSetStudentWithData = async () => {
      const { data, err } = await retrieveStudentWithData(
        id,
        user?.access_token
      );
      if (err) {
        NotificationManager.error(
          "Öğrenci bilgisi sorgulanırken bir hata oluştu"
        );
        return;
      }
      setStudentWithData(data);
    };
    const retrieveAndSetStudentContractByStudentIdAndParentId = async () => {
      const { data, err } = await retrieveStudentContractByStudentIdAndParentId(
        id,
        parentUser.user_id,
        user?.access_token
      );
      if (err) {
        NotificationManager.error(
          "Öğrenci sözleşme detayı sorgulanırken bir hata oluştu"
        );
        return;
      }
      setStudentContract(data);
    };
    if (!id) return;
    retrieveAndSetStudentWithData();
    retrieveAndSetStudentContractByStudentIdAndParentId();
  }, [id]);

  const startContract = () => {
    navigate(`/parent-payment/${id}`);
  };

  return (
    <div className={`${userInformation.role === "ADMIN" ? "content" : ""}`}>
      {userInformation.role === "ADMIN" ? (
        <div className="" style={{ width: 260 }} />
      ) : null}
      <div className="container">
        <div className="row">
          <div className="col-12 col-xl-12">
            <div className="card card-body border-0 shadow mb-4">
              <div className="w-100 d-flex justify-content-center">
                <img
                  src={Logo}
                  alt="atekent_logo"
                  style={{ width: "150px" }}
                  className="p-3"
                />
              </div>

              <div className="row">
                <div className="col-auto">
                  <div
                    className="d-inline-flex"
                    onClick={() => navigate(`/parent-student`)}
                  >
                    <img
                      src={Back}
                      alt="back"
                      style={{ width: "30px", cursor: "pointer" }}
                    />
                  </div>
                </div>
                <div className="col">
                  <h2 className="h5 mb-4">
                    {studentWithData ? studentWithData.name : ""}{" "}
                    {studentWithData ? studentWithData.lastname : ""} için kayıt
                    bilgilendirmesi
                  </h2>
                </div>
              </div>

              <div className="alert alert-info">Fiyat bilgileri</div>
              <FeesTable
                contractFees={studentContract?.contractFees}
                contractDiscounts={studentContract?.contractDiscounts}
              />

              <button
                className="btn btn-success text-light "
                onClick={() => startContract()}
              >
                Sözleşme sürecini başlat
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
