import React from "react";
import { formatMoneyToTL } from "../../../utilization/MoneyOperations";
import { convertToDateString } from "../../../utilization/DateOperations";

export default function CreditCartInstallment(props) {
  const { paymentList } = props;
  if (!paymentList) return;

  const filterPayments = () => {
    return paymentList.filter((payment) => {
      const { contractPaymentTransferItem } = payment;
      if (contractPaymentTransferItem) {
        const { status } = contractPaymentTransferItem;
        return status === "APPROVED";
      } else {
        return payment;
      }
    });
  };

  return (
    <div className="table-responsive">
      <table className="table table-centered mb-0 rounded table-hover w-100">
        <thead className="thead-light">
          <tr>
            <th className="border-0">TÜR</th>
            <th className="border-0">DETAY</th>
            <th className="border-0">TUTAR</th>
          </tr>
        </thead>
        <tbody>
          {filterPayments().map((payment, key) => (
            <tr key={key} style={{ cursor: "pointer" }}>
              <td>
                {payment.paymentType === "PESIN"
                  ? "Peşin ödeme"
                  : payment.paymentType === "PESİNAT"
                  ? "Peşinat"
                  : payment.paymentType === "TAKSİT"
                  ? "Taksitli ödeme"
                  : payment.paymentType === "HAVALE"
                  ? "Havale"
                  : payment.paymentType === "KMH"
                  ? `KMH (Ödeme ${payment.installmentNo})`
                  : null}
              </td>
              <td>{convertToDateString(payment.installmentDate)}</td>
              <td>
                {payment.paymentType === "PESIN" ||
                payment.paymentType === "PESİNAT" ||
                payment.paymentType === "HAVALE"
                  ? formatMoneyToTL(payment.cash) + " TL"
                  : formatMoneyToTL(payment.installmentFee) + " TL"}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
