import React from "react";

export default function ParentInformation (props){


    return (
        <div>
            {props.selectedStudent ? props.selectedStudent.parents && Array.isArray(props.selectedStudent.parents)
                ? props.selectedStudent.parents.map((parent, key) => (
                    <table className="table table-centered table-nowrap mb-0 rounded table-hover mt-3">
                        <tbody>
                        <tr>
                            <th>İSİM SOYİSİM</th>
                            <td>{parent.user.name} {parent.user.lastname}</td>
                        </tr>
                        <tr>
                            <th>YAKINLIK</th>
                            <td>{parent.parentType}</td>
                        </tr>
                        <tr>
                            <th>TELEFON</th>
                            <td>{parent.user.phone}</td>
                        </tr>
                        <tr>
                            <th>KİMLİK</th>
                            <td>{parent.user.identityNumber}</td>
                        </tr>
                        </tbody>
                    </table>

                ))
                : null : null}
        </div>
    )
}