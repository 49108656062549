import StudentInformationTable from "./StudentInformationTable";

const StudentInformationTableWithTitle = (props) => {
  const { title, student, parentFather, parentMother } = props;
  return (
    <div className="mb-3 mt-3">
      <div className="d-flex justify-content-center align-item-center mb-3">
        <h5>{title}</h5>
      </div>
      <StudentInformationTable
        student={student}
        parentMother={parentMother}
        parentFather={parentFather}
      />
    </div>
  );
};

export default StudentInformationTableWithTitle;
