import React, { useState, useEffect, useContext } from "react";
import HideShowButton from "./header/HideShowButton";
import Search from "./header/Search";

import { AppContext } from "../../context/AppContextProvider";
import { UserContext } from "../../context/UserContextProvider";
import { useNavigate } from "react-router";
import TokenService from "../../service/token.service";

export default function Header() {
  const navigate = useNavigate();
  const appContext = useContext(AppContext);
  const { setAppState } = appContext;
  const userContext = useContext(UserContext);
  const { userInformation } = userContext;

  return (
    <React.Fragment>
      <nav className="content navbar navbar-top navbar-expand navbar-dashboard navbar-dark ps-0 pe-2 pb-0 mb-3">
        <div className="container-fluid px-0">
          <div
            className="d-flex justify-content-between w-100"
            id="navbarSupportedContent"
          >
            <div className="d-flex align-items-center">
              <HideShowButton />
              <Search />
            </div>
            <ul className="navbar-nav align-items-center">
              <div className="m-2">
                {userInformation ? (
                  <span className="mx-2">
                    {userInformation.name + " " + userInformation.lastname}
                  </span>
                ) : (
                  ""
                )}
                <button
                  className="btn btn-danger text-light "
                  onClick={() => {
                    navigate("/");
                    TokenService.removeUser();
                    setAppState("LOGIN_REQUIRED");
                  }}
                >
                  Çıkış
                </button>
              </div>
            </ul>
          </div>
        </div>
      </nav>
    </React.Fragment>
  );
}
