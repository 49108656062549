import Open from "../assets/open.png";
import FormSelect from "../app/components/FormSelect";
import React from "react";

const posList = [
  { name: "İŞ POS" },
  { name: "AKBANK POS" },
  { name: "YAPI KREDI POS" },
  { name: "ZİRAAT POS" },
  { name: "DENİZ BONUS POS" },
  { name: "HALKBANK BONUS POS" },
];

const paymentMethodList = [
  {
    name: "NAKİT PEŞİN",
  },
  {
    name: "KREDİ KARTI PEŞİN",
  },
  {
    name: "PEŞİNAT",
  },
  {
    name: "KREDİ KARTI TAKSİT",
  },
  {
    name: "HAVALE / EFT",
  },
  {
    name: "KMH",
  },
];
export default function ManuelPaymentDetail() {
  const manuel = () => {
    return (
      <div>
        <h5>Toplam ödenecek tutar: TL</h5>
        <br />
        <div className="row">
          <FormSelect
            name="branchId"
            text="POS Seç"
            placeholder=""
            type="text"
            col="12"
            required
            value=""
            onChange={(e) => null}
            id={"inputs.id"}
            data={posList}
            valuekey="name"
            textkey="name"
          />
          <FormSelect
            name="branchId"
            text="Ödeme Şekli Seç"
            placeholder=""
            type="text"
            col="12"
            required
            value=""
            onChange={(e) => null}
            id={"inputs.id"}
            data={paymentMethodList}
            valuekey="name"
            textkey="name"
          />
        </div>
        <div className="mx-2">
          <button className="btn btn-success text-light ">Ödeme yap</button>
        </div>
      </div>
    );
  };

  const StartContract = () => {
    return "";
  };
  const SelectParent = () => {
    return "";
  };
  const SelectFeesAndDiscounts = () => {
    return "";
  };
  const SelectPamnetntMethod = () => {
    return "";
  };
  const DoPayment = () => {
    return "";
  };
  const ShowContract = () => {
    return "";
  };

  return (
    <>
      <h3>Öğrencinin Adı Soyadı ve Diğer Bilgileri</h3>
      <div className="row">
        <div className="col-sm-6">
          <div className="card">
            <div className="card-body">
              {manuel()}
              <a href="#" className="btn btn-primary">
                Go somewhere
              </a>
            </div>
          </div>
        </div>
        <div className="col-sm-6">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">Yapılmış Ödemeler</h5>
              <p className="card-text">
                With supporting text below as a natural lead-in to additional
                content.
              </p>
              <a href="#" className="btn btn-primary">
                Go somewhere
              </a>
              <h5 className="card-title">Bekleyen Ödemeler</h5>
              <p className="card-text">
                With supporting text below as a natural lead-in to additional
                content.
              </p>
              <a href="#" className="btn btn-primary">
                Go somewhere
              </a>
              <h5 className="card-title">Sözleşme Bilgileri</h5>
              <p className="card-text">
                With supporting text below as a natural lead-in to additional
                content.
              </p>
              <a href="#" className="btn btn-primary">
                Go somewhere
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
