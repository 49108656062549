import { useContext } from "react";
import { AppContext } from "../../context/AppContextProvider";
import TokenService from "../../service/token.service";
import { useNavigate } from "react-router";
import { UserContext } from "../../context/UserContextProvider";

export default function AppHeader() {
  const navigate = useNavigate();
  const appContext = useContext(AppContext);
  const { setAppState } = appContext;
  const userContext = useContext(UserContext);
  const { userInformation } = userContext;

  return (
    <div className="d-flex justify-content-between">
      <div></div>
      <div className="m-2">
        {userInformation ? (
          <span className="mx-2">
            {userInformation.name + " " + userInformation.lastname}
          </span>
        ) : (
          ""
        )}
        <button
          className="btn btn-danger "
          onClick={() => {
            navigate("/");
            TokenService.removeUser();
            setAppState("LOGIN_REQUIRED");
          }}
        >
          Çıkış
        </button>
      </div>
    </div>
  );
}
