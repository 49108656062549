import React, { useEffect, useState } from "react";
import FormInput from "../app/components/FormInput";
import BaseModal from "./components/BaseModal";
import {
  retrieveQuotaListBySchoolId,
  retrieveSchoolQuotaByGradeId,
} from "../requests/SchoolRequests";
import { NotificationManager } from "react-notifications";
import { retrieveAllContractsInformationBySchoolId } from "../requests/ContractRequests";

export default function SchoolQuotaScreen() {
  const [dataList, setDataList] = useState([]);
  const [quotaList, setQuotaList] = useState(null);
  const [selectedGrade, setSelectedGrade] = useState(null);
  const [baseGradeQuota, setBaseGradeQuota] = useState(250);
  const [showSelectedStudentModal, setShowSelectedStudentModal] =
    useState(false);
  const schoolId = localStorage.getItem("schoolId");
  const user = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    const retrieveAndSetContractInformationBySchoolId = async () => {
      const { data, err } = await retrieveAllContractsInformationBySchoolId(
        schoolId,
        user?.access_token
      );
      if (err) {
        NotificationManager.error(
          "Öğrenci sözleşmeleri sorgulanırken bir hata oluştu"
        );
        return;
      }
      setDataList(data);
    };
    if (!schoolId) return;
    retrieveAndSetContractInformationBySchoolId();
    retrieveAndSetQuotaListBySchoolId();
  }, [schoolId]);

  const retrieveAndSetQuotaListBySchoolId = async () => {
    const { data, err } = await retrieveQuotaListBySchoolId(
      schoolId,
      user?.access_token
    );
    if (err) {
      NotificationManager.error("Kota listesi sorgulanırken bir hata oluştu");
      return;
    }
    setQuotaList(data);
  };

  useEffect(() => {
    if (selectedGrade) {
      setBaseGradeQuota(selectedGrade.count);
    }
  }, [selectedGrade]);

  const calculateCountDone = (gradeId) => {
    if (!dataList) return 0;
    const count = dataList.filter(
      (d) => d.student.grade.id === gradeId && d.status === "END"
    );
    return count.length;
  };

  const calculateGradeCount = (gradeId) => {
    if (!dataList) return 0;
    const count = dataList.filter((d) => d.student.grade.id === gradeId);
    return count.length;
  };

  const calculateFull = (gradeId, gradeCount) => {
    if (!dataList) return 0;
    return gradeCount - calculateGradeCount(gradeId);
  };

  const calculateReal = (gradeId, gradeCount) => {
    if (!dataList) return 0;
    return gradeCount - calculateCountDone(gradeId);
  };

  const handleUpdateQuotaOnClick = async () => {
    const { data, err } = await retrieveSchoolQuotaByGradeId(
      selectedGrade.id,
      baseGradeQuota,
      user?.access_token
    );
    if (err) {
      NotificationManager.error("Kota güncellenirken bir hata oluştu");
      return;
    }
    NotificationManager.success("Kota başarıyla güncellendi");
    retrieveAndSetQuotaListBySchoolId();
    setShowSelectedStudentModal(false);
  };

  const RenderSelectedStudentModalContent = () => {
    return (
      <BaseModal
        modalIsOpen={showSelectedStudentModal}
        contentLabel={"Öğrenci bilgileri"}
        closeModal={() => {
          setShowSelectedStudentModal(false);
        }}
      >
        <div className="col-12 ">
          <div className="my-3">
            <h5>Kontenjan güncelle</h5>
          </div>
          <div className="d-flex flex-row align-items-center justify-content-center gap-2">
            <FormInput
              name="description"
              text="Kontenjan"
              placeholder=""
              type="number"
              col="10"
              value={baseGradeQuota}
              onChange={(e) => setBaseGradeQuota(parseFloat(e.target.value))}
            />
            <div className="col-md-1 mt-4 mb-3">
              <button
                className="btn btn-success text-light "
                onClick={() => {
                  handleUpdateQuotaOnClick();
                }}
              >
                Kaydet
              </button>
            </div>
          </div>
        </div>
      </BaseModal>
    );
  };

  return (
    <>
      {RenderSelectedStudentModalContent()}
      <div className="content">
        <div className="col-12 col-xl-12">
          <div className="card border-0 shadow">
            <div className="card-body">
              <div className="table-responsive">
                <table className="table table-centered mb-0 rounded table-hover w-100">
                  <thead className="thead-light">
                    <tr>
                      <th className="border-0">SINIF DÜZEYİ</th>
                      <th className="border-0">KONTENJAN</th>
                      <th className="border-0">KAYITLI ÖĞRENCİ</th>
                      <th className="border-0">KAYIT BEKLEYEN KONTENJAN</th>
                      <th className="border-0">YENİ KONTENJAN</th>
                      <th className="border-0"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {dataList &&
                    Array.isArray(dataList) &&
                    quotaList &&
                    quotaList.gradeList
                      ? quotaList.gradeList.map((f, key) => (
                          <tr
                            key={key}
                            onClick={() => {
                              setSelectedGrade(f);
                            }}
                            style={{ cursor: "pointer" }}
                            className={`${
                              selectedGrade && selectedGrade.id === f.id
                                ? "table-warning"
                                : ""
                            } `}
                          >
                            <td>{f.name}</td>
                            <td>{f.count}</td>
                            <td>{calculateCountDone(f.id, f.count)}</td>
                            <td>{calculateReal(f.id, f.count)}</td>
                            <td>{calculateFull(f.id, f.count)}</td>
                            <td>
                              <button
                                className="btn btn-info "
                                onClick={() =>
                                  setShowSelectedStudentModal(true)
                                }
                              >
                                İşlem
                              </button>
                            </td>
                          </tr>
                        ))
                      : null}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
