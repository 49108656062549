import { formatMoneyToTL } from "../../../utilization/MoneyOperations";

const FeesTable = (props) => {
  const { contractFees, contractDiscounts } = props;
  if (!contractFees) return;

  const constructTotalFeePrice = () => {
    let total = 0;
    for (let i = 0; i < contractFees.length; i++) {
      total += contractFees[i].totalFees;
    }
    return formatMoneyToTL(total) + " TL";
  };

  const retrieveDiscountsInFee = (feesId) => {
    if (contractDiscounts) {
      const discountListForFees = contractDiscounts.filter(
        (discount) =>
          discount.contractFees && discount.contractFees.id === feesId
      );
      return discountListForFees;
    }
    return [];
  };

  return (
    <div className="table-responsive">
      <table className="table table-centered mb-0 rounded table-hover w-100">
        <thead className="thead-light">
          <tr>
            <th className="border-0">ÜCRET KALEMİ</th>
            <th className="border-0">BEDELİ</th>
            <th className="border-0">KDV ORANI</th>
            <th className="border-0" style={{ whiteSpace: "wrap" }}>
              KURUMUN İLAN ETTİĞİ ÜCRETLER (KDV DAHİL)
            </th>
            <th className="border-0" style={{ whiteSpace: "wrap" }}>
              ÖĞRENCİ İÇİN BELİRLENEN ÜCRETLER (KDV DAHİL)
            </th>
          </tr>
        </thead>
        <tbody>
          {contractFees.map((fees, key) => (
            <>
              <tr key={key} style={{ cursor: "pointer" }}>
                <td> {fees.name}</td>
                <td>{formatMoneyToTL(fees.fees)} TL</td>
                <td>%{fees.taxRatio}</td>
                <td>{formatMoneyToTL(fees.feesWithTax)} TL</td>
                <td>
                  <b>{formatMoneyToTL(fees.totalFees)} TL</b>
                </td>
              </tr>
              {retrieveDiscountsInFee(fees.id).map((discount, key) => (
                <tr className="w-100" key={key} style={{ cursor: "pointer" }}>
                  <td></td>
                  <td style={{ maxWidth: "20%" }}>
                    {discount.name} (%{discount.discount})
                  </td>
                  <td></td>
                  <td>-{formatMoneyToTL(discount.discountFees)} TL</td>
                  <td></td>
                </tr>
              ))}
            </>
          ))}

          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td>
              <b>GENEL TOPLAM:</b>
            </td>
            <td>
              <b>{contractFees ? constructTotalFeePrice() : null}</b>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default FeesTable;
