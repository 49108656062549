import { useContext, useEffect, useState } from "react";
import Back from "../../assets/back.png";
import { Link, useParams } from "react-router-dom";
import ContractText from "../page-parts/contract/ContractText";
import { UserContext } from "../../context/UserContextProvider";
import ParentExpressConsent from "../page-parts/contract/ParentExpressConsent";
import SchoolExpressConsent from "../page-parts/contract/SchoolExpressConsent";
import FeesTableWithTitle from "../page-parts/fees/FeesTableWithTitle";
import ParentInformationTableWithTitle from "../page-parts/parents/ParentInformationTableWithTitle";
import StudentInformationTableWithTitle from "../page-parts/students/StudentInformationTableWithTitle";
import ContractHeader from "../page-parts/contract/ContractHeader";
import CreditCartInstallment from "../../admin/page-parts/payments/CreditCartInstallment";
import { retrieveStudentContractByStudentIdAndParentId } from "../../requests/ContractRequests";
import { NotificationManager } from "react-notifications";

export default function Contract() {
  const { id } = useParams();
  const userContext = useContext(UserContext);
  const { userInformation } = userContext;
  const [studentContract, setStudentContract] = useState(null);
  const parentUser = JSON.parse(localStorage.getItem("parent_data"));
  const user = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    const retrieveAndSetStudentContractByStudentIdAndParentId = async () => {
      const { data, err } = await retrieveStudentContractByStudentIdAndParentId(
        id,
        parentUser?.user_id,
        user?.access_token
      );
      if (err) {
        NotificationManager.error(
          "Öğrenci sözleşme detayı sorgulanırken bir hata oluştu"
        );
        return;
      }
      setStudentContract(data);
    };
    if (!id) return;
    retrieveAndSetStudentContractByStudentIdAndParentId();
  }, []);

  const findParentByParentType = (parentType) => {
    if (!studentContract) return;
    return studentContract.student.parents.find(
      (p) => p.parentType === parentType
    );
  };

  const RenderContractTextContent = () => {
    return studentContract && studentContract.student ? (
      <div className="p-4">
        <div className="row" style={{ marginBottom: 15, marginTop: 15 }}>
          <ContractHeader student={studentContract?.student} />
        </div>
        <div className="row" style={{ marginBottom: 15, marginTop: 15 }}>
          <StudentInformationTableWithTitle
            title={"Öğrenci bilgileri"}
            student={studentContract?.student}
            parentMother={findParentByParentType("ANNE")}
            parentFather={findParentByParentType("BABA")}
          />
        </div>
        <div className="row" style={{ marginBottom: 15, marginTop: 15 }}>
          <ParentInformationTableWithTitle
            title={"Öğrenci veli bilgileri"}
            parentMother={findParentByParentType("ANNE")}
            parentFather={findParentByParentType("BABA")}
          />
        </div>
        <div className="row " style={{ marginBottom: 15, marginTop: 15 }}>
          <FeesTableWithTitle
            title={"Ödeme bilgileri"}
            contractFees={studentContract?.contractFees}
            contractDiscounts={studentContract?.contractDiscounts}
          />
          <CreditCartInstallment payments={studentContract?.payments} />
        </div>

        <div className="" style={{ marginTop: 10, marginBottom: 10 }}>
          <ContractText />
        </div>
        <div className="" style={{ marginTop: 10, marginBottom: 10 }}>
          <ParentExpressConsent student={studentContract?.student} />
        </div>

        <div className="" style={{ marginTop: 10, marginBottom: 10 }}>
          <SchoolExpressConsent student={studentContract?.student} />
        </div>
      </div>
    ) : null;
  };

  return (
    <div className={`${userInformation.role === "ADMIN" ? "content" : ""}`}>
      {userInformation.role === "ADMIN" ? (
        <div className="" style={{ width: 260 }} />
      ) : null}
      <div className="container">
        <div className="row">
          <div className="col-12 col-xl-12">
            <div className="card card-body border-0 shadow mb-4">
              <div className="row">
                <div className="col-auto">
                  <Link to="/" className="d-inline-flex">
                    <img
                      src={Back}
                      alt="back"
                      style={{ width: "30px", cursor: "pointer" }}
                    />
                  </Link>
                </div>
                <Link to="/" className="col">
                  <h2 className="h5 mb-4"> Sözleşme metni</h2>
                </Link>
              </div>
              {studentContract?.payments ? RenderContractTextContent() : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
