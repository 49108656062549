import { useEffect, useState } from "react";
import FormSelect from "../app/components/FormSelect";
import { queryUserLogsByParams } from "../requests/UserLogRequests";
import { formatMoneyToTL } from "../utilization/MoneyOperations";
import { convertToTurkish } from "../utilization/LanguageOperations";
import {
  queryStudentByParams,
  retrieveStudentWithData,
} from "../requests/StudentRequests";
import { retrieveContractInformation } from "../requests/ContractRequests";
import { retrieveQuotaListBySchoolId } from "../requests/SchoolRequests";
import { NotificationManager } from "react-notifications";

const ContractLogsScreen = () => {
  const [quotaList, setQuotaList] = useState(null);
  const [selectedGradeId, setSelectedGradeId] = useState(null);
  const [selectedBranchId, setSelectedBranchId] = useState(null);
  const [selectedStudentId, setSelectedStudentId] = useState(null);
  const [studentWithData, setStudentWithData] = useState(null);
  const [contractInformation, setContractInformation] = useState(null);
  const [studentList, setStudentList] = useState([]);
  const [parentList, setParentList] = useState([]);
  const [parentLogMap, setParentLogMap] = useState({});
  const schoolId = localStorage.getItem("schoolId");
  const user = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    const retrieveAndSetQuotaListBySchoolId = async () => {
      const { data, err } = await retrieveQuotaListBySchoolId(
        schoolId,
        user?.access_token
      );
      if (err) {
        NotificationManager.error("Kota listesi sorgulanırken bir hata oluştu");
        return;
      }
      setQuotaList(data);
    };
    if (!schoolId) return;
    retrieveAndSetQuotaListBySchoolId();
  }, [schoolId]);

  useEffect(() => {
    if (!quotaList) return;
    const { gradeList, branchList } = quotaList;
    const grade = gradeList[0];
    const foundBranch = branchList.find(
      (branch) => branch.grade.id === grade.id
    );
    if (grade) {
      setSelectedGradeId(grade.id);
    }
    if (foundBranch) {
      setSelectedBranchId(foundBranch.id);
    }
  }, [quotaList]);

  useEffect(() => {
    const retrieveAndSetStudentList = async () => {
      const { data, err } = await queryStudentByParams(
        { gradeId: selectedGradeId, branchId: selectedBranchId },
        user?.access_token
      );
      if (err) {
      } else {
        setStudentList(data);
      }
    };
    if (!selectedBranchId) return;
    if (!selectedGradeId) return;
    retrieveAndSetStudentList();
  }, [selectedGradeId, selectedBranchId]);

  useEffect(() => {
    if (!studentList) return;
    if (studentList.length === 0) return;
    setSelectedStudentId(studentList[0].id);
  }, [studentList]);

  useEffect(() => {
    const retrieveAndSetStudentWithData = async () => {
      const { data, err } = await retrieveStudentWithData(
        selectedStudentId,
        user?.access_token
      );
      if (err) {
        NotificationManager.error(
          "Detaylı öğrenci bilgisi sorgulanırken bir hata oluştu"
        );
        return;
      }
      setStudentWithData(data);
    };

    if (!selectedStudentId) return;
    retrieveAndSetStudentWithData();
  }, [selectedStudentId]);

  useEffect(() => {
    const retrieveAndSetContractInformation = async () => {
      const { data, err } = await retrieveContractInformation(
        studentWithData.id,
        studentWithData.parents[0].id,
        user?.access_token
      );
      if (err) {
        NotificationManager.error(
          "Öğrenci sözleşmesi sorgulanırken bir hata oluştu"
        );
        return;
      }
      setContractInformation(data);
    };
    if (!studentWithData) return;
    retrieveAndSetContractInformation();
    setParentList(studentWithData.parents);
  }, [studentWithData]);

  useEffect(() => {
    const retrieveAndSetParentLogList = async () => {
      const currentLogMap = {};
      for (const parent of parentList) {
        const { data, err } = await queryUserLogsByParams(
          { userId: parent.id, entityId: contractInformation.contract.id },
          user?.access_token
        );
        if (err) {
        } else {
          currentLogMap[parent.id] = data;
        }
      }
      setParentLogMap(currentLogMap);
    };

    if (!parentList) return;
    if (!contractInformation) return;
    if (parentList.length === 0) return;
    retrieveAndSetParentLogList();
  }, [contractInformation, parentList]);

  const retrieveFilteredBranchList = () => {
    if (!quotaList) return [];
    return quotaList.branchList.filter(
      (branch) => branch.grade.id === selectedGradeId
    );
  };

  const constructStudentOptionList = () => {
    if (!studentList) return [];
    return studentList.map((student) => ({
      id: student.id,
      name: `${student.name} ${student.lastname}`,
    }));
  };

  const handleSelectGradeOnChange = (e) => {
    setParentList([]);
    setContractInformation(null);
    const { branchList } = quotaList;
    const foundBranch = branchList.find(
      (branch) => branch.grade.id === e.target.value
    );
    setSelectedGradeId(e.target.value);
    if (foundBranch) {
      setSelectedBranchId(foundBranch.id);
    }
  };

  const handleSelectBranchOnChange = (e) => {
    setParentList([]);
    setContractInformation(null);
    setSelectedStudentId(null);
    setSelectedBranchId(e.target.value);
  };

  const handleSelectStudentOnChange = (e) => {
    setParentList([]);
    setContractInformation(null);
    setSelectedStudentId(e.target.value);
  };

  const RenderPaymentTypeContent = (paymentType) => {
    if (!paymentType) return;
    return (
      <span>
        {" "}
        Ödeme tipi: <span style={{ fontWeight: "bold" }}>
          {paymentType}
        </span>{" "}
      </span>
    );
  };

  const RenderCashContent = (cash) => {
    if (!cash) return;
    return (
      <span>
        {" "}
        Tutar:{" "}
        <span style={{ fontWeight: "bold" }}>
          {formatMoneyToTL(parseFloat(cash))} TL
        </span>
      </span>
    );
  };

  const RenderManualPaymentTypeContent = (manualPaymentType) => {
    if (!manualPaymentType) return;
    return (
      <span>
        {" "}
        POS: <span style={{ fontWeight: "bold" }}>{manualPaymentType}</span>
      </span>
    );
  };

  const RenderTransactionIdContent = (transactionId) => {
    if (!transactionId) return;
    return (
      <span>
        {" "}
        İşlem numarası:{" "}
        <span style={{ fontWeight: "bold" }}>{transactionId}</span>
      </span>
    );
  };

  const RenderContractStatusContent = (status) => {
    if (!status) return;
    return (
      <span>
        {" "}
        Durum:{" "}
        <span style={{ fontWeight: "bold" }}>{convertToTurkish(status)}</span>
      </span>
    );
  };

  const RenderInstallmentNoContent = (installmentNo) => {
    if (!installmentNo) return;
    return (
      <span>
        {" "}
        Taksit sayısı:{" "}
        <span style={{ fontWeight: "bold" }}>{parseInt(installmentNo)}</span>
      </span>
    );
  };

  const RenderUserRoleContent = (userRole, name, lastname) => {
    if (!userRole) return;
    if (userRole === "PARENT") return;
    return (
      <span style={{}}>
        {" "}
        <span style={{ fontWeight: "bold", fontStyle: "italic" }}>
          ({convertToTurkish(userRole)} - {name} {lastname})
        </span>
      </span>
    );
  };

  const RenderCreatedAtContent = (createdAt, userRole, name, lastname) => {
    if (!createdAt) return;
    return (
      <span style={{ fontStyle: "italic" }}>
        {new Date(createdAt).toLocaleString("TR")}
        {RenderUserRoleContent(userRole, name, lastname)}
      </span>
    );
  };

  const RenderLogContent = (log, logParentId) => {
    const {
      userId,
      userRole,
      name,
      lastname,
      entity,
      entityId,
      operation,
      createdAt,
      description,
    } = log;
    if (entity === "PAYMENT") {
      if (operation === "ADD") {
        const parsedDescription = JSON.parse(description);
        const {
          contractId,
          paidBy,
          paymentType,
          cash,
          installmentFee,
          installmentNo,
          manualPaymentType,
        } = parsedDescription;
        let canShow = true;
        if (paidBy && paidBy !== logParentId) {
          canShow = false;
        }
        if (contractId !== contractInformation.contract.id) {
          canShow = false;
        }
        if (!canShow) return;
        return (
          <div>
            {RenderCreatedAtContent(createdAt, userRole, name, lastname)}{" "}
            tarihinde bir ödeme <span className="text-info">yaptı</span>.
            <div className="px-4" style={{ fontSize: 14 }}>
              {RenderPaymentTypeContent(paymentType)}
              {RenderCashContent(cash)}
              {RenderCashContent(installmentFee)}
              {RenderInstallmentNoContent(installmentNo)}
              {RenderManualPaymentTypeContent(manualPaymentType)}
            </div>
          </div>
        );
      }
    } else if (entity === "CONTRACT") {
      const parsedDescription = JSON.parse(description);
      const { status, parentId, studentId } = parsedDescription;
      if (operation === "CHANGE") {
        let canShow = true;
        if (parentId && parentId !== logParentId) {
          canShow = false;
        }
        if (studentId !== selectedStudentId) {
          canShow = false;
        }
        if (!canShow) return;
        return (
          <div>
            {RenderCreatedAtContent(createdAt, userRole, name, lastname)}{" "}
            tarihinde sözleşme durumu{" "}
            <span className="text-warning">değiştirildi</span>.
            <div className="px-4" style={{ fontSize: 14 }}>
              {RenderContractStatusContent(status)}
            </div>
          </div>
        );
      } else if (operation === "END") {
        let canShow = true;
        if (parentId && parentId !== logParentId) {
          canShow = false;
        }
        if (studentId !== selectedStudentId) {
          canShow = false;
        }
        if (!canShow) return;
        return (
          <div>
            {RenderCreatedAtContent(createdAt, userRole, name, lastname)}{" "}
            tarihinde sözleşme durumu{" "}
            <span className="text-success">tamamlandı</span>.
            <div className="px-4" style={{ fontSize: 14 }}>
              {RenderContractStatusContent(status)}
            </div>
          </div>
        );
      }
    } else if (entity === "TRANSACTION") {
      if (operation === "START") {
        const parsedDescription = JSON.parse(description);
        const { userId, contractId, transactionPrice, transactionId } =
          parsedDescription;
        let canShow = true;
        if (userId !== logParentId) {
          canShow = false;
        }
        if (contractId !== contractInformation.contract.id) {
          canShow = false;
        }
        if (!canShow) return;
        return (
          <div>
            {RenderCreatedAtContent(createdAt, userRole, name, lastname)}{" "}
            tarihinde parampos tarafında bir ödeme{" "}
            <span className="text-warning">başlattı</span>.
            <div className="px-4" style={{ fontSize: 14 }}>
              {RenderTransactionIdContent(transactionId)}
              {RenderCashContent(transactionPrice)}
            </div>
          </div>
        );
      } else if (operation === "ERROR") {
        const parsedDescription = JSON.parse(description);
        const { userId, contractId, receiptPrice, installment } =
          parsedDescription;
        let canShow = true;
        if (userId !== logParentId) {
          canShow = false;
        }
        if (contractId !== contractInformation.contract.id) {
          canShow = false;
        }
        if (!canShow) return;
        return (
          <div>
            {RenderCreatedAtContent(createdAt, userRole, name, lastname)}{" "}
            tarihinde parampos tarafında ödemede bir{" "}
            <span className="text-danger">hata aldı</span>.
            <div className="px-4" style={{ fontSize: 14 }}>
              {RenderCashContent(receiptPrice)}
              {RenderInstallmentNoContent(installment)}
            </div>
          </div>
        );
      } else if (operation === "SUCCESS") {
        const parsedDescription = JSON.parse(description);
        const { userId, contractId, receiptPrice } = parsedDescription;
        let canShow = true;
        if (userId !== logParentId) {
          canShow = false;
        }
        if (contractId !== contractInformation.contract.id) {
          canShow = false;
        }
        if (!canShow) return;
        return (
          <div>
            {RenderCreatedAtContent(createdAt, userRole, name, lastname)}{" "}
            tarihinde parampos tarafında ödemesini{" "}
            <span className="text-success">tamamladı</span>.
            <div className="px-4" style={{ fontSize: 14 }}>
              {RenderCashContent(receiptPrice)}
            </div>
          </div>
        );
      }
    } else if (entity === "PAYMENT_PARTIAL") {
      if (operation === "ADD") {
        const parsedDescription = JSON.parse(description);
        const {
          contractId,
          paidBy,
          paymentType,
          cash,
          installmentFee,
          installmentNo,
          manualPaymentType,
        } = parsedDescription;
        let canShow = true;
        if (paidBy && paidBy !== logParentId) {
          canShow = false;
        }
        if (contractId && contractId !== contractInformation.contract.id) {
          canShow = false;
        }
        if (!canShow) return;
        return (
          <div>
            {RenderCreatedAtContent(createdAt, userRole, name, lastname)}{" "}
            tarihinde bir ödeme <span className="text-info">yaptı</span>.{" "}
            <div className="px-4" style={{ fontSize: 14 }}>
              {RenderPaymentTypeContent(paymentType)}
              {RenderCashContent(cash)}
              {RenderCashContent(installmentFee)}
              {RenderInstallmentNoContent(installmentNo)}
              {RenderManualPaymentTypeContent(manualPaymentType)}
            </div>
          </div>
        );
      } else if (operation === "APPROVE") {
        const parsedDescription = JSON.parse(description);
        const { paidBy, cash, paymentType } = parsedDescription;
        let canShow = true;
        if (paidBy && paidBy !== logParentId) {
          canShow = false;
        }
        if (!canShow) return;
        return (
          <div>
            {RenderCreatedAtContent(createdAt, userRole, name, lastname)}{" "}
            tarihinde yapılan ödeme{" "}
            <span className="text-success">onaylandı</span>.
            <div className="px-4" style={{ fontSize: 14 }}>
              {RenderPaymentTypeContent(paymentType)}
              {RenderCashContent(cash)}
            </div>
          </div>
        );
      } else if (operation === "REJECT") {
        const parsedDescription = JSON.parse(description);
        const { paidBy, cash, paymentType } = parsedDescription;
        let canShow = true;
        if (paidBy && paidBy !== logParentId) {
          canShow = false;
        }
        if (!canShow) return;
        return (
          <div>
            {RenderCreatedAtContent(createdAt, userRole, name, lastname)}{" "}
            tarihinde yapılan ödeme{" "}
            <span className="text-danger">reddedildi</span>.
            <div className="px-4" style={{ fontSize: 14 }}>
              {RenderPaymentTypeContent(paymentType)}
              {RenderCashContent(cash)}
            </div>
          </div>
        );
      }
    } else if (entity === "LOGIN") {
      if (operation === "NEW") {
        return (
          <div>
            {RenderCreatedAtContent(createdAt, userRole, name, lastname)}{" "}
            tarihinde sisteme giriş yaptı.
          </div>
        );
      }
    } else if (entity === "ACCESS_TOKEN") {
      if (operation === "NEW") {
        return (
          <div>
            {RenderCreatedAtContent(createdAt, userRole, name, lastname)}{" "}
            tarihinde sisteme girişini yeniledi.
          </div>
        );
      }
    } else if (entity === "SIBLING_DISCOUNT") {
      if (logParentId !== userId) return;
      const parsedDescription = JSON.parse(description);
      const { studentId } = parsedDescription;
      if (studentId !== selectedStudentId) return;
      if (operation === "ADD") {
        return (
          <div>
            {RenderCreatedAtContent(createdAt, userRole, name, lastname)}{" "}
            tarihinde kardeş indirimi{" "}
            <span className="text-success">ekledi</span>.
          </div>
        );
      } else if (operation === "REMOVE") {
        return (
          <div>
            {RenderCreatedAtContent(createdAt, userRole, name, lastname)}{" "}
            tarihinde kardeş indirimini{" "}
            <span className="text-danger">kaldırdı</span>.
          </div>
        );
      }
    }
  };

  const RenderSingleParentLogMap = (parentId) => {
    const foundParent = parentList.find((parent) => parent.id === parentId);
    if (!foundParent) return;
    if (!contractInformation) return;
    const parentLogList = parentLogMap[parentId];
    return (
      <div className="my-4">
        <div className="my-1">
          <span style={{ fontWeight: "bold" }}>
            {foundParent.user.name} {foundParent.user.lastname}
          </span>{" "}
          için kayıtlar
        </div>
        <div className="px-4 my-1">
          {parentLogList.length === 0 ? (
            <div>
              <div>
                <span style={{ fontStyle: "italic", opacity: 0.7 }}>
                  Sistemde yapılan aktiviteye ilişkin bir kayıt bulunamadı.
                </span>
              </div>
            </div>
          ) : (
            parentLogList.map((log, index) => (
              <div key={index} className="my-2">
                {RenderLogContent(log, parentId)}
              </div>
            ))
          )}
        </div>
      </div>
    );
  };

  const RenderParentLogMap = () => {
    return Object.keys(parentLogMap).map((key, index) => (
      <div key={index}>{RenderSingleParentLogMap(key)}</div>
    ));
  };

  return (
    <div className="content">
      <div className="col-12">
        <div className="card border-0 shadow">
          <div className="card-body">
            <form>
              <div className="row">
                {quotaList?.gradeList ? (
                  <FormSelect
                    name="gradeId"
                    text="Sınıf"
                    placeholder=""
                    type="text"
                    col="4"
                    required
                    value={selectedGradeId}
                    onChange={handleSelectGradeOnChange}
                    id={"inputs.id"}
                    data={quotaList.gradeList}
                    valuekey="id"
                    textkey="name"
                  />
                ) : null}
                {selectedGradeId && quotaList?.branchList ? (
                  <FormSelect
                    name="branchId"
                    text="Şube"
                    placeholder=""
                    type="text"
                    col="4"
                    required
                    value={selectedBranchId}
                    onChange={handleSelectBranchOnChange}
                    id={"inputs.id"}
                    data={retrieveFilteredBranchList()}
                    valuekey="id"
                    textkey="name"
                  />
                ) : null}
                {selectedBranchId && studentList ? (
                  <FormSelect
                    name="studentId"
                    text="Öğrenci"
                    placeholder=""
                    type="text"
                    col="4"
                    required
                    value={selectedStudentId}
                    onChange={handleSelectStudentOnChange}
                    id={"inputs.id"}
                    data={constructStudentOptionList()}
                    valuekey="id"
                    textkey="name"
                  />
                ) : null}
              </div>
            </form>
            {RenderParentLogMap()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContractLogsScreen;
